export function estimatedTimeInMinutes(estimated_time: number) {
  const minutes = (estimated_time - Math.floor(estimated_time)) * 60;
  const hoursInMinutes = Math.floor(estimated_time) * 60;
  return hoursInMinutes + minutes;
}

export function remainingTimeText(
  remaining_time: number,
  hour: number,
  minutes: number,
  notEndedText: string,
  endedText: string,
  hour_symbol: string,
  minute_symbol: string
) {
  const absValueHours = Math.floor(Math.abs(hour));
  let minutesText = "" + minutes;
  let hoursText = "" + absValueHours;

  if (minutes === 0) {
    minutesText = minutesText + "0";
  }
  if (absValueHours < 10) {
    hoursText = "0" + absValueHours;
  }
  let time_text = notEndedText;
  if (remaining_time < 0) {
    time_text = endedText;
  }
  return (
    hoursText +
    hour_symbol +
    " " +
    minutesText +
    minute_symbol +
    " " +
    time_text
  );
}
