import { UserApi } from "@/infrastructure/http/UserApiResource";
import { UserInterface } from "@/models/sso/user";
import { User } from "@firebase/auth";

export async function checkUser(user: User): Promise<boolean> {
  const result = await UserApi.checkUser(user);
  if (result === true) {
    return true;
  } else {
    return false;
  }
}

export async function createSessionWithGoogle(
  accessToken: string,
  user: User
): Promise<{ user: UserInterface; session: string } | undefined> {
  const result = await UserApi.loginWithGoogle("Google", accessToken, user);
  return result;
}

export async function loginWithUsernameAndPassword(
  email: string,
  password: string
): Promise<{ user: UserInterface; session: string } | undefined> {
  const result = await UserApi.loginWithUsernameAndPassword(email, password);
  return result;
}
