import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "inner-block"
}
const _hoisted_2 = { class: "inner-block-main" }
const _hoisted_3 = { class: "h4-white main-text" }
const _hoisted_4 = { class: "small-text-white secondary-main-text" }
const _hoisted_5 = { class: "small-text-orange secondary-main-text" }
const _hoisted_6 = { class: "small-text-white normal-text" }
const _hoisted_7 = { class: "input-field-container" }
const _hoisted_8 = { class: "small-text-white normal-text" }
const _hoisted_9 = { class: "input-field-container password-field" }
const _hoisted_10 = { class: "input-field-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_Form = _resolveComponent("Form")!
  const _component_ta_button = _resolveComponent("ta-button")!
  const _component_main_block = _resolveComponent("main-block")!
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_main_block, { customClass: 'main-block-registration registration-end-wrapper' }, {
      default: _withCtx(() => [
        (_ctx.showContent)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t("registration-end.end-message")), 1),
                _createElementVNode("p", _hoisted_4, [
                  _createTextVNode(_toDisplayString(_ctx.$t("registration-end.email-message")) + " ", 1),
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.shownEmail), 1)
                ]),
                _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("registration-end.name-question")), 1),
                _createVNode(_component_Form, { class: "form" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_input_field, {
                        onKeyup: _withKeys(_ctx.updateUserInfo, ["enter"]),
                        class: "small-text-grey",
                        placeholder: _ctx.nameText,
                        type: 'Name',
                        required: true,
                        onInput: _ctx.receiveName
                      }, null, 8, ["onKeyup", "placeholder", "onInput"])
                    ]),
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("registration-end.create-password")), 1),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_input_field, {
                        onKeyup: _withKeys(_ctx.updateUserInfo, ["enter"]),
                        class: "small-text-grey",
                        type: _ctx.passwordType,
                        placeholder: _ctx.passwordText,
                        required: true,
                        showPasswordIcon: "",
                        onPasswordReveal: _ctx.showPassword,
                        onInput: _ctx.receivePassword,
                        allowPasswordRules: "",
                        showHint: ""
                      }, null, 8, ["onKeyup", "type", "placeholder", "onPasswordReveal", "onInput"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createVNode(_component_input_field, {
                        onKeyup: _withKeys(_ctx.updateUserInfo, ["enter"]),
                        class: "small-text-grey",
                        type: _ctx.passwordType,
                        placeholder: _ctx.reEnterText,
                        required: true,
                        errorMessage: _ctx.errorMessage,
                        showPasswordIcon: "",
                        onPasswordReveal: _ctx.showPassword,
                        onInput: _ctx.receiveRePassword,
                        allowPasswordRules: "",
                        showHint: ""
                      }, null, 8, ["onKeyup", "type", "placeholder", "errorMessage", "onPasswordReveal", "onInput"])
                    ])
                  ]),
                  _: 1
                }),
                _createElementVNode("div", {
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateUserInfo())),
                  class: "continue-button-container"
                }, [
                  _createVNode(_component_ta_button, {
                    text: _ctx.continueText,
                    backgroundColor: 'orange',
                    enableLoading: _ctx.enableButtonLoader
                  }, null, 8, ["text", "enableLoading"])
                ])
              ])
            ]))
          : _createCommentVNode("", true),
        _createVNode(_component_ta_button, {
          text: _ctx.cancelButtonText,
          customClass: 'cancel-button',
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelUpdate()))
        }, null, 8, ["text"])
      ]),
      _: 1
    }),
    _createElementVNode("div", null, [
      _createVNode(_component_main_footer)
    ])
  ]))
}