import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/expand_more.svg'


const _hoisted_1 = { class: "admin-displayed-main-name" }
const _hoisted_2 = { class: "small-text" }
const _hoisted_3 = {
  key: 1,
  class: "admin-user-list-element-wrapper"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "small-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "admin-user-info-dropdown-block",
    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toggleDropdown())),
    onClickout: _cache[2] || (_cache[2] = ($event: any) => (this.dropdownEnabled = false))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["admin-displayed-name-block", { 'extended-admin-displayed-name-block': _ctx.dropdownEnabled }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.userFullName), 1),
        _createElementVNode("img", {
          class: _normalizeClass(["admin-displayed-dropdown-icon", { 'admin-displayed-image-rotate': _ctx.dropdownEnabled }]),
          src: _imports_0
        }, null, 2)
      ]),
      (_ctx.dropdownEnabled)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "small-text select-self",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectUser(_ctx.originalUserName, _ctx.orginalUserUid)))
          }, " - - " + _toDisplayString(_ctx.returnMeText) + " - - ", 1))
        : _createCommentVNode("", true),
      (_ctx.dropdownEnabled)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allUsers, (user, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "admin-user-list-element",
                onClick: ($event: any) => (_ctx.selectUser(user.display_name, user.firebase_uid))
              }, [
                _createElementVNode("span", _hoisted_5, _toDisplayString(user.display_name), 1)
              ], 8, _hoisted_4))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ], 2)
  ], 32))
}