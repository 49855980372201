
import { defineComponent } from "vue";
import { getAsanaAccessToken } from "@/service/AsanaService";
import userStore from "@/store/store";
import { UserInterface } from "@/models/sso/user";
import loadingRing from "@/components/loadingRing.vue";
import router from "@/router";
import protectionPopup from "@/components/GenericComponents/DataProtectionPopup.vue";
import {
  allIntegrationByUser,
  removeIntegration,
  updateIntegration,
} from "@/service/IntegrationService";
import { Integration } from "@/models/time_assistant/integration";
import { ASANA } from "@/constants/IntegrationConstants";

export default defineComponent({
  name: "Asana callback",
  components: { loadingRing, protectionPopup },
  data() {
    return {
      email: "",
      showPopup: false,
      asanaIntegration: {} as Integration,
      callbackText: this.$t("AsanaCallback.link-asana"),
    };
  },
  methods: {
    async denyConnection() {
      this.showPopup = !this.showPopup;
      this.callbackText = this.$t("AsanaCallback.returning");
      await removeIntegration(this.asanaIntegration.id);
      router.push("/dashboard/tasks");
    },
    async allowConnection() {
      await updateIntegration(this.asanaIntegration.id, true);
      router.push("/dashboard/tasks");
    },
  },
  async mounted() {
    const user: UserInterface = userStore.getters.getUser;
    const code = window.location.href.split("code=");
    const asanaApplicationEmail = await getAsanaAccessToken(
      code[1],
      user.firebase_uid
    );
    const allIntegrations = await allIntegrationByUser(user.firebase_uid);
    this.asanaIntegration = allIntegrations.all_user_integrations.find(
      (element: Integration) => element.integration_name === ASANA
    );
    this.email = asanaApplicationEmail;
    this.showPopup = !this.showPopup;
  },
  beforeCreate: function () {
    document.body.className = "AsanaCallback";
  },
});
