
import { defineComponent } from "vue";
import { UserInterface } from "@/models/sso/user";
import taButton from "@/components/Button.vue";
import store from "@/store/store";
import checkbox from "@/components/CheckBox.vue";
import multipleCheckboxSelect from "@/components/MultipleCheckboxSelect.vue";
import {
  getTimeEntryInformations,
  uploadUserExcelEntries,
} from "@/service/TimeEntryService";
import { ProfileInformations } from "@/models/time_assistant/time_entry_information";
import { TimeEntryInformations } from "@/models/time_assistant/time_entry_information";
import loadingStore from "@/store/store";
import { SuggestionData } from "@/models/time_assistant/suggestion_data";
import * as timeEntryConstants from "@/constants/TimeEntryConstants";
import profileButton from "@/components/ProfileButton.vue";
import { checkSimilarity } from "@/utils/tech-similarity";
import {
  addToFlowCircleArray,
  removerFromFlowCircleArray,
} from "@/helpers/TimeEntryHelper";

interface ActionObject {
  text: string;
  selected: boolean;
}

export default defineComponent({
  name: "TagSelection",
  components: {
    profileButton,
    taButton,
    checkbox,
    multipleCheckboxSelect,
  },
  data() {
    return {
      timeEntryInformations: {} as TimeEntryInformations,
      user: store.getters.getUser as UserInterface,
      project: store.getters.getProjectState,
      task: store.getters.getTask,
      selected_project_name: store.getters.getProject.name,
      selected_project_firebase_id: store.getters.getProject.firebase_id,
      selected_task_firebase_id: store.getters.getTask.firebase_id,
      selectedActions: store.getters.getActions,
      firstSectionName: this.$t("tag-selection.first-section-name"),
      secondSectionName: this.$t("tag-selection.second-section-name"),
      thirdSectionName: this.$t("tag-selection.third-section-name"),
      fourthSectionName: this.$t("tag-selection.fourth-section-name"),
      firstCheckboxText: this.$t("tag-selection.first-checkbox-text"),
      secondCheckboxText: this.$t("tag-selection.second-checkbox-text"),
      valueCheckboxText: this.$t("tag-selection.why-checkbox-text"),
      requiredSymbol: this.$t("required.symbol"),
      selectedProfile: "",
      compareableProfile: "",
      defaultProfileRetrieved: false,
      givenValueApproved: store.getters.getConfirmValueCheck,
      buttonColor: "white",
      suggestionArray: [] as string[],
      suggestionMapData: [] as SuggestionData[],
      setDefaultProfile: false,
      removeDefaultProfile: false,
      suggestedTechArray: [] as string[],
      options: [] as ActionObject[],
      showError: false,
      showSuggestionArea: false,
      showUserTechnologies: false,
      showNoSuggestionText: false,
      profilesOrder: [
        timeEntryConstants.TECHNICAL_EXPERT,
        timeEntryConstants.DESIGN_THINKER,
        timeEntryConstants.PROJECT_OWNER,
        timeEntryConstants.LEADER_COACH,
        timeEntryConstants.BUSINESS_DEVELOPER,
      ],
      profilesOrdered: new Map<string, ProfileInformations>(),
      customValueLength: "",
      newLabelClicked: false,
      insertedValue: "",
      bestTechnologyMatch: "",
      timeEntryLoading: store.getters.getTimeEntryLoading,
      noProjectForDefaultProfile:
        Object.keys(store.getters.getProject).length != 0,
    };
  },
  methods: {
    checkTechnologySimilarity() {
      let passableValue = this.$refs.suggestionInput as HTMLInputElement;
      const customSuggestion = passableValue.value.trim();
      if (this.timeEntryInformations.technology_list) {
        const matches = checkSimilarity(
          customSuggestion,
          this.timeEntryInformations.technology_list
        );
        if (matches.bestMatch.rating > 0.5 && matches.bestMatch.rating !== 1) {
          this.insertedValue = customSuggestion;
          this.bestTechnologyMatch = matches.bestMatch.target;
        } else {
          this.addToSuggestion(
            customSuggestion,
            matches.bestMatch.rating !== 1
          );
          passableValue.value = "";
        }
      }
    },
    async addToSuggestion(technology: string, isNewTechnology: boolean) {
      if (isNewTechnology) {
        let storedNewTechnologies: string[] = store.getters.getNewTechnologies;
        if (!storedNewTechnologies.includes(technology)) {
          storedNewTechnologies.push(technology);
          await store.dispatch("updateNewTechnologies", storedNewTechnologies);
        }
      }
      if (
        this.suggestionMapData.length <= timeEntryConstants.MAX_SUGGESTIONS &&
        this.suggestionMapData.filter((e) => e.name === technology).length ===
          0 &&
        technology != "" &&
        this.suggestedTechArray.length < timeEntryConstants.USER_MAX_SUGGESTIONS
      ) {
        this.suggestionMapData.push({
          name: technology,
          isRemovable: true,
        });
        this.suggestedTechArray.push(technology);
        this.showUserTechnologies = true;
      } else if (
        this.suggestionMapData.length >= timeEntryConstants.MAX_SUGGESTIONS ||
        this.suggestedTechArray.length >=
          timeEntryConstants.USER_MAX_SUGGESTIONS
      ) {
        this.showError = true;
      } else {
        const suggestion = this.suggestionMapData.filter(
          (element) => element.name === technology
        );
        if (!this.suggestedTechArray.includes(suggestion[0].name)) {
          this.toggleSuggestion(suggestion[0]);
        }
      }
      await store.dispatch("updateTechnologyArray", this.suggestedTechArray);

      this.insertedValue = "";
      this.bestTechnologyMatch = "";
    },
    async selectProfile(
      receivedProfileID: string,
      receivedProfileName: string,
      resetActions: boolean,
      userUsingFunction: boolean
    ) {
      this.compareableProfile = receivedProfileID;
      if (
        this.selectedProfile !== this.compareableProfile ||
        !userUsingFunction
      ) {
        this.selectedProfile = receivedProfileID;

        let profile = this.timeEntryInformations.profile_informations?.filter(
          (profile: ProfileInformations) => {
            if (profile.ID === receivedProfileID) {
              return profile;
            }
          }
        );

        if (profile && profile.length > 0) {
          this.options = profile[0].profile_activities.map((item: string) => {
            return { text: item, selected: false };
          });
        }

        this.newLabelClicked = resetActions;

        this.defaultProfileRetrieved = false;
        await store.dispatch(
          "updateIsDefaultProfileSelected",
          this.defaultProfileRetrieved
        );
        await store.dispatch("updateRoleID", this.selectedProfile);
        await store.dispatch("updateRoleName", receivedProfileName);
      }
    },
    async setDefaultRole() {
      await store.dispatch(
        "updateDefaultCheckHasBeenTriggered",
        !store.getters.getDefaultCheckHasBeenTriggered
      );
      this.setDefaultProfile = !this.setDefaultProfile;
      this.removeDefaultProfile = !this.setDefaultProfile;
      await store.dispatch("updateAddDefaultRole", this.setDefaultProfile);
      await store.dispatch(
        "updateRemoveDefaultRole",
        this.removeDefaultProfile
      );
    },
    async removeDefaultRole() {
      await store.dispatch("updateDefaultCheckHasBeenTriggered", true);
      this.removeDefaultProfile = true;
      this.defaultProfileRetrieved = false;
      await store.dispatch(
        "updateRemoveDefaultRole",
        this.removeDefaultProfile
      );
      await store.dispatch(
        "updateIsDefaultProfileSelected",
        this.defaultProfileRetrieved
      );
    },
    async approveGivenValue() {
      this.givenValueApproved = !this.givenValueApproved;
      if (this.givenValueApproved) {
        await store.dispatch("updateTaskValue", this.task.custom_fields?.why);
      } else {
        await store.dispatch("updateTaskValue", "");
      }
      await store.dispatch("updateConfirmValueCheck", this.givenValueApproved);
    },
    async addCustomValue() {
      let customValue = this.$refs.customSuggestionInput as HTMLInputElement;
      if (
        store.getters.getEnableEditing &&
        store.getters.getShowCompletionInfo
      ) {
        customValue = store.getters.getCustonTaskValue;
        await store.dispatch("updateShowTagSelectionInfo", false);
      }
      let storeableCustomValue = customValue.value.trim();
      await store.dispatch("updateCustonTaskValue", storeableCustomValue);
      let customValueRef = this.$refs.customSuggestionInput as HTMLInputElement;
      this.customValueLength = customValueRef.value;
    },
    async toggleSuggestion(value: SuggestionData) {
      if (!this.suggestedTechArray.includes(value.name) && !value.isRemovable) {
        if (
          this.suggestedTechArray.length >=
          timeEntryConstants.USER_MAX_SUGGESTIONS
        ) {
          this.showError = true;
        } else {
          this.suggestedTechArray.push(value.name);
          this.showError = false;
        }
      } else {
        if (!value.isRemovable) {
          let removableElement = this.suggestedTechArray.indexOf(value.name);
          this.suggestedTechArray.splice(removableElement, 1);
          this.showError = false;
        }
        if (value.isRemovable) {
          let removableMapData = this.suggestionMapData.indexOf(value);
          this.suggestionMapData.splice(removableMapData, 1);
          let removableElement = this.suggestedTechArray.indexOf(value.name);
          this.suggestedTechArray.splice(removableElement, 1);
          if (
            this.suggestionMapData.filter((e) => e.isRemovable).length === 0
          ) {
            this.showUserTechnologies = false;
          }
          this.showError = false;
        }
        let storedNewTechnologies: string[] = store.getters.getNewTechnologies;
        if (storedNewTechnologies.includes(value.name)) {
          const removableNewTechnology = storedNewTechnologies.indexOf(
            value.name
          );
          storedNewTechnologies.splice(removableNewTechnology, 1);
          await store.dispatch("updateNewTechnologies", storedNewTechnologies);
        }
      }
      await store.dispatch("updateTechnologyArray", this.suggestedTechArray);
    },
    buttonToggleClass(suggestion: SuggestionData) {
      return this.suggestedTechArray.includes(suggestion.name)
        ? "suggestion-button-toggled"
        : "";
    },
    techButtonClass(requireHover: boolean) {
      return requireHover ? "tech-button" : "";
    },
    async checkRequiredValues() {
      if (this.selectedProfile || this.options) {
        const storedProfile = store.getters.getRoleID;
        const storedActions = store.getters.getActions;
        await store.dispatch("updateProceedToTagView", false);
        this.$emit("tagSelected", storedProfile && storedActions.length > 0);
      }

      if (this.options.some((e) => e.selected === true)) {
        addToFlowCircleArray(timeEntryConstants.TAG_FLOW_CIRCLE_INDEX);
      } else {
        removerFromFlowCircleArray(timeEntryConstants.TAG_FLOW_CIRCLE_INDEX);
      }
    },
    resetActions() {
      this.newLabelClicked = false;
    },
    async resetSelectedRole() {
      this.selectProfile("", "", true, false);
    },
    async storeDefaultProfileRetrievedValue(value: boolean) {
      this.defaultProfileRetrieved = value;
      await store.dispatch(
        "updateIsDefaultProfileSelected",
        this.defaultProfileRetrieved
      );
    },
    async retrieveTaskInformations() {
      //retrieve task informations from the local store
      this.timeEntryInformations = store.getters.getTimeEntryInformations;

      //check if there are task information stored
      if (Object.keys(this.timeEntryInformations).length === 0) {
        loadingStore.dispatch("changeLoadingState", true);
        let selected_project_id: string;
        if (store.getters.getProject.firebase_id) {
          selected_project_id = store.getters.getProject.firebase_id;
        } else {
          selected_project_id = store.getters.getProject.gid;
        }
        const selected_task_firebase_id = store.getters.getTask.firebase_id;
        const user = store.getters.getUser as UserInterface;

        //if there aren't task informations in the store, do the api calls to retrieve them
        const result = await getTimeEntryInformations(
          user.firebase_uid,
          selected_task_firebase_id,
          selected_project_id
        );
        this.timeEntryInformations = result.timeEntryInformations;
        //store informations in the store
        await store.dispatch(
          "updateTimeEntryInformations",
          this.timeEntryInformations
        );
        loadingStore.dispatch("changeLoadingState", false);
      }
    },
    retrieveProfileInformations() {
      //retrieve informations of all the profiles ordering them in the buttons order
      if (this.timeEntryInformations.profile_informations) {
        const profiles = this.timeEntryInformations.profile_informations;
        this.profilesOrder.forEach((po) => {
          const result = profiles.find(
            (p: ProfileInformations) => p.profile_name === po
          );
          if (result) {
            this.profilesOrdered.set(po, result);
          }
        });
      }
    },
    async retrieveTechnologySuggestions() {
      if (store.getters.getEnableEditing) {
        this.suggestedTechArray = store.getters.getEditableTechnologies;
        await store.dispatch("updateTechnologyArray", this.suggestedTechArray);
      } else {
        this.suggestedTechArray = store.getters.getTechnologyArray;
      }

      if (this.timeEntryInformations.technologies_suggestion) {
        this.suggestionArray =
          this.timeEntryInformations.technologies_suggestion.sort();
      }

      this.showSuggestionArea = true;
      this.suggestionMapData = this.suggestionArray.map((mappableObject) => ({
        name: mappableObject,
        isRemovable: false,
      }));

      if (this.suggestionMapData.length === 0) {
        this.showNoSuggestionText = true;
      }

      if (this.suggestedTechArray.length !== 0) {
        let taSuggestions = this.suggestionMapData.filter(
          (item: SuggestionData) => this.suggestedTechArray.includes(item.name)
        );
        let taSuggestionsArray = taSuggestions.map((a) => a.name);
        let storedSuggestions = this.suggestedTechArray.map((a) => a);

        let userSuggestions = storedSuggestions.filter(
          (item) => !taSuggestionsArray.includes(item)
        );

        userSuggestions.forEach((el) => {
          this.suggestionMapData.push({
            name: el,
            isRemovable: true,
          });
        });
        if (this.suggestionMapData.length !== 0) {
          this.showUserTechnologies = true;
        }
        if (this.suggestionMapData.filter((e) => e.isRemovable).length === 0) {
          this.showUserTechnologies = false;
        }
      }
    },
    async retrieveUserProfileAndActions() {
      //search for user default role in profile list using the ID
      const result = this.timeEntryInformations.profile_informations?.find(
        (item: ProfileInformations) =>
          item.ID === this.timeEntryInformations.user_default_role
      );
      await store.dispatch(
        "updateOriginalRoleID",
        this.timeEntryInformations.user_default_role
      );
      //take the selectedProfile and defaultProfile retrieve from the local store
      this.selectedProfile = store.getters.getRoleID;
      this.compareableProfile = store.getters.getRoleID;
      this.defaultProfileRetrieved = store.getters.getIsDefaultProfileSelected;
      //check if there isn't a selected profile for the user in the store
      if (!this.selectedProfile) {
        //check if the user has a default role for this project
        //if the user has a default role save it as selected profile otherwise reset every selection
        if (this.timeEntryInformations.user_default_role) {
          if (result) {
            this.selectProfile(result.ID, result.profile_name, true, false);
          }
        } else {
          this.resetSelectedRole();
        }

        this.storeDefaultProfileRetrievedValue(
          !!this.timeEntryInformations.user_default_role
        );
      } else {
        //there is a stored selected profile
        let storedActions: string[] = [];

        //check if it has been retrieved as default role from the database
        if (this.defaultProfileRetrieved) {
          //check if the default role has been retrieved correctly
          if (this.timeEntryInformations.user_default_role) {
            const isDefaultRoleStored =
              this.selectedProfile ===
              this.timeEntryInformations.user_default_role;

            if (result) {
              this.selectProfile(
                result.ID,
                result.profile_name,
                !isDefaultRoleStored,
                false
              );
            }
            //if the role is already saved retrieve also selected actions

            if (isDefaultRoleStored) {
              //retrieve also the actions selected from the user
              storedActions = store.getters.getActions;
            }

            this.storeDefaultProfileRetrievedValue(true);
          } else {
            this.resetSelectedRole();
          }
        } else {
          //retrieve all informations about profile and selected actions from the store
          this.selectProfile(
            store.getters.getRoleID,
            store.getters.getRoleName,
            false,
            false
          );
          storedActions = store.getters.getActions;
          this.setDefaultProfile = store.getters.getAddDefaultRole;
          this.removeDefaultProfile = store.getters.getRemoveDefaultRole;
        }

        //update list of selected actions by the user
        this.options = this.options.map((option: ActionObject) => {
          if (
            option.text === storedActions.find((a: string) => option.text === a)
          ) {
            option.selected = true;
          }
          return option;
        });
      }
    },
    async retrieveAndStoreTimeSuggestions() {
      //retrieve time suggestions from time entry informations and save them in local store
      if (this.timeEntryInformations.time_suggestions) {
        await store.dispatch(
          "updateTimeSuggestions",
          this.timeEntryInformations.time_suggestions
        );
      }
    },
    async retrieveAndStoreWhyValue() {
      let providedValue = this.$refs.customSuggestionInput as HTMLInputElement;
      providedValue.value = store.getters.getCustonTaskValue;

      this.givenValueApproved = store.getters.getConfirmValueCheck;
      await store.dispatch("updateTimeEntryLoading", false);
    },
    async stopLoader() {
      this.timeEntryLoading = false;
    },
  },
  computed: {
    HideOurSuggestionsBlock(): boolean {
      return (
        this.suggestionMapData.some((e) => e.isRemovable === true) &&
        !this.suggestionMapData.some((e) => e.isRemovable === false)
      );
    },
  },
  async mounted() {
    loadingStore.dispatch("changeLoadingState", this.timeEntryLoading);
    await this.retrieveTaskInformations();
    if (this.timeEntryInformations) {
      this.retrieveProfileInformations();
      this.retrieveTechnologySuggestions();
      this.retrieveUserProfileAndActions();
      await this.retrieveAndStoreTimeSuggestions();
    }
    await this.stopLoader();
    await this.retrieveAndStoreWhyValue();
    await this.checkRequiredValues();
    loadingStore.dispatch("changeLoadingState", false);
  },
});
