import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "callbackInfo h5-white" }
const _hoisted_2 = {
  key: 0,
  class: "callback-element-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_protection_popup = _resolveComponent("protection-popup")!
  const _component_loading_ring = _resolveComponent("loading-ring")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showPopup)
      ? (_openBlock(), _createBlock(_component_protection_popup, {
          key: 0,
          relatedEmail: _ctx.email,
          asanaData: true,
          onDenyEmit: _ctx.denyConnection,
          onAllowEmit: _ctx.allowConnection
        }, null, 8, ["relatedEmail", "onDenyEmit", "onAllowEmit"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (!_ctx.showPopup)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createTextVNode(_toDisplayString(_ctx.callbackText) + " ", 1),
            _createVNode(_component_loading_ring, { customColor: 'ta-white' })
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}