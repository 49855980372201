import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "button-div" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ta_button = _resolveComponent("ta-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ta_button, {
      class: _normalizeClass(['button-class', _ctx.buttonClasses]),
      text: _ctx.profilesButtonText(_ctx.profile.profile_name),
      backgroundColor: 'white',
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectProfile(_ctx.profile.ID)))
    }, null, 8, ["class", "text"])
  ]))
}