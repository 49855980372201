
import { computed, defineComponent } from "vue";
import mainBlock from "@/components/MainBlock.vue";
import leftBlock from "@/components/LeftBlock.vue";
import rightBlock from "@/components/RightBlock.vue";
import mainFooter from "@/components/MainFooter.vue";
import taButton from "@/components/Button.vue";
import inputEmailResetPassword from "@/components/InputEmailResetPassword.vue";
import resetPassword from "@/components/ResetPassword.vue";
import resetPasswordLinkException from "@/components/ResetPasswordLinkException.vue";
import * as pageNames from "@/router/pageNames";
import { useRoute } from "vue-router";
import snackbar from "@/components/Snackbar.vue";
import router from "@/router";
import loadingStore from "@/store/store";

export default defineComponent({
  name: "ForgottenPasswordView",
  components: {
    mainBlock,
    leftBlock,
    rightBlock,
    mainFooter,
    inputEmailResetPassword,
    resetPassword,
    resetPasswordLinkException,
    snackbar,
    taButton,
  },
  data() {
    return {
      cancelButtonTextForgotten: this.$t(
        "forgotten-password-view.forgotten-cancel"
      ),
      forgottenPassword: this.$t("forgotten-password-view.forgotten-password"),
      showSnackbar: false,
      snackbarMessage: "",
      buttonActionText: this.$t("snackbar.buttonActionText"),
      emailNotSentText: this.$t("snackbar.send-email-error"),
      emailSentText: this.$t("snackbar.email-sent"),
      snackbarCustomClass: "snackbar-div",
    };
  },
  setup() {
    const computedComponent = computed(() => {
      if (useRoute().name === pageNames.FORGOTTEN_PASSWORD_PAGE) {
        return inputEmailResetPassword;
      } else if (useRoute().name === pageNames.RESET_PASSWORD_PAGE) {
        return resetPassword;
      } else if (
        useRoute().name === pageNames.FORGOTTEN_PASSWORD_INVALID_LINK
      ) {
        return resetPasswordLinkException;
      } else return null;
    });
    return { computedComponent };
  },
  methods: {
    createSnackBar(message: string) {
      if (message === this.emailSentText) {
        this.snackbarCustomClass = "snackbar-div-large";
      }
      this.showSnackbar = true;
      this.snackbarMessage = message;
      setTimeout(() => {
        this.showSnackbar = false;
      }, 4000);
    },
    closeSnackBar() {
      this.showSnackbar = false;
    },
    cancelForgotten() {
      loadingStore.dispatch("changeLoadingState", true);
      router.push("/login");
    },
  },
  beforeCreate: function () {
    document.body.className = "ForgottenPasswordView";
  },
});
