import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_main_header = _resolveComponent("main-header")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", null, [
      _createVNode(_component_main_header)
    ]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.computedComponent)))
  ]))
}