import { Module } from "vuex";
import { RootState } from "@/store/store";

interface project {
  ID: string;
  project_color: string;
  project_description: string;
  project_name: string;
  source: string;
  user_uid: string;
}

export interface editProjectState {
  enableProjectEditing: boolean;
  editableProjectInfo: project;
}

export const editProjectStore: Module<editProjectState, RootState> = {
  state: {
    enableProjectEditing: false,
    editableProjectInfo: {} as project,
  },
  mutations: {
    setEnableProjectEditing(state, payload) {
      state.enableProjectEditing = payload;
    },
    setEditableProjectInfo(state, payload) {
      state.editableProjectInfo = payload;
    },
  },
  actions: {
    updateEnableProjectEditing(context, enableProjectEditing) {
      context.commit("setEnableProjectEditing", enableProjectEditing);
    },
    updateEditableProjectInfo(context, editableProjectInfo) {
      context.commit("setEditableProjectInfo", editableProjectInfo);
    },
  },
  getters: {
    getEnableProjectEditing(state) {
      return state.enableProjectEditing;
    },
    getEditableProjectInfo(state) {
      return state.editableProjectInfo;
    },
  },
};
