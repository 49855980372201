
import { defineComponent } from "vue";

import router from "@/router";
import store from "@/store/store";
import taButton from "@/components/Button.vue";
import { addProject, updateProject } from "@/service/ProjectService";
import colorSelector from "@/components/LocalTaskComponents/ColorSelector.vue";
import { TransferableInterface } from "@/models/time_assistant/ta_project";
import { AsanaTask } from "@/models/time_assistant/asana_task";

export default defineComponent({
  name: "Project creation view",
  components: { taButton, colorSelector },
  data() {
    return {
      projectId: "",
      projectName: "",
      projectDescription: "",
      cancelText: this.$t("component-new-task-view.cancel-text"),
      saveText: this.$t("component-new-task-view.save-text"),
      localSourceName: "Time Assistant",
      selectedColor: "",
      hideFirstError: true,
      hideSecondError: true,
      firstErrorMessageText: this.$t(
        "project-creation-view-component.first-error-message"
      ),
      secondErrorMessageText: this.$t(
        "project-creation-view-component.second-error-message"
      ),
      requiredSymbol: this.$t("required.symbol"),
      currentAcquiredProjects: store.getters.getTaProjectArrayState,
      currentAcquiredTasks: store.getters.getTaObjectiveArrayState,
    };
  },
  methods: {
    updateSelectedColor(colorName: string) {
      this.selectedColor = colorName;
      this.hideSecondError = true;
    },
    async saveProject() {
      if (!!this.projectName && !!this.selectedColor) {
        if (await store.getters.getEnableProjectEditing) {
          await store.dispatch("changeLoadingState", true);
          await updateProject(
            this.projectId,
            this.projectName,
            this.selectedColor,
            this.localSourceName,
            store.getters.getUser.firebase_uid,
            this.projectDescription
          );
          await store.dispatch("updateEnableProjectEditing", false);
          await store.dispatch("changeLoadingState", false);

          const newProject: TransferableInterface = {
            gid: this.projectId,
            color: this.selectedColor,
            name: this.projectName,
            source: this.localSourceName,
            user_uid: store.getters.getUser.firebase_uid,
            project_description: this.projectDescription,
          };
          if (newProject) {
            const updatedAcquiredProjects = this.currentAcquiredProjects.map(
              (project: TransferableInterface) => {
                if (project.gid === this.projectId) {
                  return (project = newProject);
                }
                return project;
              }
            );
            const updatedAcquiredTasks = this.currentAcquiredTasks.map(
              (task: AsanaTask) => {
                if (task.project_gid === this.projectId) {
                  task.color = this.selectedColor;
                }
                return task;
              }
            );

            store.dispatch(
              "updateTaProjectArrayState",
              updatedAcquiredProjects
            );
            store.dispatch("updateTaObjectiveArrayState", updatedAcquiredTasks);
            router.push("/dashboard/tasks");
          }
        } else {
          await store.dispatch("changeLoadingState", true);
          const projectId = await addProject(
            this.projectName,
            this.selectedColor,
            this.localSourceName,
            store.getters.getUser.firebase_uid,
            this.projectDescription
          );

          const newProject: TransferableInterface = {
            gid: projectId,
            color: this.selectedColor,
            name: this.projectName,
            source: this.localSourceName,
            user_uid: store.getters.getUser.firebase_uid,
            project_description: this.projectDescription,
          };
          if (projectId) {
            this.currentAcquiredProjects.push(newProject);
            store.dispatch(
              "updateTaProjectArrayState",
              this.currentAcquiredProjects
            );
            await store.dispatch("changeLoadingState", false);
            router.push("/dashboard/tasks");
          }
        }
      } else {
        if (!this.projectName) {
          this.hideFirstError = false;
        }
        if (!this.selectedColor) {
          this.hideSecondError = false;
        }
      }
    },
    updateOptionalText() {
      let customValueRef = this.$refs.projectDescription as HTMLInputElement;
      this.projectDescription = customValueRef.value;
    },
    async leavePage() {
      if (await store.getters.getEnableProjectEditing) {
        await store.dispatch("updateEnableProjectEditing", false);
        router.push("/dashboard/projects");
      } else {
        router.push("/dashboard/tasks");
      }
    },
    projectNameValue() {
      let customValueRef = this.$refs.projectNameInput as HTMLInputElement;
      this.projectName = customValueRef.value;
      this.hideFirstError = true;
    },
  },
  async mounted() {
    await store.dispatch("updateShowSideBar", true);
    if (await store.getters.getEnableProjectEditing) {
      const projectInfo = await store.getters.getEditableProjectInfo;
      this.projectId = projectInfo.ID;
      (this.$refs.colorSelector as any).selectColor(projectInfo.project_color);
      (this.$refs.colorSelector as any).showColorDropdown();
      this.projectName = projectInfo.project_name;
      const customNameValueRef = this.$refs
        .projectNameInput as HTMLInputElement;
      customNameValueRef.value = this.projectName;
      this.projectDescription = projectInfo.project_description;
      const customOptionalValueRef = this.$refs
        .projectDescription as HTMLInputElement;
      customOptionalValueRef.value = this.projectDescription;
    }
  },
});
