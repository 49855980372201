export const WHO_CONSTANT = "WHO";
export const WHY_CONSTANT = "WHY";
export const WHAT_CONSTANT = "WHAT";

export const LENGTH_120 = "120";

export const TIME_ASSISTANT = "Time Assistant";

export const NO_COLOR = "No color";
export const NO_GID = "No gid";
export const NO_NAME = "No name";
export const PROJECT_TYPE = "Project";
export const NO_FIREBASE_ID = "No firebase_id";
