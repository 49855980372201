
import { computed, defineComponent } from "vue";

export default defineComponent({
  emits: ["closeSnackBar"],
  props: {
    buttonActionText: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    let showSnackbar = computed(() => {
      return props.show;
    });

    return { showSnackbar };
  },
  methods: {
    closeSnackBar() {
      this.$emit("closeSnackBar");
    },
  },
});
