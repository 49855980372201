import { Module } from "vuex";
import { RootState } from "@/store/store";
import { generateBearerToken } from "@/infrastructure/http/ApiGatewaySetup";

export interface tokenState {
  bearerTokenSSO: string | null;
  bearerTokenTA: string | null;
  email: string | null;
}

export const tokenStore: Module<tokenState, RootState> = {
  state: {
    bearerTokenSSO: null,
    bearerTokenTA: null,
    email: "",
  },
  mutations: {
    setBearerTokenSSO(state, payload: string) {
      state.bearerTokenSSO = "Bearer " + payload;
    },
    setBearerTokenTA(state, payload: string) {
      state.bearerTokenTA = "Bearer " + payload;
    },
    setEmail(state, payload: string) {
      state.email = payload;
    },
  },
  actions: {
    async generateSSOToken(context) {
      const token = await generateBearerToken(
        process.env.VUE_APP_SSO_APPLICATION + ""
      );
      context.commit("setBearerTokenSSO", token);
    },
    async generateTAToken(context) {
      const token = await generateBearerToken(
        process.env.VUE_APP_TA_APPLICATION + ""
      );
      context.commit("setBearerTokenTA", token);
    },
    async saveEmail(context, email: string) {
      context.commit("setEmail", email);
    },
  },
  getters: {
    getBearerTokenSSO(state) {
      return state.bearerTokenSSO;
    },
    getBearerTokenTA(state) {
      return state.bearerTokenTA;
    },
    getEmail(state) {
      return state.email;
    },
  },
};
