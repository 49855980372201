import { Module } from "vuex";
import { RootState } from "@/store/store";
import { UserInterface } from "@/models/sso/user";

export interface userState {
  user: UserInterface | null;
  session_cookie: string | null;
  adminSelectionUserUid: string;
}

export const userStore: Module<userState, RootState> = {
  state: {
    user: null,
    session_cookie: null,
    adminSelectionUserUid: "",
  },
  mutations: {
    setUser(state, payload: UserInterface) {
      state.user = payload;
    },
    setSessionCookie(state, payload: string) {
      state.session_cookie = payload;
    },
    setAdminSelectionUserUid(state, payload: string) {
      state.adminSelectionUserUid = payload;
    },
  },
  actions: {
    async saveLoggedUser(context, user: UserInterface) {
      if (this.getters.getUser === null) {
        context.commit("setUser", user);
        console.log("New User Logged", user?.email);
      } else {
        const loggedUser = this.getters.getUser;
        console.log("There is already an user logged:", loggedUser.email);
      }
    },
    async saveSessionCookie(context, session_cookie) {
      if (this.getters.getSessionCookie === null) {
        context.commit("setSessionCookie", session_cookie);
      } else {
        const cookie = this.getters.getSessionCookie;
        console.log("The user is already logged in with this cookie:", cookie);
      }
    },
    async resetLoggedUser(context) {
      context.commit("setUser", null);
    },
    async resetSessionCookie(context) {
      context.commit("setSessionCookie", null);
    },
    async updateAdminSelectionUserUid(context, adminSelectionUserUid) {
      context.commit("setAdminSelectionUserUid", adminSelectionUserUid);
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getSessionCookie(state) {
      return state.session_cookie;
    },
    getAdminSelectionUserUid(state) {
      return state.adminSelectionUserUid;
    },
  },
};
