import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "snackbar-div" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snackbar = _resolveComponent("snackbar")!
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_snackbar, {
        buttonActionText: _ctx.buttonActionText,
        text: _ctx.snackbarMessage,
        show: _ctx.showSnackbar,
        onCloseSnackBar: _ctx.closeSnackBar
      }, null, 8, ["buttonActionText", "text", "show", "onCloseSnackBar"])
    ]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.computedComponent), {
      onRegistrationEmailSent: _ctx.createSnackBar,
      onRegistrationEmailNotSent: _ctx.createSnackBar,
      onUserRegistrationSuccess: _ctx.createSnackBar,
      onUserRegistrationFailed: _ctx.createSnackBar
    }, null, 8, ["onRegistrationEmailSent", "onRegistrationEmailNotSent", "onUserRegistrationSuccess", "onUserRegistrationFailed"])),
    _createElementVNode("div", null, [
      _createVNode(_component_main_footer)
    ])
  ]))
}