
import { defineComponent } from "vue";
import loadingWrapper from "@/components/loadingWrapper.vue";
import sideBar from "@/components/SideBarMenu.vue";
import NotSupportedVersionComponent from "@/components/NotSupportedVersionComponent.vue";
import loadingStore from "@/store/store";
import emitter from "@/utils/emitter";
import snackbar from "@/components/Snackbar.vue";

export default defineComponent({
  components: {
    loadingWrapper,
    snackbar,
    NotSupportedVersionComponent,
    sideBar,
  },
  computed: {
    isLoading() {
      return loadingStore.getters.getLoadingState;
    },
  },
  data() {
    return {
      buttonActionText: this.$t("snackbar.buttonActionText"),
      showSnackbar: false,
      snackbarMessage: "",
    };
  },
  created() {
    emitter.on("passwordUpdated", (message: any) =>
      this.createSnackBar(message)
    );
  },
  methods: {
    closeSnackBar() {
      this.showSnackbar = false;
    },
    createSnackBar(message: string) {
      this.showSnackbar = true;
      this.snackbarMessage = message;
      setTimeout(() => {
        this.showSnackbar = false;
      }, 4000);
    },
  },
});
