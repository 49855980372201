import jwt from "jsonwebtoken";
import axios from "axios";

export async function createJWT(application: string): Promise<string> {
  let privateKey, scope, issuer, subject;
  if (application === process.env.VUE_APP_SSO_APPLICATION) {
    // Add here the private key of the service account you want to use
    privateKey = (process.env.VUE_APP_JWT_SSO_PRIVATE_KEY || "").replace(
      /\\n/g,
      "\n"
    );
    scope = process.env.VUE_APP_JWT_SSO_SCOPE || "";
    issuer = process.env.VUE_APP_JWT_SSO_ISSUER || "";
    subject = process.env.VUE_APP_JWT_SSO_SUBJECT || "";
  } else {
    // Add here the private key of the service account you want to use
    privateKey = (process.env.VUE_APP_JWT_TA_PRIVATE_KEY || "").replace(
      /\\n/g,
      "\n"
    );
    scope = process.env.VUE_APP_JWT_TA_SCOPE || "";
    issuer = process.env.VUE_APP_JWT_TA_ISSUER || "";
    subject = process.env.VUE_APP_JWT_TA_SUBJECT || "";
  }

  // In the scope, add the url of the gateway
  const token = jwt.sign({ scope: scope }, privateKey, {
    algorithm: "RS256",
    issuer: issuer,
    subject: subject,
    expiresIn: "1h",
    audience: "https://oauth2.googleapis.com/token",
  });
  return token;
}

export async function getTokenId(jwt: string): Promise<string> {
  const body = {
    grant_type: "urn:ietf:params:oauth:grant-type:jwt-bearer",
    assertion: jwt,
  };

  let idtoken = "";

  await axios
    .post("https://oauth2.googleapis.com/token", body)
    .then(async function (response) {
      idtoken = response.data.id_token;
    })
    .catch(async function (error) {
      idtoken = "Error retrieving the token!\nError: " + error;
    });
  return idtoken;
}

export async function generateBearerToken(
  application: string
): Promise<string> {
  const jwt = await createJWT(application);
  const idtoken = await getTokenId(jwt);
  return idtoken;
}
