import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snackbar = _resolveComponent("snackbar")!
  const _component_leftBlock = _resolveComponent("leftBlock")!
  const _component_ta_button = _resolveComponent("ta-button")!
  const _component_rightBlock = _resolveComponent("rightBlock")!
  const _component_main_block = _resolveComponent("main-block")!
  const _component_main_footer = _resolveComponent("main-footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass(['snackbar-div', _ctx.snackbarCustomClass])
    }, [
      _createVNode(_component_snackbar, {
        buttonActionText: _ctx.buttonActionText,
        text: _ctx.snackbarMessage,
        show: _ctx.showSnackbar,
        onCloseSnackBar: _ctx.closeSnackBar
      }, null, 8, ["buttonActionText", "text", "show", "onCloseSnackBar"])
    ], 2),
    _createVNode(_component_main_block, { customClass: 'main-block-login' }, {
      default: _withCtx(() => [
        _createVNode(_component_leftBlock, {
          text: _ctx.forgottenPassword,
          imageSrc: ""
        }, null, 8, ["text"]),
        _createVNode(_component_rightBlock, null, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.computedComponent), {
              onSuccessEmail: _ctx.createSnackBar,
              onFailedEmail: _ctx.createSnackBar,
              onErrorUpdatingPassword: _ctx.createSnackBar,
              onPasswordUpdateSuccess: _ctx.createSnackBar
            }, null, 8, ["onSuccessEmail", "onFailedEmail", "onErrorUpdatingPassword", "onPasswordUpdateSuccess"])),
            _createVNode(_component_ta_button, {
              class: "cancel-button-forgotten",
              text: _ctx.cancelButtonTextForgotten,
              customClass: 'cancel-button',
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.cancelForgotten()))
            }, null, 8, ["text"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", null, [
      _createVNode(_component_main_footer)
    ])
  ]))
}