import { createApp } from "vue";
import App from "@/App.vue";
import "./assets/global.scss";
import router from "@/router";
import store from "@/store/store";
import i18n from "./i18n";
import { initializeApplication } from "@/utils/firebase-init";
import VueClickAway from "vue3-click-away";

initializeApplication();

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(VueClickAway)
  .mount("#app");

export default app;
