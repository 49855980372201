export const IN_PROGRESS = "In Progress";
export const TO_DO = "To Do";
export const DEV_TESTING = "Dev testing";
export const INTEGRATION = "Integration";
export const DONE = "Done";
export const READY_FOR_PROD = "Ready for Prod";
export const MR = "MR";
export const HIGH = "High";
export const MEDIUM = "Medium";
export const LOW = "Low";

const NONE = "none";
const RED = "dark-red";
const ORANGE = "dark-orange";
const YELLOW_ORANGE = "light-orange";
const YELLOW = "dark-brown";
const YELLOW_GREEN = "light-green";
const GREEN = "dark-green";
const BLUE_GREEN = "light-teal";
const AQUA = "dark-teal";
const BLUE = "light-blue";
const INDIGO = "dark-purple";
const PURPLE = "light-purple";
const MAGENTA = "light-pink";
const HOT_PINK = "dark-pink";
const PINK = "light-red";
const COOL_GRAY = "light-warm-gray";

export const projectConstants: any = {
  [NONE]: "none",
  [RED]: "dark-red",
  [ORANGE]: "dark-orange",
  [YELLOW_ORANGE]: "light-orange",
  [YELLOW]: "dark-brown",
  [YELLOW_GREEN]: "light-green",
  [GREEN]: "dark-green",
  [BLUE_GREEN]: "light-teal",
  [AQUA]: "dark-teal",
  [BLUE]: "light-blue",
  [INDIGO]: "dark-purple",
  [PURPLE]: "light-purple",
  [MAGENTA]: "light-pink",
  [HOT_PINK]: "dark-pink",
  [PINK]: "light-red",
  [COOL_GRAY]: "light-warm-gray",
};
