import { Module } from "vuex";
import { RootState } from "@/store/store";
import { AsanaTask } from "@/models/time_assistant/asana_task";
import { TimeEntryInformations } from "@/models/time_assistant/time_entry_information";

export interface activityState {
  project: null;
  task: AsanaTask;
  roleID: string;
  roleName: string;
  isDefaultProfileSelected: boolean;
  addDefaultRole: boolean;
  removeDefaultRole: boolean;
  actions: string[];
  hour: number;
  minutes: number;
  timeSuggestions: number[];
  technologyArray: string[];
  newTechnologies: string[];
  confirmValueCheck: boolean;
  taskValue: string;
  custonTaskValue: string;
  flowCircleChecks: number[];
  dateValue: string;
  timeEntryLoading: boolean;
  timeEntryInformations: TimeEntryInformations;
  proceedToTagView: boolean;
  showCancelPopup: boolean;
  routedFromTasklist: boolean;
}

export const activityStore: Module<activityState, RootState> = {
  state: {
    project: null,
    task: ({} as AsanaTask) || undefined,
    roleID: "",
    roleName: "",
    isDefaultProfileSelected: false,
    addDefaultRole: false,
    removeDefaultRole: false,
    actions: [],
    hour: 0,
    minutes: 0,
    timeSuggestions: [],
    technologyArray: [],
    newTechnologies: [],
    confirmValueCheck: false,
    taskValue: "",
    custonTaskValue: "",
    flowCircleChecks: [],
    dateValue: "",
    timeEntryLoading: true,
    timeEntryInformations: ({} as TimeEntryInformations) || undefined,
    proceedToTagView: false,
    showCancelPopup: false,
    routedFromTasklist: false,
  },
  mutations: {
    resetState(state) {
      state.project = null;
      state.task = ({} as AsanaTask) || undefined;
      state.roleID = "";
      state.roleName = "";
      state.isDefaultProfileSelected = false;
      state.addDefaultRole = false;
      state.removeDefaultRole = false;
      state.actions = [];
      state.hour = 0;
      state.minutes = 0;
      state.technologyArray = [];
      state.newTechnologies = [];
      state.confirmValueCheck = false;
      state.taskValue = "";
      state.custonTaskValue = "";
      state.flowCircleChecks = [];
      state.dateValue = "";
      state.timeEntryLoading = true;
      state.timeEntryInformations = {};
      state.proceedToTagView = false;
      state.showCancelPopup = false;
      state.routedFromTasklist = false;
    },
    softReset(state) {
      state.roleID = "";
      state.roleName = "";
      state.addDefaultRole = false;
      state.removeDefaultRole = false;
      state.actions = [];
      state.hour = 0;
      state.minutes = 0;
      state.technologyArray = [];
      state.newTechnologies = [];
      state.confirmValueCheck = false;
      state.taskValue = "";
      state.custonTaskValue = "";
      state.flowCircleChecks = [];
      state.dateValue = "";
      state.timeEntryLoading = true;
      state.timeEntryInformations = ({} as TimeEntryInformations) || undefined;
      state.proceedToTagView = false;
      state.showCancelPopup = false;
      state.routedFromTasklist = false;
    },
    resetTime(state) {
      state.hour = 0;
      state.minutes = 0;
    },
    setProject(state, payload) {
      state.project = payload;
    },
    setTask(state, payload) {
      state.task = payload;
    },
    setRoleID(state, payload) {
      state.roleID = payload;
    },
    setRoleName(state, payload) {
      state.roleName = payload;
    },
    setIsDefaultProfileSelected(state, payload) {
      state.isDefaultProfileSelected = payload;
    },
    setAddDefaultRole(state, payload) {
      state.addDefaultRole = payload;
    },
    setRemoveDefaultRole(state, payload) {
      state.removeDefaultRole = payload;
    },
    setTimeSuggestions(state, payload) {
      state.timeSuggestions = payload;
    },
    setTechnologyArray(state, payload) {
      state.technologyArray = payload;
    },
    setNewTechnologies(state, payload) {
      state.newTechnologies = payload;
    },
    setConfirmValueCheck(state, payload) {
      state.confirmValueCheck = payload;
    },
    setTaskValue(state, payload) {
      state.taskValue = payload;
    },
    setCustonTaskValue(state, payload) {
      state.custonTaskValue = payload;
    },
    setDateValue(state, payload) {
      state.dateValue = payload;
    },
    setActions(state, payload: []) {
      state.actions = payload;
    },
    setHour(state, payload) {
      state.hour = payload;
    },
    setMinutes(state, payload) {
      state.minutes = payload;
    },
    setFlowCircleChecks(state, payload) {
      state.flowCircleChecks = payload;
    },
    setTimeEntryLoading(state, payload) {
      state.timeEntryLoading = payload;
    },
    setTimeEntryInformations(state, payload) {
      state.timeEntryInformations = payload;
    },
    setProceedToTagView(state, payload) {
      state.proceedToTagView = payload;
    },
    setShowCancelPopup(state, payload) {
      state.showCancelPopup = payload;
    },
    setRoutedFromTasklist(state, payload) {
      state.routedFromTasklist = payload;
    },
  },
  actions: {
    updateProject(context, project) {
      context.commit("setProject", project);
    },
    updateTask(context, task) {
      context.commit("setTask", task);
    },
    updateRoleID(context, roleID) {
      context.commit("setRoleID", roleID);
    },
    updateRoleName(context, roleName) {
      context.commit("setRoleName", roleName);
    },
    updateIsDefaultProfileSelected(context, isDefaultProfileSelected) {
      context.commit("setIsDefaultProfileSelected", isDefaultProfileSelected);
    },
    updateAddDefaultRole(context, addDefaultRole) {
      context.commit("setAddDefaultRole", addDefaultRole);
    },
    updateRemoveDefaultRole(context, removeDefaultRole) {
      context.commit("setRemoveDefaultRole", removeDefaultRole);
    },
    updateTimeSuggestions(context, timeSuggestions) {
      context.commit("setTimeSuggestions", timeSuggestions);
    },
    updateTechnologyArray(context, technologyArray) {
      context.commit("setTechnologyArray", technologyArray);
    },
    updateNewTechnologies(context, newTechnologies) {
      context.commit("setNewTechnologies", newTechnologies);
    },
    updateConfirmValueCheck(context, confirmValueCheck) {
      context.commit("setConfirmValueCheck", confirmValueCheck);
    },
    updateTaskValue(context, taskValue) {
      context.commit("setTaskValue", taskValue);
    },
    updateCustonTaskValue(context, custonTaskValue) {
      context.commit("setCustonTaskValue", custonTaskValue);
    },
    updateDateValue(context, dateValue) {
      context.commit("setDateValue", dateValue);
    },
    updateActions(context, actions: []) {
      context.commit("setActions", actions);
    },
    updateHour(context, hour) {
      context.commit("setHour", hour);
    },
    updateMinutes(context, minutes) {
      context.commit("setMinutes", minutes);
    },
    updateFlowCircleChecks(context, flowCircleChecks) {
      context.commit("setFlowCircleChecks", flowCircleChecks);
    },
    updateTimeEntryLoading(context, timeEntryLoading) {
      context.commit("setTimeEntryLoading", timeEntryLoading);
    },
    updateTimeEntryInformations(context, time_entry_information) {
      context.commit("setTimeEntryInformations", time_entry_information);
    },
    updateProceedToTagView(context, proceedToTagView) {
      context.commit("setProceedToTagView", proceedToTagView);
    },
    updateShowCancelPopup(context, showCancelPopup) {
      context.commit("setShowCancelPopup", showCancelPopup);
    },
    updateRoutedFromTasklist(context, routedFromTasklist) {
      context.commit("setRoutedFromTasklist", routedFromTasklist);
    },
  },
  getters: {
    getProject(state) {
      return state.project;
    },
    getTask(state) {
      return state.task;
    },
    getRoleID(state) {
      return state.roleID;
    },
    getRoleName(state) {
      return state.roleName;
    },
    getAddDefaultRole(state) {
      return state.addDefaultRole;
    },
    getIsDefaultProfileSelected(state) {
      return state.isDefaultProfileSelected;
    },
    getRemoveDefaultRole(state) {
      return state.removeDefaultRole;
    },
    getTimeSuggestions(state) {
      return state.timeSuggestions;
    },
    getTechnologyArray(state) {
      return state.technologyArray;
    },
    getNewTechnologies(state) {
      return state.newTechnologies;
    },
    getConfirmValueCheck(state) {
      return state.confirmValueCheck;
    },
    getaskValue(state) {
      return state.taskValue;
    },
    getCustonTaskValue(state) {
      return state.custonTaskValue;
    },
    getDateValue(state) {
      return state.dateValue;
    },
    getActions(state) {
      return state.actions;
    },
    getHour(state) {
      return state.hour;
    },
    getMinutes(state) {
      return state.minutes;
    },
    getFlowCircleChecks(state) {
      return state.flowCircleChecks;
    },
    getTimeEntryLoading(state) {
      return state.timeEntryLoading;
    },
    getTimeEntryInformations(state) {
      return state.timeEntryInformations;
    },
    getProceedToTagView(state) {
      return state.proceedToTagView;
    },
    getShowCancelPopup(state) {
      return state.showCancelPopup;
    },
    getRoutedFromTasklist(state) {
      return state.routedFromTasklist;
    },
  },
};
