
import { defineComponent } from "vue";

import router from "@/router";
import store from "@/store/store";
import * as pageNames from "@/router/pageNames";
import {
  TaProject,
  TransferableInterface,
} from "@/models/time_assistant/ta_project";
import { UserInterface } from "@/models/sso/user";
import { deleteUserProject, projectsByUser } from "@/service/ProjectService";
import Tooltip from "@/components/Tooltip.vue";
import ProjectDeletePopoup from "@/components/GenericComponents/DeletePopup.vue";

export default defineComponent({
  name: "Project management view",
  components: { Tooltip, ProjectDeletePopoup },
  data() {
    return {
      taUserProjects: [] as TaProject[],
      deleteTooltipText: "Delete project",
      storedTaProjectList: store.getters.getTaProjectArrayState,
      showDeleteWarning: false,
      deletableProjectID: "",
      projectManagementWarningText: this.$t(
        "local-task-component-locales.warning-message"
      ),
      projectManagementConfirmDeleteText: this.$t(
        "local-task-component-locales.delete-message"
      ),
      projectManagementCancelDeleteText: this.$t(
        "local-task-component-locales.cancel-message"
      ),
    };
  },
  methods: {
    async deleteSelectedProject() {
      await this.deleteProject(this.deletableProjectID);
    },
    async deleteProject(ID: string) {
      if (!this.showDeleteWarning) {
        this.showDeleteWarning = !this.showDeleteWarning;
        this.deletableProjectID = ID;
      } else {
        this.showDeleteWarning = false;
        await store.dispatch("changeLoadingState", true);
        await deleteUserProject(ID);

        this.taUserProjects = this.taUserProjects.filter(
          (project) => project.ID !== ID
        );

        this.storedTaProjectList = this.storedTaProjectList.filter(
          (project: TransferableInterface) => project.gid !== ID
        );
        store.dispatch("updateTaProjectArrayState", this.storedTaProjectList);
        await store.dispatch("changeLoadingState", false);
        await store.dispatch("updateloadTaskList", true);
      }
    },
    cancelDeletion() {
      this.showDeleteWarning = !this.showDeleteWarning;
    },
    async leavePage() {
      router.push("/dashboard/tasks");
    },
    async editProject(project: TaProject) {
      await store.dispatch("updateEditableProjectInfo", project);
      await store.dispatch("updateEnableProjectEditing", true);
      router.push("/dashboard/createProject");
    },
  },
  async mounted() {
    await store.dispatch("updateShowSideBar", true);
    await store.dispatch("changeLoadingState", true);
    const user: UserInterface = store.getters.getUser;
    this.taUserProjects = await projectsByUser(user.firebase_uid);
    store.dispatch(
      "updateSelectedSidebarElement",
      pageNames.PROJECT_MANAGEMENT_PAGE
    );
    await store.dispatch("changeLoadingState", false);
  },
});
