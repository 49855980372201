
import { defineComponent } from "vue";
import MainBlock from "@/components/MainBlock.vue";
import { sendVerificationEmail } from "@/service/RegistrationService";
import taButton from "@/components/Button.vue";
import store from "@/store/store";
import router from "@/router";
import loadingStore from "@/store/store";

export default defineComponent({
  emits: ["registrationEmailNotSent", "registrationEmailSent"],
  name: "Registration Verify",
  components: { MainBlock, taButton },
  data() {
    return {
      cancelButtonText: this.$t("registration.cancel"),
      emailNotSentText: this.$t("snackbar.send-email-error-registration"),
      emailSentText: this.$t("snackbar.verification-email-sent"),
    };
  },
  methods: {
    async sendVerifyEmail() {
      let isEmailValid = await sendVerificationEmail(store.getters.getEmail);
      if (!isEmailValid) {
        this.$emit("registrationEmailNotSent", this.emailNotSentText);
      } else {
        this.$emit("registrationEmailSent", this.emailSentText);
      }
    },
    cancelVerify() {
      loadingStore.dispatch("changeLoadingState", true);
      router.push("/login");
    },
  },
});
