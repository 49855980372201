
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import registration from "@/components/Registration.vue";
import registrationVerify from "@/components/RegistrationVerify.vue";
import registrationEnd from "@/components/RegistrationEnd.vue";
import registrationLinkException from "@/components/RegistrationLinkException.vue";
import mainFooter from "@/components/MainFooter.vue";
import * as pageNames from "@/router/pageNames";
import snackbar from "@/components/Snackbar.vue";

export default defineComponent({
  name: "RegistrationView",
  components: {
    mainFooter,
    registration,
    registrationVerify,
    registrationEnd,
    snackbar,
  },
  data() {
    return {
      showSnackbar: false,
      snackbarMessage: "",
      buttonActionText: this.$t("snackbar.buttonActionText"),
    };
  },
  setup() {
    const computedComponent = computed(() => {
      if (useRoute().name === pageNames.REGISTRATION_PAGE) {
        return registration;
      } else if (useRoute().name === pageNames.REGISTRATION_VERIFIY_PAGE) {
        return registrationVerify;
      } else if (useRoute().name === pageNames.REGISTRATION_END_PAGE) {
        return registrationEnd;
      } else if (
        useRoute().name === pageNames.REGISTRATION_INVALID_LINK ||
        useRoute().name === pageNames.REGISTRATION_ALREADY_REGISTERED_USER
      ) {
        return registrationLinkException;
      } else return null;
    });
    return { computedComponent };
  },
  beforeCreate: function () {
    document.body.className = "RegistrationView";
  },
  methods: {
    createSnackBar(message: string) {
      this.showSnackbar = true;
      this.snackbarMessage = message;
      setTimeout(() => {
        this.showSnackbar = false;
      }, 4000);
    },
    closeSnackBar() {
      this.showSnackbar = false;
    },
  },
});
