
import { defineComponent } from "vue";
import "clickout-event";

export default defineComponent({
  name: "Color Select",
  components: {},
  emits: ["selectedColor"],
  data() {
    return {
      projectColors: [
        "none",
        "dark-red",
        "dark-orange",
        "light-orange",
        "dark-brown",
        "light-green",
        "dark-green",
        "light-teal",
        "dark-teal",
        "light-blue",
        "dark-purple",
        "light-purple",
        "light-pink",
        "dark-pink",
        "light-red",
        "light-warm-gray",
      ],
      selectedColorName: "",
      hideDropdown: true,
    };
  },
  methods: {
    showColorDropdown() {
      this.hideDropdown = !this.hideDropdown;
    },
    selectColor(name: string) {
      this.selectedColorName = name;
      this.$emit("selectedColor", name);
      this.hideDropdown = !this.hideDropdown;
    },
  },
});
