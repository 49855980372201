import store from "@/store/store";
import * as timeEntryConstants from "@/constants/TimeEntryConstants";

export async function addToFlowCircleArray(addableValue: number) {
  const flowCircleArray = store.getters.getFlowCircleChecks;
  if (!flowCircleArray.includes(addableValue)) {
    //Push index value of the circle to array to check if circle should be marked
    flowCircleArray.push(addableValue);
  }
  await store.dispatch("updateFlowCircleChecks", flowCircleArray);
}

export async function removerFromFlowCircleArray(removableValue: number) {
  let flowCircleArray = store.getters.getFlowCircleChecks;
  flowCircleArray = flowCircleArray.filter(
    //Removes index value of the circles from array to check if circle should be marked
    (item: number) =>
      item !== removableValue &&
      item !== timeEntryConstants.COMPLETION_FLOW_CIRCLE_INDEX
  );
  await store.dispatch("updateFlowCircleChecks", flowCircleArray);
}
