import { UserApi } from "@/infrastructure/http/UserApiResource";
import {
  USER_ADDED_SUCCESSFULLY,
  USER_ALREADY_REGISTERED,
  VERIFICATION_EMAIL_SENT,
} from "@/constants/ApiResponseConstants";

export async function userInfoByDocumentId(
  user_id: string
): Promise<{ email: string; verified: string }> {
  const user_info = await UserApi.emailByDocumentId(user_id);
  return user_info;
}

export async function updateUser(
  user_id: string,
  name: string,
  rePassword: string
): Promise<boolean> {
  const result = await UserApi.updateUser(user_id, name, rePassword);
  if (result.success === USER_ADDED_SUCCESSFULLY) {
    return true;
  } else {
    return false;
  }
}

export async function checkEmailAndSignup(
  email: string,
  applicationName: string,
  applicationBasicRole: string
): Promise<boolean> {
  let emailValidationStatus = true;
  const data = await UserApi.checkEmailAndSignup(
    email,
    applicationName,
    applicationBasicRole
  );
  if (data === USER_ALREADY_REGISTERED) {
    emailValidationStatus = false;
  }
  return emailValidationStatus;
}

export async function sendVerificationEmail(email: string): Promise<boolean> {
  const data = await UserApi.sendVerificationEmail(email);
  if (data === VERIFICATION_EMAIL_SENT) return true;
  return false;
}
