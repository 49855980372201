
import { defineComponent } from "vue";
import taButton from "@/components/Button.vue";
import router from "@/router";

export default defineComponent({
  components: {
    taButton,
  },
  emits: ["denyEmit", "allowEmit"],
  props: {
    asanaData: {
      required: false,
      type: Boolean,
    },
    relatedEmail: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      denyText: this.$t("data-protection-popup.deny"),
      allowText: this.$t("data-protection-popup.agree"),
      warningText: "",
      connectingText: this.$t("data-protection-popup.allow-access"),
      requiredInforamtionText: "",
    };
  },
  methods: {
    denyEmit() {
      this.$emit("denyEmit");
    },
    allowEmit() {
      this.$emit("allowEmit");
    },
  },
  computed: {
    email() {
      return this.relatedEmail;
    },
  },
  mounted() {
    if (this.asanaData) {
      if (this.relatedEmail === undefined) {
        //update for better compare if new integrations are implemented
        router.push("/dashboard/tasks");
      }
      this.requiredInforamtionText = this.$t(
        "data-protection-popup.information"
      );
      this.warningText = `${this.connectingText} (${this.relatedEmail}) ${this.requiredInforamtionText}`;
    }
  },
});
