
import { defineComponent } from "vue";
import taButton from "@/components/Button.vue";

export default defineComponent({
  components: {
    taButton,
  },
  emits: ["deleteObjectEmit", "cancelDelete"],
  props: {
    warningText: {
      required: true,
      type: String,
    },
    confirmDeleteText: {
      required: true,
      type: String,
    },
    cancelDeleteText: {
      required: true,
      type: String,
    },
  },
  methods: {
    deleteObject() {
      this.$emit("deleteObjectEmit");
    },
    cancelDelete() {
      this.$emit("cancelDelete");
    },
  },
});
