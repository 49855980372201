
import { defineComponent } from "vue";
import Tooltip from "@/components/Tooltip.vue";
import { format } from "date-fns";
import { totalTimeString } from "@/utils/timeUtils";

export default defineComponent({
  name: "Info item",
  components: { Tooltip },
  emits: [
    "newTimeEntry",
    "viewTaskInfo",
    "duplicationEmit",
    "delitionEmit",
    "editionEmit",
    "viewFromAllEntries",
  ],
  props: {
    taskInfo: {
      type: Object,
    },
    allEntryView: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hours: 0,
      minutes: 0,
      duplicateTooltipText: this.$t(
        "component-entry-info-item-view.duplicate-tooltip"
      ),
      deleteTooltipText: this.$t(
        "component-entry-info-item-view.delete-tooltip"
      ),
      actionWordArray: [],
      tooltipActions: this.taskInfo?.actions.join(", "),
      taskName: this.taskInfo?.name,
      postitionSmall: "small",
    };
  },
  methods: {
    roleLabel(labelText: string) {
      return this.$t(`profile-roles-label.${labelText}`);
    },
    tooltipLabels(labelText: string) {
      return this.$t(`profile-roles-name.${labelText}`);
    },
    emitDuplication() {
      this.$emit("duplicationEmit");
    },
    emitDelition() {
      this.$emit("delitionEmit");
    },
    emitEdition() {
      if (this.allEntryView) {
        this.$emit("viewFromAllEntries");
      } else {
        this.$emit("editionEmit");
      }
    },
  },
  computed: {
    getTotalTime() {
      return totalTimeString(this.taskInfo?.spent_time_min);
    },
    visualDateValue() {
      let dateToString = new Date(this.taskInfo?.updatedAt as string);

      return format(dateToString, "dd.MM.yyyy");
    },
  },
  mounted() {
    const actionString = this.taskInfo?.actions.join(", ");
    this.actionWordArray = actionString.split(" ");
  },
});
