
import { defineComponent } from "vue";
import mainFooter from "@/components/MainFooter.vue";
import inputField from "@/components/InputField.vue";
import MainBlock from "@/components/MainBlock.vue";
import taButton from "@/components/Button.vue";
import {
  updateUser,
  userInfoByDocumentId,
} from "@/service/RegistrationService";
import router from "@/router";
import loadingStore from "@/store/store";
import store from "@/store/store";
import { Form } from "vee-validate";

export default defineComponent({
  emits: ["userRegistrationSuccess", "userRegistrationFailed"],
  name: "Registration End",
  components: { mainFooter, inputField, taButton, MainBlock, Form },
  data() {
    return {
      cancelButtonText: this.$t("registration.cancel"),
      passwordType: "password",
      email: this.emailById,
      nameText: this.$t("registration-end.name-input"),
      passwordText: this.$t("registration-end.password-input"),
      reEnterText: this.$t("registration-end.re-enter-password"),
      continueText: this.$t("registration-end.continue-button"),
      registrationSuccessText: this.$t("snackbar.user-registration-success"),
      registrationFailedText: this.$t("snackbar.user-registration-failed"),
      errorMessage: "",
      name: "",
      password: "",
      rePassword: "",
      userId: "",
      shownEmail: "",
      showContent: false,
      enableButtonLoader: false,
    };
  },
  async mounted() {
    loadingStore.dispatch("changeLoadingState", true);
    if (this.$route.query.id) {
      this.userId = this.$route.query.id as string;
      const user_info = await userInfoByDocumentId(this.userId);
      if (user_info.verified) {
        await store.dispatch("saveEmail", user_info.email);
        await router.push("/registration/useralreadyregistered");
      }
      this.shownEmail = user_info.email;
    } else {
      this.shownEmail = this.$t("registration-end.verification-link");
    }
    loadingStore.dispatch("changeLoadingState", false);
    this.showContent = true;
  },
  methods: {
    async updateUserInfo() {
      if (!this.enableButtonLoader) {
        this.errorMessage = "";
        if (this.password !== this.rePassword && this.shownEmail) {
          this.errorMessage = this.$t("registration-end.passwords-match");
        } else if (this.password !== "" && this.name !== "") {
          this.enableButtonLoader = true;
          const updateResult = await updateUser(
            this.userId,
            this.name,
            this.rePassword
          );
          this.enableButtonLoader = false;
          if (updateResult) {
            this.$emit("userRegistrationSuccess", this.registrationSuccessText);
          } else {
            this.$emit("userRegistrationFailed", this.registrationFailedText);
          }
        }
      }
    },
    receiveName(data: string) {
      this.name = data;
    },
    receivePassword(data: string) {
      this.password = data;
    },
    receiveRePassword(data: string) {
      this.rePassword = data;
      this.errorMessage = "";
    },
    showPassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    cancelUpdate() {
      loadingStore.dispatch("changeLoadingState", true);
      router.push("/login");
    },
  },
});
