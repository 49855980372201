
import { defineComponent } from "vue";
import store from "@/store/store";
import { HOUR_OPTIONS, MINUTES_OPTIONS } from "@/constants/TimeEntryConstants";
import emitter from "@/utils/emitter";

export default defineComponent({
  emits: ["checkSuggestion"],
  name: "TimeSelector",
  props: {
    hour: {
      required: false,
      type: Number,
      default: 0,
    },
    minutes: {
      required: false,
      type: Number,
      default: 0,
    },
    hoursStored: {
      required: false,
      type: Number,
      default: 0,
    },
    minutesStored: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      hour_selected: 0,
      minutes_selected: 0,
      hourClicked: false,
      minutesClicked: false,
      hourOptions: HOUR_OPTIONS,
      minutesOptions: MINUTES_OPTIONS,
      hour_symbol: this.$t("time-selector.hour-symbol"),
      minute_symbol: this.$t("time-selector.minute-symbol"),
      triggerCloseMinutesDropdown: true,
    };
  },
  created() {
    emitter.on("selectSuggestionHour", (hour: any) => this.selectHour(hour));
    emitter.on("selectSuggestionMinutes", (minutes: any) =>
      this.selectMinutes(minutes)
    );
  },
  methods: {
    openHour() {
      this.hourClicked = !this.hourClicked;
    },
    openMinutes() {
      this.minutesClicked = !this.minutesClicked;
    },
    closeHourDropdown() {
      this.hourClicked = false;
    },
    closeMinutesDropdown() {
      if (this.triggerCloseMinutesDropdown) {
        this.minutesClicked = false;
      }
      this.triggerCloseMinutesDropdown = true;
    },
    async selectMinutes(value: number) {
      this.minutes_selected = value;
      this.minutesClicked = false;
      const totalMinutes = this.hour_selected * 60 + this.minutes_selected;
      await store.dispatch("updateMinutes", value);
      this.$emit("checkSuggestion", totalMinutes);
    },
    async selectHour(value: number) {
      this.hour_selected = value;
      this.hourClicked = false;
      this.minutesClicked = true;
      this.triggerCloseMinutesDropdown = false;
      const totalMinutes = this.hour_selected * 60 + this.minutes_selected;
      await store.dispatch("updateHour", value);
      this.$emit("checkSuggestion", totalMinutes);
    },
  },
  mounted() {
    this.hour_selected = this.hoursStored;
    this.minutes_selected = this.minutesStored;
  },
});
