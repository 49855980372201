
import { defineComponent } from "vue";
import mainFooter from "@/components/MainFooter.vue";

export default defineComponent({
  name: "Not Supported Version Component",
  components: { mainFooter },
  data() {
    return {
      title: this.$t("not-supported-version.title"),
      subtitle: this.$t("not-supported-version.subtitle"),
    };
  },
});
