import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/images/task_filled_outlined.svg'
import _imports_1 from '@/assets/images/list_icon.svg'
import _imports_2 from '@/assets/images/folder_icon.svg'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showSideBar)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["main-side-bar-block", { expanded: _ctx.expandSidebar }]),
        onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.expandSidebar = false)),
        onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.expandSidebar = true))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["side-bar-button", {
        sideBarSelected: _ctx.myTasksSelected === _ctx.selectedString,
        'expanded-button': _ctx.expandSidebar,
      }]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToTaskList()))
        }, [
          _createElementVNode("img", {
            class: _normalizeClass(["task-list-icon icon-margin", { 'disable-icon-margin': !_ctx.expandSidebar }]),
            src: _imports_0
          }, null, 2),
          _createElementVNode("span", {
            class: _normalizeClass(["side-bar-button-text small-text", { visible: _ctx.expandSidebar }])
          }, _toDisplayString(_ctx.mytasksText), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["side-bar-button", {
        sideBarSelected: _ctx.allEntryListSelected === _ctx.selectedString,
        'expanded-button': _ctx.expandSidebar,
      }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToAllEntryList()))
        }, [
          _createElementVNode("img", {
            class: _normalizeClass(["all-entry-icon icon-margin", { 'disable-icon-margin': !_ctx.expandSidebar }]),
            src: _imports_1
          }, null, 2),
          _createElementVNode("span", {
            class: _normalizeClass(["side-bar-button-text small-text lesser-text-margin", { visible: _ctx.expandSidebar }])
          }, _toDisplayString(_ctx.allEntriesText), 3)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["side-bar-button", {
        sideBarSelected: _ctx.projectManagement === _ctx.selectedString,
        'expanded-button': _ctx.expandSidebar,
      }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToProjectCreationView()))
        }, [
          _createElementVNode("img", {
            class: _normalizeClass(["all-entry-icon icon-margin", { 'disable-icon-margin': !_ctx.expandSidebar }]),
            src: _imports_2
          }, null, 2),
          _createElementVNode("span", {
            class: _normalizeClass(["side-bar-button-text small-text lesser-text-margin", { visible: _ctx.expandSidebar }])
          }, _toDisplayString(_ctx.projectCreationText), 3)
        ], 2)
      ], 34))
    : _createCommentVNode("", true)
}