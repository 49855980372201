import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/images/eye_icon.svg'
import _imports_1 from '@/assets/images/eye_icon_no_visibility.svg'


const _hoisted_1 = { class: "input-field-wrapper" }
const _hoisted_2 = { class: "field-and-icon-wrapper" }
const _hoisted_3 = { class: "icon-div" }
const _hoisted_4 = { class: "small-text-salmon error-text" }
const _hoisted_5 = {
  key: 0,
  class: "small-text-orange-40 helper-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_field = _resolveComponent("field")!
  const _component_tooltip = _resolveComponent("tooltip")!
  const _component_error_message = _resolveComponent("error-message")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_field, {
        class: "input-field",
        rules: _ctx.validationRules,
        type: _ctx.type,
        name: _ctx.name,
        required: _ctx.required,
        placeholder: _ctx.placeholder,
        autocomplete: _ctx.autocomplete,
        modelValue: _ctx.insertedText,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.insertedText) = $event)),
        onClick: _ctx.onClick,
        onInput: _ctx.onInput
      }, null, 8, ["rules", "type", "name", "required", "placeholder", "autocomplete", "modelValue", "onClick", "onInput"]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.type === 'password')
          ? (_openBlock(), _createBlock(_component_tooltip, {
              key: 0,
              positionFromTop: _ctx.passwordTooltipPositionFromTop,
              text: _ctx.showPasswordTooltip
            }, {
              default: _withCtx(() => [
                (_ctx.showPasswordIcon && _ctx.type === 'password')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPassword())),
                      class: "icon",
                      src: _imports_0
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["positionFromTop", "text"]))
          : _createCommentVNode("", true),
        (_ctx.type === 'text')
          ? (_openBlock(), _createBlock(_component_tooltip, {
              key: 1,
              positionFromTop: _ctx.passwordTooltipPositionFromTop,
              text: _ctx.hidePasswordTooltip
            }, {
              default: _withCtx(() => [
                (_ctx.showPasswordIcon && _ctx.type === 'text')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showPassword())),
                      class: "icon",
                      src: _imports_1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["positionFromTop", "text"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createVNode(_component_error_message, {
      class: "small-text-salmon error-text",
      name: _ctx.name,
      required: _ctx.required,
      placeholder: _ctx.placeholder,
      autocomplete: _ctx.autocomplete,
      onClick: _ctx.onClick,
      onInput: _ctx.onInput
    }, null, 8, ["name", "required", "placeholder", "autocomplete", "onClick", "onInput"]),
    _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.errorMessage), 1),
    (
        (_ctx.type === 'password' || _ctx.type === 'text') && !_ctx.errorMessage && _ctx.showHint
      )
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.helperText), 1))
      : _createCommentVNode("", true)
  ]))
}