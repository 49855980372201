
import { defineComponent } from "vue";
import StatusChip from "@/components/StatusChip.vue";
import store from "@/store/store";
import { UserInterface } from "@/models/sso/user";
import Tooltip from "./Tooltip.vue";
import progressBar from "@/components/TimeEntryInfoComponents/TimeEntryProgressBar.vue";
import {
  estimatedTimeInMinutes,
  remainingTimeText,
} from "@/helpers/TaskHelper";

export default defineComponent({
  name: "Task item",
  components: { StatusChip, Tooltip, progressBar },
  emits: ["newTimeEntry", "viewTaskInfo"],
  props: {
    task: {
      type: Object,
    },
    showStatus: {
      type: Boolean,
    },
    showPriority: {
      type: Boolean,
    },
    selectedCheck: {
      type: String,
    },
    showFirstElement: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      user: store.getters.getUser as UserInterface,
      parentTaskName: this.task?.parent as number,
      allCustomFields: store.getters.getAllCustomFields,
      plusButtonTooltip: this.$t("tooltip.plus-button"),
      statusChipsTooltip: this.$t("tooltip.status-chips"),
      priorityChipsTooltip: this.$t("tooltip.priority-chips"),
      chipsTooltipPositionFromTop: "small",
      plusButtonTooltipPositionFromTop: "medium",
      hour_symbol: this.$t("time-selector.hour-symbol"),
      minute_symbol: this.$t("time-selector.minute-symbol"),
      notEndedText: this.$t("remaining-time.not-ended-text"),
      endedText: this.$t("remaining-time.ended-text"),
      timePercentage: 0,
      timeOver: false,
    };
  },
  methods: {
    createTimeEntry() {
      this.$emit("newTimeEntry", this.task);
    },
    async seeTaskInfo() {
      await store.dispatch("updateShowTaskInfo", true);
      this.$emit("viewTaskInfo", this.task);
    },
  },
  computed: {
    isSelected() {
      return this.task?.task_gid === this.selectedCheck;
    },
    remainingTime() {
      const estimated_time = this.task?.custom_fields?.estimated_time_hours;
      const time_spent = this.task?.totalTimeSpentMin;

      if (estimated_time) {
        const estimatedMinutes = estimatedTimeInMinutes(estimated_time);
        let remaining_time = estimatedMinutes;

        if (time_spent) {
          remaining_time = remaining_time - time_spent;
        }

        const hour = remaining_time / 60;
        const minutes = (hour - Math.floor(hour)) * 60;
        return remainingTimeText(
          remaining_time,
          hour,
          minutes,
          this.notEndedText,
          this.endedText,
          this.hour_symbol,
          this.minute_symbol
        );
      } else {
        return null;
      }
    },
  },
  mounted() {
    const estimated_time = this.task?.custom_fields?.estimated_time_hours;
    const time_spent = this.task?.totalTimeSpentMin;
    (this.$refs.taskItemProgressBar as any).remainingTime(
      estimated_time,
      time_spent
    );
  },
});
