export function sortAlphabeticalStringArray(stringArray: any) {
  stringArray.sort((a: { name: string }, b: { name: string }) => {
    const fa = a.name.toLowerCase(),
      fb = b.name.toLowerCase();
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
  return stringArray;
}
