import userStore from "@/store/store";
import { UserInterface } from "@/models/sso/user";

export async function storeUserAndSession(login_info: {
  user: UserInterface;
  session: string;
}): Promise<void> {
  await userStore.dispatch("saveLoggedUser", login_info.user);
  await userStore.dispatch("saveSessionCookie", login_info.session);
}

export async function resetUserAndSession(): Promise<void> {
  await userStore.dispatch("resetLoggedUser", null);
  await userStore.dispatch("resetSessionCookie", null);
}
