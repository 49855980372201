import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "registration-snackbar-div" }
const _hoisted_2 = { class: "h4-white main-text" }
const _hoisted_3 = { class: "small-text-white policy-message" }
const _hoisted_4 = { class: "small-text-orange policy-highlight" }
const _hoisted_5 = { class: "small-text-orange policy-highlight" }
const _hoisted_6 = { class: "input-field-container" }
const _hoisted_7 = { class: "default-text-white or-divider" }
const _hoisted_8 = { class: "google-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_snackbar = _resolveComponent("snackbar")!
  const _component_input_field = _resolveComponent("input-field")!
  const _component_ta_button = _resolveComponent("ta-button")!
  const _component_main_block = _resolveComponent("main-block")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_snackbar, {
        buttonActionText: _ctx.buttonActionText,
        text: _ctx.snackbarMessage,
        show: _ctx.showSnackbar,
        onCloseSnackBar: _ctx.closeSnackBar
      }, null, 8, ["buttonActionText", "text", "show", "onCloseSnackBar"])
    ]),
    _createVNode(_component_main_block, { customClass: 'main-block-registration' }, {
      default: _withCtx(() => [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t("registration.welcome-message")), 1),
        _createElementVNode("p", _hoisted_3, [
          _createTextVNode(_toDisplayString(_ctx.$t("registration.policy-message-beginning")) + " ", 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("registration.policy-message-first-highlight")), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("registration.policy-message-middle")) + " ", 1),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t("registration.policy-message-second-highlight")), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t("registration.policy-message-end")), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_input_field, {
            onKeyup: _withKeys(_ctx.checkEmailValidation, ["enter"]),
            class: "small-text-grey",
            placeholder: _ctx.inputFieldEmailPlaceholder,
            type: 'Email',
            required: true,
            errorMessage: _ctx.errorMessage,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showErrorMessage('', false))),
            onEmptyFieldEmail: _ctx.isInputFieldEmpty,
            onEmitEmail: _ctx.checkEmail,
            onNotLaCEmail: _ctx.setLaCCheck,
            onInput: _ctx.receive
          }, null, 8, ["onKeyup", "placeholder", "errorMessage", "onEmptyFieldEmail", "onEmitEmail", "onNotLaCEmail", "onInput"])
        ]),
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.checkEmailValidation())),
          class: "signup-button-container"
        }, [
          _createVNode(_component_ta_button, {
            text: _ctx.buttonText,
            backgroundColor: _ctx.backgroundColor,
            enableLoading: _ctx.enableButtonLoader
          }, null, 8, ["text", "backgroundColor", "enableLoading"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("registration-verify.or")), 1)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_ta_button, {
            text: _ctx.googleButtonText,
            backgroundColor: _ctx.googleButtonBackgroundColor,
            customClass: 'google-button',
            showGoogleImage: "",
            onClick: _ctx.loginWithGoogle
          }, null, 8, ["text", "backgroundColor", "onClick"])
        ]),
        _createVNode(_component_ta_button, {
          customClass: 'cancel-button',
          text: _ctx.cancelButtonText,
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.cancel()))
        }, null, 8, ["text"])
      ]),
      _: 1
    })
  ]))
}