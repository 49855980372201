import { User } from "@firebase/auth";
import { AbstractApiResource } from "@/infrastructure/http/AbstractApiResource";

class UserApiResource extends AbstractApiResource {
  public constructor() {
    super(process.env.VUE_APP_SSO_APPLICATION + "");
  }

  public async checkUser(user: User) {
    return await this.http
      .post("checkUser", {
        user: {
          email: user.email,
          display_name: user.displayName || "",
          firebase_uid: user.uid,
          isVerified: user.emailVerified,
        },
        application: {
          name: "time-assistant-v3",
          basicRole: "user",
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async loginWithGoogle(type: string, token: string, user: User) {
    return await this.http
      .post("login", {
        type: type,
        token: token,
        user: {
          email: user.email,
          display_name: user.displayName || "",
          firebase_uid: user.uid,
          isVerified: user.emailVerified,
        },
        applicationSent: {
          name: "time-assistant-v3",
          basicRole: "user",
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async loginWithUsernameAndPassword(email: string, password: string) {
    return await this.http
      .post("login", {
        email: email,
        password: password,
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async checkEmailAndSignup(
    email: string,
    applicationName: string,
    applicationBasicRole: string
  ) {
    return await this.http
      .get("checkEmailAndSignup", {
        params: {
          email,
          applicationName,
          applicationBasicRole,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async sendEmailResetUserPassword(email: string) {
    return await this.http
      .get("sendEmailResetUserPassword", {
        params: {
          email,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async sendVerificationEmail(email: string) {
    return await this.http
      .get("sendEmailVerify", {
        params: {
          email,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async emailByDocumentId(user_id: string) {
    return await this.http
      .get("emailByDocumentId", {
        params: {
          docId: user_id,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async updateUserPassword(password: string, id: string) {
    return await this.http
      .put("updateUserPassword", {
        password: password,
        id: id,
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async updateUser(user_id: string, name: string, rePassword: string) {
    return await this.http
      .put("updateUserInfo", {
        id: user_id,
        display_name: name,
        password: rePassword,
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async getMe() {
    return await this.http
      .get("getMe", {
        params: { application_name: process.env.VUE_APP_APPLICATION_NAME },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async logout() {
    return await this.http
      .post("logout")
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }
  public async getMyAsanaAuth() {
    return await this.http
      .get("getMyAsanaAuth")
      .then(this.handleResponse.bind(this))
      .catch(this.handleResponse.bind(this));
  }

  public async getAsanaAccessToken(code: string, uid: string) {
    return await this.http
      .get("getAsanaAccessToken", { params: { code: code, uid: uid } })
      .then(this.handleResponse.bind(this))
      .catch(this.handleResponse.bind(this));
  }

  public async integrationAccessToken(integration_name: string, uid: string) {
    return await this.http
      .get("integrationTokenKey", {
        params: { integration_name: integration_name, uid: uid },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleResponse.bind(this));
  }

  public async getAllUsers() {
    return await this.http
      .get("user")
      .then(this.handleResponse.bind(this))
      .catch(this.handleResponse.bind(this));
  }

  public async disableAsanaPopup(
    firebase_uid: string,
    document_id: string,
    disable_asana_popup: boolean
  ) {
    return await this.http
      .put("user", {
        user: {
          firebase_uid: firebase_uid,
          document_id: document_id,
          disable_asana_popup: disable_asana_popup,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async allIntegrationByUser(user_uid: string) {
    return await this.http
      .get("allIntegrationByUser", {
        params: { user_uid: user_uid },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleResponse.bind(this));
  }

  public async removeIntegration(intId: string) {
    return await this.http
      .delete("integrationByid", {
        params: { intId: intId },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleResponse.bind(this));
  }

  public async updateIntegration(id: string, confirmed_integration: boolean) {
    return await this.http
      .put("integrationByid", {
        integration: {
          id: id,
          confirmed_integration: confirmed_integration,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleResponse.bind(this));
  }
}

export const UserApi: UserApiResource = new UserApiResource();
