
import { defineComponent } from "vue";
import inputField from "@/components/InputField.vue";

export default defineComponent({
  name: "Search Bar",
  components: { inputField },
  data() {
    return {
      searchItem: "",
      showCloseIcon: false,
    };
  },
  methods: {
    search(data: string) {
      this.searchItem = data;
      this.showCloseIcon = !!data;
      this.$emit("searchItem", this.searchItem);
    },
    clearSearchText() {
      this.searchItem = "";
      this.showCloseIcon = false;

      this.$emit("searchItem", this.searchItem);
    },
  },
});
