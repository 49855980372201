import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_task_list = _resolveComponent("task-list")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_task_list, {
      tasks: _ctx.updateTasks,
      projects: _ctx.updateAsanaProjectList,
      taProjects: _ctx.localProjects,
      taTasks: _ctx.localTasks
    }, null, 8, ["tasks", "projects", "taProjects", "taTasks"])
  ]))
}