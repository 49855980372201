import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/images/done_check.svg'


const _hoisted_1 = { class: "checkbox-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(['check-box', _ctx.checkboxColorClass, _ctx.checkboxSizeClass]),
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.triggerCheck()))
    }, [
      (_ctx.checkActive)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            id: "check_img",
            class: _normalizeClass(['img', _ctx.imgClass]),
            src: _imports_0
          }, null, 2))
        : _createCommentVNode("", true)
    ], 2),
    _createElementVNode("div", {
      class: "label-container",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.triggerCheck()))
    }, [
      _createElementVNode("p", {
        class: _normalizeClass(['default-text', _ctx.textColorClass, _ctx.textSizeClass])
      }, _toDisplayString(_ctx.checkboxText), 3)
    ])
  ]))
}