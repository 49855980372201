
import { computed } from "@vue/reactivity";
import { Field, ErrorMessage } from "vee-validate";
import { defineComponent } from "vue";
import { validateEmail, validatePassword } from "@/utils/validations";
import * as loginCOnstants from "@/constants/LoginConstants";
import tooltip from "@/components/Tooltip.vue";

export default defineComponent({
  components: { Field, ErrorMessage, tooltip },
  name: "inputField",
  emits: [
    "input",
    "passwordReveal",
    "emitEmail",
    "emailIsNotValid",
    "emptyFieldEmail",
    "emptyFieldPassword",
    "notLaCEmail",
  ],
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    showPasswordIcon: {
      required: false,
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      default: "",
    },
    imgSrc: {
      required: false,
      type: String,
    },
    allowPasswordRules: {
      type: Boolean,
      required: false,
      default: false,
    },
    showHint: {
      type: Boolean,
      required: false,
      default: false,
    },
    autocomplete: {
      type: String,
      required: false,
      default: "on",
    },
    value: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      helperText: this.$t("component-input-field.valid-password-helper"),
      showPasswordTooltip: this.$t("tooltip.show-password"),
      hidePasswordTooltip: this.$t("tooltip.hide-password"),
      isClicked: false,
      emailValidation: false,
      passwordTooltipPositionFromTop: "small",
    };
  },
  setup(props, context) {
    const name = computed(() => {
      return props.placeholder.toLowerCase();
    });

    const insertedText = computed(() => {
      return props.value;
    });

    const onInput = (event: Event) => {
      const el = event.target as HTMLInputElement;
      context.emit("input", el.value);
    };

    return { name, onInput, insertedText };
  },
  methods: {
    showPassword() {
      this.$emit("passwordReveal");
    },
    onClick() {
      this.isClicked = true;
    },
    emitEmailvalidity() {
      this.$emit("emitEmail", this.emailValidation);
    },
    validationRules(value: string) {
      const lacEmail = loginCOnstants.LAC_EMAIL;
      if (this.isClicked) {
        this.helperText = "";
      }
      if (this.required && this.isClicked) {
        if (!value) {
          if (this.type === "email") {
            this.$emit("emptyFieldEmail", true);
          } else if (this.type === "password" || this.type === "text") {
            this.$emit("emptyFieldPassword", true);
          }
          return this.$t("component-input-field.field-required");
        }
        this.$emit("emptyFieldEmail", false);
        this.$emit("emptyFieldPassword", false);
      }
      if (this.type.toLowerCase() === "name") {
        return true;
      }
      if (this.type.toLowerCase() === "email" && this.isClicked) {
        if (validateEmail(value)) {
          if (!value.includes(lacEmail)) {
            this.$emit("notLaCEmail", true);
            return this.$t("component-input-field.not-valid-lac");
          } else {
            this.$emit("notLaCEmail", false);
            this.emailValidation = true;
            this.emitEmailvalidity();
            this.$emit("emailIsNotValid", false);
            this.$emit("emptyFieldEmail", false);
            return validateEmail(value);
          }
        }
        this.emailValidation = false;
        this.emitEmailvalidity();
        return this.$t("component-input-field.valid-email");
      }
      if (this.allowPasswordRules && this.isClicked) {
        if (
          this.type.toLowerCase() === "text" ||
          this.type.toLowerCase() === "password"
        ) {
          const validationResult = validatePassword(
            value,
            this.allowPasswordRules
          );
          if (validationResult === "short") {
            return this.$t("component-input-field.valid-password-length");
          }
          if (validationResult === "no-digits") {
            return this.$t("component-input-field.valid-password-digits");
          }
          if (validationResult === "no-letters") {
            return this.$t("component-input-field.valid-password-letters");
          } else {
            return "";
          }
        }
      } else {
        return "";
      }
    },
  },
});
