
import taButton from "@/components/Button.vue";
import { defineComponent } from "vue";
import store from "@/store/store";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import { AsanaTask } from "@/models/time_assistant/asana_task";
import TimeSelector from "@/components/TimeSelector.vue";
import emitter from "@/utils/emitter";
import tooltip from "@/components/Tooltip.vue";
import * as timeEntryConstants from "@/constants/TimeEntryConstants";

export default defineComponent({
  components: {
    taButton,
    Datepicker,
    TimeSelector,
    tooltip,
  },
  data() {
    return {
      selected_project_name: store.getters.getProject.name,
      task: store.getters.getSelectedTaskState as AsanaTask,
      taskValue: store.getters.getaskValue,
      techSuggestions: store.getters.getTechnologyArray,
      role: store.getters.getRoleName,
      customValue: store.getters.getCustonTaskValue,
      actionArray: store.getters.getActions,
      selectedDate: "",
      date: new Date(store.getters.getDateValue),
      storedHours: store.getters.getHour,
      storedMinutes: store.getters.getMinutes,
      minmalAllowedValue: new Date(),
      tooltipPositionFromTop: "small",
      editIconTitleTooltip: this.$t("tooltip.editIconTitleTooltip"),
      editIconTagsTooltip: this.$t("tooltip.editIconTagsTooltip"),
      editModeActive: store.getters.getEnableEditing,
      showNoSelectedTechText: false,
    };
  },
  setup() {
    const format = (date: Date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };
    return {
      format,
    };
  },
  methods: {
    async checkTime() {
      const storedDate = store.getters.getDateValue;
      const storedHour = store.getters.getHour;
      const storedMinutes = store.getters.getMinutes;
      const checkIfSelected = (storedHour || storedMinutes) && storedDate;
      this.$emit("dateAndTimeSelected", !!checkIfSelected.length);
    },
    async clearDate() {
      this.selectedDate = this.converteDate(new Date());
      await store.dispatch("updateDateValue", this.selectedDate);
    },
    async getDateValue(dateValue: Date) {
      this.selectedDate = this.converteDate(dateValue);
      await store.dispatch("updateDateValue", this.selectedDate);
    },
    converteDate(date: Date) {
      return date.toDateString();
    },
    buttonText(role: string) {
      return this.$t(`profiles.${role}`);
    },
    editSelectedTask() {
      emitter.emit(
        "selectedCircleWithEdit",
        timeEntryConstants.TASK_FLOW_CIRCLE_INDEX
      );
    },
    editTaskTags() {
      emitter.emit(
        "selectedCircleWithEdit",
        timeEntryConstants.TAG_FLOW_CIRCLE_INDEX
      );
    },
  },
  async mounted() {
    //Check is the view is in edit mode and if so apply information from editable time entry one time
    if (this.editModeActive && store.getters.getShowCompletionInfo) {
      this.role = store.getters.getProfileName;
      this.techSuggestions = store.getters.getEditableTechnologies;
      await store.dispatch("updateRoleName", this.role);
      await store.dispatch("updateShowCompletionInfo", false);
    } else {
      this.role = store.getters.getRoleName;
      this.techSuggestions = store.getters.getTechnologyArray;
    }
    const flowCircleArray = store.getters.getFlowCircleChecks;
    if (
      !flowCircleArray.includes(timeEntryConstants.COMPLETION_FLOW_CIRCLE_INDEX)
    ) {
      //Push index value of the circle to array to check if circle should be marked
      flowCircleArray.push(timeEntryConstants.COMPLETION_FLOW_CIRCLE_INDEX);
    }
    await store.dispatch("updateFlowCircleChecks", flowCircleArray);
    let minDateValue = new Date();
    minDateValue.setDate(minDateValue.getDate() - 40);
    this.minmalAllowedValue = minDateValue;

    if (this.techSuggestions.length === 0) {
      this.showNoSelectedTechText = !this.techSuggestions.length;
    }
  },
});
