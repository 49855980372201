import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-192187a9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "task-selection-project-text h5"
}
const _hoisted_2 = { class: "project-selection" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  class: "solid"
}
const _hoisted_5 = {
  ref: "taskSelection",
  class: "task-selection"
}
const _hoisted_6 = {
  key: 0,
  class: "small-text-salmon task-selection-edit-text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_search_bar = _resolveComponent("search-bar")!
  const _component_task_item = _resolveComponent("task-item")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: _normalizeClass({ 'disabled-selection': _ctx.disableSelection })
    }, [
      (_ctx.getStoreProjects.length !== 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("p", null, _toDisplayString(_ctx.$t("component-task-selection.select-project")), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getStoreProjects, (project, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("div", {
              class: _normalizeClass(["project-button default-text", [
              _ctx.selectedProject(index, project),
              { 'hover-scroll': project.name === _ctx.nameOfScrollableText },
            ]]),
              onClick: ($event: any) => (_ctx.toggleProject(index, project)),
              onMouseenter: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.checkForWidth && _ctx.checkForWidth(...args)))
            }, [
              _createElementVNode("span", null, _toDisplayString(project.name), 1)
            ], 42, _hoisted_3)
          ]))
        }), 128)),
        (_ctx.displayOther)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["project-button default-text", { selected: _ctx.selected === _ctx.otherProject }]),
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.clearProject()))
            }, _toDisplayString(this.otherText), 3))
          : _createCommentVNode("", true)
      ]),
      (_ctx.getStoreProjects.length !== 0)
        ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
        : _createCommentVNode("", true),
      _createVNode(_component_search_bar, {
        class: "search-bar",
        onSearchItem: _ctx.searchItem
      }, null, 8, ["onSearchItem"]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getStoreTasks, (task, index) => {
          return (_openBlock(), _createBlock(_component_task_item, {
            class: "task-item-padding",
            onClick: ($event: any) => (_ctx.selectTask(task)),
            ref: `taskItem${index}`,
            key: index,
            task: task,
            selectedCheck: _ctx.taskGid
          }, null, 8, ["onClick", "task", "selectedCheck"]))
        }), 128))
      ], 512)
    ], 2),
    (_ctx.disableSelection)
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.editSwitchingText), 1))
      : _createCommentVNode("", true)
  ]))
}