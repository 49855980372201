import { AsanaTask } from "@/models/time_assistant/asana_task";
import { TaObjective } from "@/models/time_assistant/ta_objectives";
import {
  TaProject,
  TransferableInterface,
} from "@/models/time_assistant/ta_project";
import store from "@/store/store";

export function localTaskFilter(localTaskArray: TaObjective[]) {
  const transformedLocalTaskArray = [] as AsanaTask[];
  localTaskArray.forEach((task) => {
    const updatedTaskArray: AsanaTask = {
      task_gid: task.ID,
      task_name: task.name,
      due_on: task.due_on,
      completed: false,
      project_gid: task.project_gid,
      custom_fields: {
        why: task.why,
        estimated_time_hours: task.estimated_time_hours,
      },
      color: task.objective_color,
      firebase_id: task.ID,
      totalTimeSpentMin: task.totalTimeSpentMin,
    };
    return transformedLocalTaskArray.push(updatedTaskArray);
  });
  store.dispatch("updateTaObjectiveArrayState", transformedLocalTaskArray);
  return transformedLocalTaskArray;
}

export function localProjectFilter(localProjectArray: TaProject[]) {
  const transformedLocalProjectArray = [] as TransferableInterface[];
  localProjectArray.forEach((project) => {
    const updatedProjectArray: TransferableInterface = {
      gid: project.ID,
      color: project.project_color,
      name: project.project_name,
      source: project.source,
      user_uid: project.user_uid,
      project_description: project.project_description,
    };
    return transformedLocalProjectArray.push(updatedProjectArray);
  });
  store.dispatch("updateTaProjectArrayState", transformedLocalProjectArray);
  return transformedLocalProjectArray;
}
