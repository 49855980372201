import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["task-item-header", { 'task-item-header-remove-margin': _ctx.infoDate }])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass({ 'span-background': !_ctx.infoDate })
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["date-span medium-text-bold", { 'info-date-span': _ctx.infoDate }])
      }, _toDisplayString(_ctx.checkDate), 3)
    ], 2)
  ], 2))
}