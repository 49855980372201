import * as timeEntryConstants from "@/constants/TimeEntryConstants";
import { format } from "date-fns";
import { getEntriesByMonthAndYear } from "@/service/TimeEntryService";
import store from "@/store/store";

export async function downloadExcelFile(
  monthValue: any,
  userUid: string,
  userEmail: string
) {
  const monthValueObject = Object.assign({}, monthValue);
  const day = 2; //Passing second day, reason related new Date() change to string in backend
  const month = monthValueObject.month + 1;
  const year = monthValueObject.year;
  const newDate = new Date(`${year}/${month}/${day}`);
  const selectedDate = newDate.toString();

  const fileNameDate = format(new Date(selectedDate), "MMMM yyyy");
  const downloadableData = await getEntriesByMonthAndYear(
    userUid,
    selectedDate,
    timeEntryConstants.DOWNLOAD_ENTRIES,
    userEmail
  );

  const link = document.createElement("a");

  const blob = new Blob([new Uint8Array(downloadableData.data).buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const blobUrl = URL.createObjectURL(blob);
  link.href = blobUrl;
  link.setAttribute(
    "download",
    `${store.getters.getUser.display_name} ${fileNameDate}.xlsx`
  );
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
