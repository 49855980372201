
import { defineComponent } from "vue";
import inputField from "@/components/InputField.vue";
import taButton from "@/components/Button.vue";
import { updateUserPassword } from "@/service/ForgottenPasswordService";
import router from "@/router";
import { Form } from "vee-validate";
import emitter from "@/utils/emitter";

export default defineComponent({
  emits: ["errorUpdatingPassword", "passwordUpdateSuccess"],
  components: { taButton, inputField, Form },
  data() {
    return {
      passwordType: "password",
      errorMessage: "",
      password: "",
      confirmedPassword: "",
      buttonBackgroundColor: "orange",
      inputFieldNewPasswordPlaceholder: this.$t(
        "component-reset-password.new-password-placeholder"
      ),
      inputFieldReEnterNewPasswordPlaceholder: this.$t(
        "component-reset-password.re-enter-password-placeholder"
      ),
      confirmNewPasswordButtonText: this.$t(
        "component-reset-password.confirm-new-password-button-text"
      ),
      errorUpdatingPassowrdText: this.$t("snackbar.update-password-error"),
      updatePasswordSuccessText: this.$t("snackbar.update-password-success"),
      enableButtonLoader: false,
    };
  },
  methods: {
    receivePassword(data: string) {
      this.password = data;
      this.errorMessage = "";
    },
    receiveConfirmedPassword(data: string) {
      this.confirmedPassword = data;
      this.errorMessage = "";
    },
    async updatePassword() {
      if (!this.enableButtonLoader) {
        if (this.password === this.confirmedPassword) {
          this.enableButtonLoader = true;
          const result = await updateUserPassword(
            this.password,
            String(this.$route.query.id)
          );
          this.enableButtonLoader = false;
          if (result === true) {
            emitter.emit("passwordUpdated", this.updatePasswordSuccessText);

            router.push("/login");
          } else {
            this.$emit("errorUpdatingPassword", this.errorUpdatingPassowrdText);
          }
        } else {
          this.errorMessage = this.$t(
            "component-reset-password.compare-passowrd-error-message"
          );
        }
      }
    },
    showPassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
  },
});
