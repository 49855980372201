
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      required: true,
      type: String,
    },
    imageSrc: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
});
