
import { computed, defineComponent } from "vue";

export default defineComponent({
  emits: ["toggleCheckBox"],
  props: {
    checkboxText: {
      required: true,
      type: String,
    },
    checkActive: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      required: true,
    },
  },
  methods: {
    triggerCheck() {
      this.$emit("toggleCheckBox");
    },
  },
  setup(props) {
    const textColorClass = computed(() => {
      return "text-" + props.color;
    });

    const textSizeClass = computed(() => {
      return props.size + "-text";
    });

    const checkboxColorClass = computed(() => {
      if (props.checkActive) {
        return "check-box-" + props.color + "-selected";
      }
      return "check-box-" + props.color;
    });

    const checkboxSizeClass = computed(() => {
      return "check-box-" + props.size;
    });

    const imgClass = computed(() => {
      return "img-" + props.color;
    });

    return {
      textColorClass,
      textSizeClass,
      checkboxColorClass,
      checkboxSizeClass,
      imgClass,
    };
  },
});
