import { AbstractApiResource } from "@/infrastructure/http/AbstractApiResource";
import {
  TimeEntry,
  UpdateableTimeEntry,
  UpdateableTimeEntryDescription,
} from "@/models/time_assistant/time_entry";
import store from "@/store/store";

class TimeAssistantApiResource extends AbstractApiResource {
  public constructor() {
    super(process.env.VUE_APP_TA_APPLICATION + "");
  }

  public async getMyTasksFromWorkspace(
    workspace_gid: number,
    firebaseuid: string
  ) {
    return await this.http
      .get("asanaTasks", {
        params: {
          workspace_gid,
          firebaseuid,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async getSingleTaskInfo(task_gid: number, firebaseuid: string) {
    return await this.http
      .get("asanaTasks", {
        params: {
          task_gid,
          firebaseuid,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async getMyProjectsFromWorkspace(
    workspace_gid: number,
    firebaseuid: string
  ) {
    return await this.http
      .get("asanaProjects", {
        params: {
          workspace_gid,
          firebaseuid,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async getUserProfileByProject(user_uid: string, project_name: string) {
    return await this.http
      .get("userProfileByProject", {
        params: {
          user_uid,
          project_name,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async setUserDefaultProfileByProject(
    user_uid: string,
    project_id: string,
    role: string
  ) {
    return await this.http
      .post("projectProfile", {
        projectProfile: {
          user_uid: user_uid,
          project_id: project_id,
          role_id: role,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async removeUserDefaultProfileByProject(
    user_uid: string,
    project_id: string
  ) {
    return await this.http
      .delete("projectProfile", {
        params: {
          user_uid,
          project_id,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async getTimeEntryInformations(
    user_uid: string,
    objective_id: string,
    project_id: string
  ) {
    return await this.http
      .get("timeEntryInformations", {
        params: {
          user_uid,
          objective_id,
          project_id,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async saveTimeEntryInformations(timeEntryInformations: TimeEntry) {
    return await this.http
      .post("timeEntryInformations", {
        time_entry_informations: timeEntryInformations,
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async getTimeEntryActivities(objective_id: string) {
    return await this.http
      .get("activitesByObjective", {
        params: {
          objective_id,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async getEntriesByMonthAndYear(
    user_uid: string,
    selected_date: string,
    download_entries: string,
    user_email: string
  ) {
    return await this.http
      .get("activitesByMonthAndYear", {
        params: {
          user_uid,
          selected_date,
          download_entries,
          user_email,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async deleteTimeEntryActivities(
    ID: string,
    firebase_id: string,
    time_entry_minutes: string
  ) {
    return await this.http
      .delete("activity", {
        params: {
          ID,
          firebase_id,
          time_entry_minutes,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async updateTimeEntryActivities(
    activity: UpdateableTimeEntry,
    descriptionInformation: UpdateableTimeEntryDescription
  ) {
    return await this.http
      .put("activity", {
        activity: activity,
        descriptionInformation: descriptionInformation,
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async addProject(
    project_name: string,
    project_color: string,
    source: string,
    user_uid: string,
    project_description: string
  ) {
    return await this.http
      .post("project", {
        project: {
          project_name: project_name,
          project_color: project_color,
          source: source,
          user_uid: user_uid,
          project_description: project_description,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async updateProject(
    project_ID: string,
    project_name: string,
    project_color: string,
    source: string,
    user_uid: string,
    project_description: string
  ) {
    return await this.http
      .put("project", {
        project: {
          ID: project_ID,
          project_name: project_name,
          project_color: project_color,
          source: source,
          user_uid: user_uid,
          project_description: project_description,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async projectsByUser(user_uid: string) {
    return await this.http
      .get("projectsByUser", {
        params: {
          user_uid,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async deleteUserProject(ID: string) {
    return await this.http
      .delete("project", {
        params: {
          ID,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async addObjective(
    name: string,
    project_name: string,
    project_gid: string,
    projectId: string,
    objective_color: string,
    source: string,
    user_uid: string,
    due_on: string,
    estimated_time_hours: number,
    who: string,
    what: string,
    why: string,
    objective_description: string
  ) {
    return await this.http
      .post("objective", {
        objective: {
          name: name,
          project_name: project_name,
          project_gid: project_gid,
          projectId: projectId,
          objective_color: objective_color,
          source: source,
          user_uid: user_uid,
          due_on: due_on,
          estimated_time_hours: estimated_time_hours,
          who: who,
          what: what,
          why: why,
          objective_description: objective_description,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async objectivesByUser(user_uid: string) {
    return await this.http
      .get("objectivesByUser", {
        params: {
          user_uid,
        },
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }

  public async uploadUserExcelEntries(
    bufferFile: any,
    passedValues: Record<string, unknown>
  ) {
    return await this.http
      .post("uploadUserExcelEntries", {
        bufferFile: bufferFile,
        passedValues: passedValues,
        headers: {
          login_session: store.getters.getSessionCookie,
        },
      })
      .then(this.handleResponse.bind(this))
      .catch(this.handleErrors.bind(this));
  }
}

export const TimeAssistantApi: TimeAssistantApiResource =
  new TimeAssistantApiResource();
