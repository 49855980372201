
import { computed, defineComponent } from "vue";
import mainHeader from "@/components/MainHeader.vue";
import taskList from "@/components/TaskListView.vue";
import newTimeEntry from "@/components/TimeEntryView.vue";
import timeEntry from "@/components/TimeEntryInfoComponents/TimeEntryInfoView.vue";
import allEntries from "@/components/AllEntriesComponents/AllEntriesView.vue";
import projectCreationView from "@/components/LocalTaskComponents/ProjectCreationView.vue";
import projectManagementPage from "@/components/LocalTaskComponents/ProjectManagementView.vue";
import objectCreationView from "@/components/LocalTaskComponents/ObjectiveCreationView.vue";
import profile_view from "@/components/ProfileComponents/ProfileView.vue";
import upload_view from "@/components/TimeEntryInfoComponents/TimEntryUploadView.vue";
import { useRoute } from "vue-router";
import * as pageNames from "@/router/pageNames";

export default defineComponent({
  name: "Dashboard",
  components: { mainHeader, taskList },
  setup() {
    const computedComponent = computed(() => {
      if (useRoute().name === pageNames.DASHBOARD_PAGE) {
        return taskList;
      } else if (useRoute().name === pageNames.TIME_ENTRY_PAGE) {
        return newTimeEntry;
      } else if (useRoute().name === pageNames.TIME_ENTRY_INFO_PAGE) {
        return timeEntry;
      } else if (useRoute().name === pageNames.ALL_ENTRIES_PAGE) {
        return allEntries;
      } else if (useRoute().name === pageNames.PROJECT_CREATION_PAGE) {
        return projectCreationView;
      } else if (useRoute().name === pageNames.PROJECT_MANAGEMENT_PAGE) {
        return projectManagementPage;
      } else if (useRoute().name === pageNames.OBJECTIVE_CREATION_PAGE) {
        return objectCreationView;
      } else if (useRoute().name === pageNames.PROFILE_PAGE) {
        return profile_view;
      } else if (useRoute().name === pageNames.UPLOAD_EXCEL_PAGE) {
        return upload_view;
      } else return null;
    });
    return { computedComponent };
  },
  beforeCreate: function () {
    document.body.className = "Dashboard";
  },
});
