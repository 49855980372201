
import { defineComponent } from "vue";
import store from "@/store/store";
import { getAllUsers } from "@/service/IntegrationService";
import { UserInterface } from "@/models/sso/user";
import "clickout-event";

export default defineComponent({
  components: {},
  emits: ["selectUser"],
  data() {
    return {
      returnMeText: this.$t("component-all-entries-view.me"),
      orginalUserUid: store.getters.getUser.firebase_uid,
      originalUserName: "",
      userFullName: store.getters.getUser.display_name,
      dropdownEnabled: false,
      sortedUserArray: [] as UserInterface[],
    };
  },
  methods: {
    async toggleDropdown() {
      this.dropdownEnabled = !this.dropdownEnabled;
    },
    async selectUser(selectedUserName: string, selectedUserUid: string) {
      this.userFullName = selectedUserName;
      await store.dispatch("updateAdminSelectionUserUid", selectedUserUid);
      this.$emit("selectUser");
    },
  },
  computed: {
    allUsers() {
      const sortedUserList = this.sortedUserArray;
      return sortedUserList;
    },
  },
  async mounted() {
    this.originalUserName = store.getters.getUser.display_name;
    const allUsers = await getAllUsers();

    allUsers.forEach((task: UserInterface) => {
      //TODO: Remove this code when firebase backend has been reset.
      if (task.display_name.length > 0) {
        this.sortedUserArray.push(task);
      }
    });

    this.sortedUserArray.sort((a, b) =>
      a.display_name.localeCompare(b.display_name)
    );
  },
});
