import { TimeAssistantApi } from "@/infrastructure/http/TimeAssistantApiResource";

export async function addObjective(
  name: string,
  project_name: string,
  project_gid: string,
  projectId: string,
  objective_color: string,
  source: string,
  user_uid: string,
  due_on: string,
  estimated_time_hours: number,
  who: string,
  what: string,
  why: string,
  objective_description: string
) {
  const objectiveId = await TimeAssistantApi.addObjective(
    name,
    project_name,
    project_gid,
    projectId,
    objective_color,
    source,
    user_uid,
    due_on,
    estimated_time_hours,
    who,
    what,
    why,
    objective_description
  );
  return objectiveId.success;
}

export async function objectivesByUser(user_uid: string) {
  const allUserObjectives = await TimeAssistantApi.objectivesByUser(user_uid);
  return allUserObjectives.user_objectives;
}
