
import { defineComponent, shallowRef } from "vue";
import TaskSelection from "@/components/NewTimeEntryComponents/TaskSelection.vue";
import TagSelection from "@/components/NewTimeEntryComponents/TagSelection.vue";
import DateAndTime from "@/components/NewTimeEntryComponents/DateAndTime.vue";
import {
  saveTimeEntryInformations,
  updateTimeEntryActivities,
} from "@/service/TimeEntryService";
import Completion from "@/components/NewTimeEntryComponents/Completion.vue";
import FlowCircle from "@/components/NewTimeEntryComponents/FlowCircle.vue";
import {
  ComperableTimeEntry,
  TimeEntry,
  UpdateableTimeEntry,
  UpdateableTimeEntryDescription,
} from "@/models/time_assistant/time_entry";
import taButton from "@/components/Button.vue";
import loadingStore from "@/store/store";
import taskStore from "@/store/store";
import store from "@/store/store";
import router from "@/router";
import emitter from "@/utils/emitter";
import {
  removeUserDefaultProfileByProject,
  setUserDefaultProfileByProject,
} from "@/service/TaskService";
import { AsanaTask } from "@/models/time_assistant/asana_task";
import * as timeEntryConstants from "@/constants/TimeEntryConstants";
import TimeEntryExitPopup from "@/components/NewTimeEntryComponents/TimeEntryExitPopup.vue";
import { addToFlowCircleArray } from "@/helpers/TimeEntryHelper";
import { format } from "date-fns";

export default defineComponent({
  name: "Time Entry View",
  components: {
    TaskSelection,
    TagSelection,
    DateAndTime,
    Completion,
    taButton,
    FlowCircle,
    TimeEntryExitPopup,
  },
  data() {
    return {
      languageKeyArray: [
        "component-new-task-view.select-task",
        "component-new-task-view.entry-tags",
        "component-new-task-view.date-time",
        "component-new-task-view.completion",
      ],
      CompletionCheck: Completion,
      requireComponent: {},
      componentArray: [TaskSelection, TagSelection, DateAndTime, Completion],
      cancelText: this.$t("component-new-task-view.cancel-text"),
      nextText: this.$t("component-new-task-view.next-text"),
      saveAndContinueText: this.$t("component-new-task-view.save-and-continue"),
      proceedCheck: false,
      circleProceedCheck: 0,
      maxCircleValue: 0,
      disableNextButton: true,
      firstTime: true,
      time_entry_informations: store.getters.getTimeEntryInformation,
      areRequiredFiedlsFilled: false,
      oldTimeEntry: {} as ComperableTimeEntry,
      currentTimeEntry: {} as ComperableTimeEntry,
      valueArray: [] as string[],
      editEnabled: store.getters.getEnableEditing,
      currentTagInformation: {},
      entryStartingMinutes: 0,
    };
  },
  created() {
    emitter.on("selectedCircleWithEdit", (index: any) =>
      this.showComponent(index)
    );
  },
  computed: {
    showWarning() {
      return store.getters.getShowCancelPopup;
    },
  },

  methods: {
    async routeToDashboard() {
      if (store.getters.getEnableEditing) {
        await store.dispatch("updateEnableEditing", false);
        if (store.getters.getEnableAllEntryViewEditing) {
          await store.dispatch("updateShowSideBar", true);
          await store.dispatch("updateEnableAllEntryViewEditing", false);
          await store.dispatch("updateShowCancelPopup", false);
          router.push("/dashboard/allEntries");
        } else {
          await store.dispatch("updateShowCancelPopup", false);
          await store.dispatch("updateAcquiredOriginalEntryCheck", false);
          await store.dispatch("updateOrginalTimeEntry", {});
          router.push("/dashboard/timeEntries");
        }
      } else {
        this.maxCircleValue = 0;
        await store.dispatch("updateEnableEditing", false);
        await store.dispatch("updateOriginalRoleID", "");
        if (store.getters.getFromEntryViewState) {
          await store.dispatch("updateFromEntryViewState", false);
          store.commit("softReset");
          await store.dispatch("updateShowSideBar", true);
          router.push("/dashboard/timeEntries");
        } else {
          store.commit("resetState");
          router.push("/dashboard/tasks");
        }
      }
    },
    async closeWarningPopup() {
      await store.dispatch("updateShowCancelPopup", false);
    },
    async leavePage() {
      this.valueArray.push(
        store.getters.getaskValue,
        store.getters.getCustonTaskValue
      );
      let currentDateValue;
      let checkIfSameRole;
      if (store.getters.getEnableEditing) {
        currentDateValue = format(
          new Date(store.getters.getDateValue),
          "MMMM d"
        );
      } else {
        currentDateValue = store.getters.getDateValue;
      }

      const currentTimeEntryObjcet: ComperableTimeEntry = {
        project: store.getters.getProject,
        task: store.getters.getTask,
        role: store.getters.getRoleID,
        actions: store.getters.getActions,
        value: this.valueArray,
        technologies: store.getters.getTechnologyArray,
        user_uid: store.getters.getUser.firebase_uid,
        hour: store.getters.getHour,
        minutes: store.getters.getMinutes,
        createdWith: "WEB_APP",
        date: currentDateValue,
      };
      this.currentTimeEntry = currentTimeEntryObjcet;
      this.oldTimeEntry = store.getters.getOrginalTimeEntry;

      if (store.getters.getEnableEditing) {
        if (
          JSON.stringify(this.currentTimeEntry) ===
          JSON.stringify(this.oldTimeEntry)
        ) {
          await store.dispatch("updateEnableEditing", false);
          await store.dispatch("updateAcquiredOriginalEntryCheck", false);
          if (store.getters.getEnableAllEntryViewEditing) {
            await store.dispatch("updateShowSideBar", true);
            await store.dispatch("updateEnableAllEntryViewEditing", false);
            await store.dispatch("updateShowCancelPopup", false);
            router.push("/dashboard/allEntries");
          } else {
            await store.dispatch("updateShowSideBar", true);
            router.push("/dashboard/timeEntries");
          }
        } else {
          await store.dispatch("updateShowCancelPopup", true);
        }
      } else {
        if (store.getters.getRoutedFromTasklist) {
          if (!store.getters.getRoleID) {
            checkIfSameRole = false;
          } else {
            checkIfSameRole =
              store.getters.getRoleID !== store.getters.getOriginalRoleID;
          }

          if (
            checkIfSameRole ||
            store.getters.getDefaultCheckHasBeenTriggered ||
            store.getters.getActions.length != 0 ||
            this.valueArray[0].length != 0 ||
            this.valueArray[1].length != 0 ||
            store.getters.getTechnologyArray.length != 0
          ) {
            await store.dispatch("updateShowCancelPopup", true);
          } else {
            this.routeToDashboard();
          }
        } else {
          if (this.maxCircleValue != 0) {
            await store.dispatch("updateShowCancelPopup", true);
          } else {
            this.routeToDashboard();
          }
        }
      }
      this.valueArray = [];
    },

    async showComponent(index: number) {
      this.circleProceedCheck = index;
      if (this.isCompletionPage()) {
        this.areRequiredFiedlsFilled = true;
        this.nextText = this.$t("component-new-task-view.save-text");
      } else {
        this.nextText = this.$t("component-new-task-view.next-text");
      }
      this.requireComponent = this.componentArray[index];
    },
    async saveEntry() {
      loadingStore.dispatch("changeLoadingState", true);

      //adding values to an array
      this.valueArray.push(
        store.getters.getaskValue,
        store.getters.getCustonTaskValue
      );

      //creating a time entry to save in the database
      const timeEntry: TimeEntry = {
        project: store.getters.getProject,
        task: store.getters.getTask,
        role: store.getters.getRoleID,
        actions: store.getters.getActions,
        value: this.valueArray,
        technologies: store.getters.getTechnologyArray,
        newTechnologies: store.getters.getNewTechnologies,
        user_uid: store.getters.getUser.firebase_uid,
        hour: store.getters.getHour,
        minutes: store.getters.getMinutes,
        createdWith: "WEB_APP",
        date: new Date(store.getters.getDateValue),
      };

      if (store.getters.getEnableEditing) {
        const descriptionId =
          store.getters.getEditableID.concat("_description");
        const spentTime = store.getters.getHour * 60 + store.getters.getMinutes;
        const editedInfo: UpdateableTimeEntry = {
          ID: store.getters.getEditableID,
          description_id: descriptionId,
          objective_id: store.getters.getTask.firebase_id,
          user_uid: store.getters.getUser.firebase_uid,
          source: "ASANA",
          spent_time_min: spentTime,
          createdWith: "WEB_APP",
          updatedAt: store.getters.getDateValue,
          createdAt: store.getters.getDateValue,
        };
        const descriptionInformation: UpdateableTimeEntryDescription = {
          ID: descriptionId,
          how: store.getters.getTechnologyArray,
          what: store.getters.getActions,
          who: store.getters.getRoleID,
          why: this.valueArray,
        };
        await updateTimeEntryActivities(editedInfo, descriptionInformation);
      } else {
        await saveTimeEntryInformations(timeEntry);
      }
      await store.dispatch("updateAcquiredOriginalEntryCheck", false);
      const AsanaTaskArray = taskStore.getters.getTaskState as AsanaTask[];
      const TaskArray = taskStore.getters
        .getTaObjectiveArrayState as AsanaTask[];
      this.mapTasks(AsanaTaskArray, timeEntry, "updateTaskState");
      this.mapTasks(TaskArray, timeEntry, "updateTaObjectiveArrayState");

      if (store.getters.getRemoveDefaultRole) {
        let projectID: string;
        if (store.getters.getProject.firebase_id) {
          projectID = store.getters.getProject.firebase_id;
        } else {
          //Local project ID is same as their gid
          projectID = store.getters.getProject.gid;
        }
        await removeUserDefaultProfileByProject(
          store.getters.getUser.firebase_uid,
          projectID
        );
      }
      if (
        store.getters.getAddDefaultRole &&
        Object.keys(store.getters.getProject).length != 0
      ) {
        let projectID: string;
        if (store.getters.getProject.firebase_id) {
          projectID = store.getters.getProject.firebase_id;
        } else {
          //Local project ID is same as their gid
          projectID = store.getters.getProject.gid;
        }
        await setUserDefaultProfileByProject(
          store.getters.getUser.firebase_uid,
          projectID,
          store.getters.getRoleID
        );
      }
    },
    mapTasks(
      taskArray: AsanaTask[],
      timeEntry: TimeEntry,
      storeActions: string
    ) {
      taskArray.map((task: AsanaTask) => {
        if (task === timeEntry.task) {
          if (task.totalTimeSpentMin) {
            let difference = 0;
            const editedEntryTime =
              store.getters.getHour * 60 + store.getters.getMinutes;
            if (this.entryStartingMinutes < editedEntryTime) {
              difference = editedEntryTime - this.entryStartingMinutes;
              task.totalTimeSpentMin += difference;
            }
            if (this.entryStartingMinutes > editedEntryTime) {
              difference = this.entryStartingMinutes - editedEntryTime;
              task.totalTimeSpentMin -= difference;
            }
          } else {
            task.totalTimeSpentMin = timeEntry.hour * 60 + timeEntry.minutes;
          }
        }
      });
      store.dispatch(storeActions, taskArray);
    },

    async next() {
      if (this.isCompletionPage()) {
        await this.saveEntry();
        if (store.getters.getEnableEditing) {
          if (store.getters.getEnableAllEntryViewEditing) {
            await store.dispatch("updateEnableAllEntryViewEditing", false);
            await store.dispatch("updateEnableEditing", false);
            await store.dispatch("updateShowSideBar", true);
            router.push("/dashboard/allEntries");
          } else {
            await store.dispatch("updateEnableEditing", false);
            await store.dispatch("updateShowSideBar", true);
            router.push("/dashboard/timeEntries");
          }
        } else {
          store.commit("resetState");
          router.push("/dashboard/tasks");
        }
      } else {
        if (this.circleProceedCheck <= this.maxCircleValue) {
          (this.$refs.flowCircle as any).nextCircle();
          this.proceedCheck = false;
        }
      }
    },
    async saveAndContinue() {
      if (!this.disableNextButton) {
        await this.saveEntry();
        store.commit("softReset");
        this.proceedCheck = false;
        this.circleProceedCheck = 0;
        this.maxCircleValue = 1;
        (this.$refs.flowCircle as any).toggleCircle(0);
        store.dispatch("changeLoadingState", false);
      }
    },
    async canProceedNext(proceed: boolean) {
      this.proceedCheck = proceed;
      this.disableNextButton = !proceed;
      if (proceed) {
        if (store.getters.getEnableEditing && this.firstTime) {
          this.requireComponent = Completion;
          addToFlowCircleArray(timeEntryConstants.TAG_FLOW_CIRCLE_INDEX);
          addToFlowCircleArray(timeEntryConstants.DATE_FLOW_CIRCLE_INDEX);
          for (
            var i = timeEntryConstants.TASK_FLOW_CIRCLE_INDEX;
            i < timeEntryConstants.COMPLETION_FLOW_CIRCLE_INDEX;
            i++
          ) {
            (this.$refs.flowCircle as any).nextCircle();
          }
          this.maxCircleValue = timeEntryConstants.COMPLETION_FLOW_CIRCLE_INDEX;
          this.proceedCheck = false;
          this.disableNextButton = false;
          this.firstTime = false;
        } else {
          if (
            this.maxCircleValue === timeEntryConstants.TASK_FLOW_CIRCLE_INDEX &&
            this.firstTime
            //0 represents first step of time entry creaton
          ) {
            this.requireComponent = TagSelection;
            (this.$refs.flowCircle as any).nextCircle();
            this.maxCircleValue = 1;
            this.proceedCheck = false;
            this.disableNextButton = false;
            this.firstTime = false;
          } else if (store.getters.getProceedToTagView) {
            this.requireComponent = TagSelection;
            (this.$refs.flowCircle as any).nextCircle();
            store.dispatch("updateProceedToTagView", false);
          }
        }
        const new_value = this.circleProceedCheck + 1;
        if (new_value > this.maxCircleValue) {
          this.maxCircleValue = new_value;
        }
      } else {
        this.maxCircleValue = this.circleProceedCheck;
      }
      if (this.areRequiredFiedlsFilled) {
        this.maxCircleValue = timeEntryConstants.COMPLETION_FLOW_CIRCLE_INDEX;
      }
    },
    isCompletionPage() {
      return (
        this.circleProceedCheck ===
        timeEntryConstants.COMPLETION_FLOW_CIRCLE_INDEX
      );
    },
  },
  beforeMount() {
    this.requireComponent = shallowRef(TaskSelection);
  },
  async mounted() {
    store.dispatch("updateShowSideBar", false);
    if (taskStore.getters.getProceedWithTask) {
      this.maxCircleValue += 1;
      taskStore.dispatch("updateProceedWithTask", false);
    }
    if (store.getters.getEnableEditing) {
      if (
        store.getters.getEnableEditing &&
        !store.getters.getAcquiredOriginalEntryCheck
      ) {
        store.dispatch("updateAcquiredOriginalEntryCheck", true);
        let valueArray: string[] = [];
        valueArray.push(
          store.getters.getaskValue || "",
          store.getters.getCustonTaskValue || ""
        );
        const sortedActions = store.getters.getActions.sort();
        const timeEntry: ComperableTimeEntry = {
          project: store.getters.getProject,
          task: store.getters.getTask,
          role: store.getters.getRoleID,
          actions: sortedActions,
          value: valueArray,
          technologies: store.getters.getEditableTechnologies,
          user_uid: store.getters.getUser.firebase_uid,
          hour: store.getters.getHour,
          minutes: store.getters.getMinutes,
          createdWith: "WEB_APP",
          date: format(new Date(await store.getters.getDateValue), "MMMM d"),
        };
        this.oldTimeEntry = timeEntry;
        this.entryStartingMinutes =
          store.getters.getHour * 60 + store.getters.getMinutes;
        await store.dispatch("updateOrginalTimeEntry", this.oldTimeEntry);
      }
    }
  },
});
