export function validateEmail(value: string): boolean {
  // if the field is not a valid email
  const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;

  return regex.test(value);
}
export function validatePassword(
  value: string,
  allowPasswordRules: boolean
): string {
  // if the field is not a valid password
  if (allowPasswordRules) {
    const letters_regex = /^.*[a-zA-Z].*/;
    const digit_regex = /^.*[0-9].*/;
    if (value.length < 8) {
      return "short";
    }
    if (!letters_regex.test(value)) {
      return "no-letters";
    }
    if (!digit_regex.test(value)) {
      return "no-digits";
    }
    return "well-formed";
  } else return "";
}
