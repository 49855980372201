
import { defineComponent } from "vue";
import inputField from "@/components/InputField.vue";
import taButton from "@/components/Button.vue";
import { sendEmailResetUserPassword } from "@/service/ForgottenPasswordService";
import loadingStore from "@/store/store";

export default defineComponent({
  emits: ["successEmail", "failedEmail"],
  components: { taButton, inputField },
  data() {
    return {
      errorMessage: "",
      email: "",
      backgroundColor: "orange",
      mainTextResetEmail: this.$t(
        "component-input-email-reset-password.main-text-reset-email"
      ),
      confirmEmailButtonText: this.$t(
        "component-input-email-reset-password.confirm-email-button"
      ),
      cancelButtonText: this.$t(
        "component-input-email-reset-password.cancel-button"
      ),
      inputFieldEmailPlaceholder: this.$t(
        "input-field.input-field-email-placeholder"
      ),
      emailSentText: this.$t("snackbar.email-sent"),
      emailNotSentText: this.$t("snackbar.send-email-error"),
      emptyField: true,
      received: false,
      emailValid: false,
      enableButtonLoader: false,
    };
  },
  methods: {
    receive(data: string) {
      this.email = data;
      this.errorMessage = "";
    },
    async sendEmail() {
      if (!this.enableButtonLoader) {
        if (!this.emptyField && this.emailValid) {
          this.enableButtonLoader = true;
          const result = await sendEmailResetUserPassword(this.email);
          if (result) {
            this.$emit("successEmail", this.emailSentText);
            this.enableButtonLoader = false;
          } else {
            this.$emit("failedEmail", this.emailNotSentText);
            this.enableButtonLoader = false;
          }
        } else {
          this.enableButtonLoader = false;
          this.showErrorMessage(this.$t("component-input-field.valid-email"));
        }
      }
    },
    isInputFieldEmpty(value: boolean) {
      this.received = true;
      this.emptyField = value;
    },
    showErrorMessage(message: string) {
      this.errorMessage = message;
      if (this.received) {
        this.errorMessage = "";
      }
    },
    isEmailValid(data: boolean) {
      this.emailValid = data;
    },
  },
  async mounted() {
    loadingStore.dispatch("changeLoadingState", false);
  },
});
