
import { computed, defineComponent } from "vue";
import taButton from "@/components/Button.vue";
import { ProfileInformations } from "@/models/time_assistant/time_entry_information";

export default defineComponent({
  components: {
    taButton,
  },
  props: {
    profile: {
      required: true,
      type: Object as () => ProfileInformations,
    },
    selectedProfile: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    const buttonClasses = computed(() => {
      if (props.selectedProfile !== props.profile.ID) {
        return `button-${props.profile.profile_name}-unselected button-${props.profile.profile_name}`;
      } else {
        return `button-${props.profile.profile_name}-selected button-${props.profile.profile_name}`;
      }
    });

    return { buttonClasses };
  },
  methods: {
    profilesButtonText(profileMacro: string) {
      return this.$t(`profiles.${profileMacro}`);
    },
    selectProfile(selectedProfile: string) {
      this.$emit("newProfile", selectedProfile);
    },
  },
});
