
import { defineComponent } from "vue";
import taButton from "@/components/Button.vue";
import store from "@/store/store";

export default defineComponent({
  components: {
    taButton,
  },
  emits: ["returnToDashboard", "closePopup"],
  data() {
    return {
      warningText: this.$t(
        "component-time-entry-exit-popup.irreversible-change"
      ),
      leaveText: this.$t("component-time-entry-exit-popup.return-to-list"),
      leaveToEntryList: this.$t(
        "component-time-entry-exit-popup.return-to-entry-list"
      ),
      stayText: this.$t("component-time-entry-exit-popup.continue-edit"),
      editEnabled: store.getters.getEnableEditing,
    };
  },
  methods: {
    continueToDashboard() {
      this.$emit("returnToDashboard");
    },
    closePopup() {
      this.$emit("closePopup");
    },
  },
  mounted() {
    if (this.editEnabled) {
      this.leaveText = this.leaveToEntryList;
    }
  },
});
