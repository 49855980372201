<template>
  <div :class="['tooltip-container', tooltipContainerClass]">
    <slot />
    <div v-if="showDiv" :class="['tooltip', tooltipClass]">
      <span class="text medium-text">{{ text }}</span>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      required: true,
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
    positionFromTop: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const showDiv = computed(() => {
      return props.showTooltip;
    });

    const tooltipContainerClass = computed(() => {
      return `tooltip-container-${props.position}`;
    });

    const tooltipClass = computed(() => {
      return `tooltip-${props.positionFromTop}`;
    });

    return { showDiv, tooltipClass, tooltipContainerClass };
  },
});
</script>

<style lang="scss">
@import "~@/assets/variables.scss";
@import "sass-rem";

$tooltip-padding: rem(4px 12px);
$tooltip-border-radius: rem(4px);
$tooltip-margin-top-small: rem(60px);
$tooltip-margin-top-medium: rem(80px);
$tooltip-margin-top-large: rem(100px);

.tooltip-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tooltip-container:hover .tooltip {
  opacity: 1;
}

.tooltip {
  pointer-events: none;
  color: white;
  text-align: center;
  padding: $tooltip-padding;

  width: max-content;
  opacity: 0;
  transition: opacity 1s;

  position: absolute;
  z-index: 1;
  border-radius: $tooltip-border-radius;
  background: rgba(51, 51, 51, 0.85);

  &-small {
    margin-top: $tooltip-margin-top-small;
  }

  &-medium {
    margin-top: $tooltip-margin-top-medium;
  }

  &-large {
    margin-top: $tooltip-margin-top-large;
  }
}
</style>
