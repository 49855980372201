import { TimeAssistantApi } from "@/infrastructure/http/TimeAssistantApiResource";

export async function addProject(
  project_name: string,
  project_color: string,
  source: string,
  user_uid: string,
  project_description: string
) {
  const projectId = await TimeAssistantApi.addProject(
    project_name,
    project_color,
    source,
    user_uid,
    project_description
  );
  return projectId.success;
}
export async function updateProject(
  project_ID: string,
  project_name: string,
  project_color: string,
  source: string,
  user_uid: string,
  project_description: string
) {
  await TimeAssistantApi.updateProject(
    project_ID,
    project_name,
    project_color,
    source,
    user_uid,
    project_description
  );
}

export async function projectsByUser(user_uid: string) {
  const allUsersProjects = await TimeAssistantApi.projectsByUser(user_uid);
  return allUsersProjects.user_projects;
}

export async function deleteUserProject(ID: string) {
  await TimeAssistantApi.deleteUserProject(ID);
}
