export const TECHNICAL_EXPERT = "TECHNICAL_EXPERT";
export const DESIGN_THINKER = "DESIGN_THINKER";
export const PROJECT_OWNER = "PROJECT_OWNER";
export const LEADER_COACH = "LEADER_COACH";
export const BUSINESS_DEVELOPER = "BUSINESS_DEVELOPER";

export const MAX_SUGGESTIONS = 6;
export const USER_MAX_SUGGESTIONS = 3;

//time constants
export const HOUR_OPTIONS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
export const MINUTES_OPTIONS = [0, 15, 30, 45];

//Flow Circle Constants
export const TASK_FLOW_CIRCLE_INDEX = 0;
export const TAG_FLOW_CIRCLE_INDEX = 1;
export const DATE_FLOW_CIRCLE_INDEX = 2;
export const COMPLETION_FLOW_CIRCLE_INDEX = 3;
export const SELECTED_FLOW_CIRCLE = "orange-circle";
export const FILLED_FLOW_CIRCLE = "checked-circle";

///Time entry info constants
export const TIME_ENTRY_ACTIVITY_MINIMUM_VALUE_LENGTH = 1;
export const TIME_ENTRY_ACTIVITY_SCROLL_VALUE = 4;
export const TIME_ENTRY_ACTIVITY_MINIMUM_VALUE_CHECK_LENGTH = 5;
export const TIME_ENTRY_ACTIVITY_MAXIMUM_VALUE_LENGTH = 10;
export const TIME_ENTRY_ACTIVITY_MINIMUM_HIGHEST_LENGTH = 11;

//Time assistant role constants
export const USER_ROLE = "user";
export const ADMIN_ROLE = "admin";

///All entry page constants
export const VIEW_ENTRIES = "view_Entries";
export const DOWNLOAD_ENTRIES = "download_Entries";
export const MIN_YEAR = 2016;

///Projectless tasks constants
export const NOT_SELECTED = -1;
export const OTHER_PROJECT = -2;

//Excel upload response constants
export const ERROR_DATE_FIELD = "Error related to date field";
export const ERROR_DESC_FIELD = "Error related to description field";
export const ERROR_HOUR_FIELD = "Error related to hour field";
export const NO_ERROR = "Entries updated successfully";
