import { TimeAssistantApi } from "@/infrastructure/http/TimeAssistantApiResource";
import store from "@/store/store";

export async function getMyTasksFromWorkspace(
  workspace_gid: number,
  firebaseuid: string
) {
  const result = await TimeAssistantApi.getMyTasksFromWorkspace(
    workspace_gid,
    firebaseuid
  );
  store.dispatch("updateAllCustomFields", result.custom_fields_infos);
  return result ? result.all_tasks : null;
}

export async function getSingleTaskInfo(task_gid: number, firebaseuid: string) {
  const result = await TimeAssistantApi.getSingleTaskInfo(
    task_gid,
    firebaseuid
  );
  return result;
}

export async function getMyProjectsFromWorkspace(
  workspace_gid: number,
  firebaseuid: string
) {
  const result = await TimeAssistantApi.getMyProjectsFromWorkspace(
    workspace_gid,
    firebaseuid
  );
  return result ? result.all_projects : null;
}

export async function getUserProfileByProject(
  user_uid: string,
  project_name: string
) {
  const result = await TimeAssistantApi.getUserProfileByProject(
    user_uid,
    project_name
  );
  return result;
}

export async function setUserDefaultProfileByProject(
  user_uid: string,
  project_id: string,
  role_id: string
) {
  const result = await TimeAssistantApi.setUserDefaultProfileByProject(
    user_uid,
    project_id,
    role_id
  );

  return result;
}

export async function removeUserDefaultProfileByProject(
  user_uid: string,
  project_id: string
) {
  const result = await TimeAssistantApi.removeUserDefaultProfileByProject(
    user_uid,
    project_id
  );

  return result;
}
