
import { disableAsanaPopup, getMyAsanaAuth } from "@/service/AsanaService";
import { defineComponent } from "vue";
import loadingStore from "@/store/store";
import { ASANA_SKIPPED } from "@/constants/IntegrationConstants";
import checkBox from "@/components/CheckBox.vue";
import { UserInterface } from "@/models/sso/user";
import store from "@/store/store";

export default defineComponent({
  components: {
    checkBox,
  },
  data() {
    return {
      checkboxMarked: true,
      checkboxText: this.$t("component-asana-popup.disable-popup"),
    };
  },
  methods: {
    skipPopup() {
      if (this.checkboxMarked) {
        const user: UserInterface = store.getters.getUser;
        store.dispatch("updateAsanaPopupdisabled", true);
        disableAsanaPopup(user.firebase_uid, user.document_id, true);
      }
      loadingStore.dispatch("changeAsanaIntegrationState", ASANA_SKIPPED);
    },
    async connectAsana() {
      const asanaCallbackLink = await getMyAsanaAuth();
      if (asanaCallbackLink) {
        window.location.replace(asanaCallbackLink);
      }
    },
    toggleCheckBox() {
      this.checkboxMarked = !this.checkboxMarked;
    },
  },
});
