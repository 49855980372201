import {
  getMyTasksFromWorkspace,
  getMyProjectsFromWorkspace,
} from "@/service/TaskService";
import { AsanaTask } from "@/models/time_assistant/asana_task";
import { AsanaProject } from "@/models/time_assistant/asana_project";
import taskStore from "@/store/store";
import projectStore from "@/store/store";

export async function callAsanaAPI(
  workspace_gid: number,
  firebase_uid: string
) {
  return Promise.all([
    getMyTasksFromWorkspace(workspace_gid, firebase_uid),
    getMyProjectsFromWorkspace(workspace_gid, firebase_uid),
  ]);
}

export function filterTaskUpdate(
  asanaTaskList: AsanaTask[] | [],
  asanaProjectList: AsanaProject[]
) {
  const updatedAsanaTaskList = [] as AsanaTask[];
  asanaTaskList?.forEach((task) => {
    if (!task.completed) {
      const taskProject = asanaProjectList.find(
        (element) => element.gid === task.project_gid
      );
      task.color = taskProject?.color;
      updatedAsanaTaskList.push(task);
    }
    taskStore.dispatch("updateTaskState", updatedAsanaTaskList);
  });
  return updatedAsanaTaskList;
}

export function filterProjectUpdate(
  asanaTaskList: AsanaTask[],
  asanaProjectList: AsanaProject[]
) {
  if (!asanaTaskList) {
    return [] as AsanaTask[];
  }
  let updatedProjectList = [] as AsanaProject[];
  const project_gids = asanaTaskList.map((task: AsanaTask) => {
    if (!task.completed) {
      return task.project_gid;
    }
  });
  const uniqueGids = project_gids.filter(function (item, pos) {
    return project_gids.indexOf(item) == pos && item !== undefined;
  });
  updatedProjectList = asanaProjectList.filter((project: AsanaProject) =>
    uniqueGids.includes(project.gid)
  );
  projectStore.dispatch("updateProjectState", updatedProjectList);
  return updatedProjectList;
}
