
import { defineComponent, ref } from "vue";
import Datepicker from "vue3-date-time-picker";
import store from "@/store/store";
import { getEntriesByMonthAndYear } from "@/service/TimeEntryService";
import { FullActivityInformation } from "@/models/time_assistant/activity-item";
import { format } from "date-fns";
import timeEntryInfoItem from "@/components/TimeEntryInfoComponents/TimeEntryInfoItem.vue";
import TaskItemHeader from "@/components/TaskItemHeader.vue";
import router from "@/router";
import adminDropdown from "@/components/AllEntriesComponents/AdminAllUserInfoDropdown.vue";
import * as timeEntryConstants from "@/constants/TimeEntryConstants";
import * as pageNames from "@/router/pageNames";
import { downloadExcelFile } from "@/utils/download-utils";
import tooltip from "@/components/Tooltip.vue";

export default defineComponent({
  name: "All Entry View",
  components: {
    timeEntryInfoItem,
    TaskItemHeader,
    Datepicker,
    adminDropdown,
    tooltip,
  },
  data() {
    return {
      currentYeat: new Date().getFullYear(),
      userEmail: store.getters.getUser.email,
      currentYear: new Date().getFullYear(),
      minYear: timeEntryConstants.MIN_YEAR,
      selectedDate: "",
      date: new Date(store.getters.getDateValue),
      entriesByDate: [] as FullActivityInformation[],
      sortedActivityList: [] as FullActivityInformation[],
      userUid: store.getters.getUser.firebase_uid,
      storedMonth: {},
      storedAdminUid: "",
      totalTime: 0,
      displayedTotalTime: "",
      customerHours: 0,
      internalHours: 0,
      isAdmin:
        store.getters.getUser.user_role === timeEntryConstants.ADMIN_ROLE,
      originUid: store.getters.getUser.firebase_uid,
      downloadButtonText: this.$t(
        "component-all-entries-view.download-entries"
      ),
      noProjectName: this.$t("component-task-list.no-project"),
    };
  },
  setup() {
    const month = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });

    return {
      month,
    };
  },
  methods: {
    async leavePage() {
      await store.dispatch("updateAdminSelectionUserUid", "");
      await store.dispatch("updateEnableEditing", false);
      await store.dispatch("updateEnableAllEntryViewEditing", false);
      router.push("/dashboard/tasks");
    },
    async downloadMonthlyEntries(monthValue: any) {
      store.dispatch("changeLoadingState", true);
      await downloadExcelFile(monthValue, this.userUid, this.userEmail);
      store.dispatch("changeLoadingState", false);
    },
    async getDateValue(monthValue: any) {
      store.dispatch("changeLoadingState", true);
      if (store.getters.getAdminSelectionUserUid) {
        this.userUid = store.getters.getAdminSelectionUserUid;
      } else {
        this.userUid = store.getters.getUser.firebase_uid;
      }

      if (
        this.month !== this.storedMonth ||
        this.userUid !== this.storedAdminUid
      ) {
        const monthValueObject = Object.assign({}, monthValue);

        const day = 2; //Passing second day, reason related new Date() change to string in backend
        const month = monthValueObject.month + 1;
        const year = monthValueObject.year;
        const newDate = new Date(`${year}/${month}/${day}`);
        this.selectedDate = this.converteDate(newDate);
        this.storedMonth = this.month;
        this.storedAdminUid = this.userUid;

        this.entriesByDate = await getEntriesByMonthAndYear(
          this.userUid,
          this.selectedDate,
          timeEntryConstants.VIEW_ENTRIES,
          this.userEmail
        );
        this.sortedActivityList = this.entriesByDate.sort((a, b) => {
          return (
            new Date(a.updatedAt).valueOf() - new Date(b.updatedAt).valueOf()
          );
        });

        this.totalTime = 0;
        this.entriesByDate?.forEach((element: FullActivityInformation) => {
          this.totalTime = this.totalTime + element.spent_time_min;
          // TODO: Enable when working on resolving issue with ASANA private project hours
          // if (element.project_id !== "here is firebase customer ID") {
          //   this.internalHours = this.internalHours + element.spent_time_min;
          // } else {
          //   this.customerHours = this.customerHours + element.spent_time_min;
          // }
        });

        let combinedHoursString = Math.floor(this.totalTime / 60).toString();
        let combinedMinutesString = (this.totalTime % 60).toString();
        this.displayedTotalTime = `${combinedHoursString}h ${combinedMinutesString}m`;
      }
      store.dispatch("changeLoadingState", false);
    },
    converteDate(date: Date) {
      return date.toString();
    },
    async viewEntryInfo(entry: FullActivityInformation) {
      if (this.originUid === this.userUid) {
        const allTasks = store.getters.getUniqueTaskListArray;
        const allProjects = store.getters.getUniqueTaskListProjectArrayState;

        const relatedTask = allTasks.find(
          (element: { task_name: string }) => entry.name === element.task_name
        );

        const relatedProject = allProjects.find(
          (element: { gid: string }) => relatedTask.project_gid === element.gid
        );

        await store.dispatch("updateSelectedTaskState", relatedTask);
        await store.dispatch("updateTask", relatedTask);
        await store.dispatch("updateProject", relatedProject);

        if (entry.value[0].length > 0) {
          await store.dispatch("updateConfirmValueCheck", true);
          await store.dispatch("updateTaskValue", entry.value[0]);
        }

        await store.dispatch("updateEnableEditing", true);
        await store.dispatch("updateEnableAllEntryViewEditing", true);

        await store.dispatch("updateProceedWithTask", true);
        await store.dispatch("updateRoleID", entry.roleID);
        await store.dispatch("updateActions", entry.actions);
        await store.dispatch("updateEditableTechnologies", entry.technology);
        await store.dispatch("updateTechnologyArray", entry.technology);
        await store.dispatch("updateDateValue", entry.updatedAt);
        await store.dispatch("updateProfileName", entry.role);
        await store.dispatch("updateEditableID", entry.ID);
        await store.dispatch("updateCustonTaskValue", entry.value[1]);

        const hours = Math.floor(entry.spent_time_min / 60);
        const minutes = entry.spent_time_min % 60;

        await store.dispatch("updateHour", hours);
        await store.dispatch("updateMinutes", minutes);

        await store.dispatch("updateShowCompletionInfo", true);
        await store.dispatch("updateShowTagSelectionInfo", true);

        router.push("/dashboard/newTimeEntry");
      }
    },
  },
  computed: {
    sortedDate() {
      let sortedResult: FullActivityInformation[] = [];

      sortedResult = this.sortedActivityList;
      const result = sortedResult.reduce(
        function (
          r: { [x: string]: any[] },
          a: { updatedAt: string | number }
        ) {
          r[format(new Date(a.updatedAt), "yyyy-MM-dd")] =
            r[format(new Date(a.updatedAt), "yyyy-MM-dd")] || [];
          r[format(new Date(a.updatedAt), "yyyy-MM-dd")].push(a);

          return r;
        },

        Object.create(null)
      );
      return result;
    },
  },
  async mounted() {
    await store.dispatch("updateShowSideBar", true);
    store.dispatch("updateSelectedSidebarElement", pageNames.ALL_ENTRIES_PAGE);
    this.getDateValue(this.month);
  },
});
