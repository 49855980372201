import { TimeAssistantApi } from "@/infrastructure/http/TimeAssistantApiResource";
import {
  TimeEntry,
  UpdateableTimeEntry,
  UpdateableTimeEntryDescription,
} from "@/models/time_assistant/time_entry";

export async function getTimeEntryInformations(
  user_uid: string,
  objective_id: string,
  project_id: string
) {
  const result = await TimeAssistantApi.getTimeEntryInformations(
    user_uid,
    objective_id,
    project_id
  );
  return result;
}

export async function saveTimeEntryInformations(
  timeEntryInformations: TimeEntry
) {
  const result = await TimeAssistantApi.saveTimeEntryInformations(
    timeEntryInformations
  );
  return result.activity_id;
}

export async function getTimeEntryActivities(objective_id: string) {
  const result = await TimeAssistantApi.getTimeEntryActivities(objective_id);
  return result;
}

export async function getEntriesByMonthAndYear(
  user_uid: string,
  selected_date: string,
  download_entries: string,
  user_email: string
) {
  const result = await TimeAssistantApi.getEntriesByMonthAndYear(
    user_uid,
    selected_date,
    download_entries,
    user_email
  );
  return result;
}

export async function deleteTimeEntryActivities(
  ID: string,
  firebase_id: string,
  time_entry_minutes: string
) {
  await TimeAssistantApi.deleteTimeEntryActivities(
    ID,
    firebase_id,
    time_entry_minutes
  );
  return "";
}

export async function updateTimeEntryActivities(
  acivity: UpdateableTimeEntry,
  descriptionInformation: UpdateableTimeEntryDescription
) {
  await TimeAssistantApi.updateTimeEntryActivities(
    acivity,
    descriptionInformation
  );
  return "";
}

export async function uploadUserExcelEntries(
  bufferFile: any,
  passedValues: any
) {
  const info = await TimeAssistantApi.uploadUserExcelEntries(
    bufferFile,
    passedValues
  );
  if (info.error) {
    return info.error;
  } else {
    return info;
  }
}
