
import router from "@/router";
import { defineComponent } from "vue";
import taButton from "@/components/Button.vue";
import { UserInterface } from "@/models/sso/user";
import store from "@/store/store";
import {
  allIntegrationByUser,
  removeIntegration,
} from "@/service/IntegrationService";
import { Integration } from "@/models/time_assistant/integration";
import { getMyAsanaAuth } from "@/service/AsanaService";
import { ASANA } from "@/constants/IntegrationConstants";

export default defineComponent({
  name: "Profile view",
  components: {
    taButton,
  },
  data() {
    return {
      asanaIntegration: {} as Integration,
      asanaText: this.$t("component-profile-view.connect-asana"),
      enabledLoader: true,
      justDeleted: false,
    };
  },
  methods: {
    async leavePage() {
      router.push("/dashboard/tasks");
    },
    async triggerAsanaIntegration() {
      if (!this.enabledLoader) {
        await store.dispatch("changeLoadingState", true);
        if (this.asanaIntegration && !this.justDeleted) {
          await removeIntegration(this.asanaIntegration.id);
          await store.dispatch("updateloadTaskList", true);
          this.asanaText = this.$t("component-profile-view.connect-asana");
          this.justDeleted = !this.justDeleted;
        } else {
          const asanaCallbackLink = await getMyAsanaAuth();
          if (asanaCallbackLink) {
            window.location.replace(asanaCallbackLink);
          }
        }
        await store.dispatch("changeLoadingState", false);
      }
    },
  },
  async mounted() {
    store.dispatch("updateSelectedSidebarElement", "");
    await store.dispatch("updateShowSideBar", true);
    const user: UserInterface = store.getters.getUser;

    const allIntegrations = await allIntegrationByUser(user.firebase_uid);

    this.asanaIntegration = allIntegrations.all_user_integrations.find(
      (element: Integration) => element.integration_name === ASANA
    );
    if (this.asanaIntegration) {
      this.asanaText = this.$t("component-profile-view.disconnect-asana");
    }
    this.enabledLoader = false;
  },
});
