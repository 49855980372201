
import { defineComponent } from "vue";
import Datepicker from "vue3-date-time-picker";
import "vue3-date-time-picker/dist/main.css";
import taButton from "@/components/Button.vue";
import store from "@/store/store";
import TimeSelector from "@/components/TimeSelector.vue";
import emitter from "@/utils/emitter";
import * as timeEntryConstants from "@/constants/TimeEntryConstants";
import {
  addToFlowCircleArray,
  removerFromFlowCircleArray,
} from "@/helpers/TimeEntryHelper";

export default defineComponent({
  components: { Datepicker, taButton, TimeSelector },
  data() {
    return {
      selectedDate: "",
      currentDate: new Date(),
      yesterdayDate: "",
      minmalAllowedValue: new Date(),
      time_section_text: this.$t("DateAndTime.time"),
      requiredSymbol: this.$t("required.symbol"),
      date: new Date(store.getters.getDateValue),
      storedHours: store.getters.getHour,
      storedMinutes: store.getters.getMinutes,
      time_suggestions_text: this.$t("DateAndTime.time-suggestion-text"),
      time_suggestions: store.getters.getTimeSuggestions as number[],
      time_suggestion_selected: 0,
    };
  },
  setup() {
    const format = (date: Date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };
    return {
      format,
    };
  },
  methods: {
    async clearDate() {
      this.selectedDate = "";
      await store.dispatch("updateDateValue", this.selectedDate);
      this.checkRequiredValues();
    },
    async getDateValue(dateValue: Date) {
      this.selectedDate = this.convertedDate(dateValue);
      await store.dispatch("updateDateValue", this.selectedDate);
      this.checkRequiredValues();
    },
    async today() {
      let today = new Date();
      this.date = today;
      this.selectedDate = this.convertedDate(today);
      await store.dispatch("updateDateValue", this.selectedDate);
      this.checkRequiredValues();
    },
    async yesterday() {
      let today = new Date();
      let yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      this.date = yesterday;
      this.selectedDate = this.convertedDate(yesterday);
      await store.dispatch("updateDateValue", this.selectedDate);
      this.checkRequiredValues();
    },
    convertedDate(date: Date) {
      return [
        date.getFullYear(),
        this.formatDate(date.getMonth() + 1),
        this.formatDate(date.getDate()),
      ].join("-");
    },
    formatDate(num: number) {
      return num.toString().padStart(2, "0");
    },
    getHour(totalMinutes: number) {
      return Math.floor(totalMinutes / 60);
    },
    getMinutes(totalMinutes: number) {
      const minutes = totalMinutes % 60;
      return minutes;
    },
    selectTimeSuggestions(minutes: number) {
      this.time_suggestion_selected = minutes;
      emitter.emit("selectSuggestionHour", this.getHour(minutes));
      emitter.emit("selectSuggestionMinutes", this.getMinutes(minutes));
      this.checkRequiredValues();
    },
    checkSuggestion(valueSelected: number) {
      if (valueSelected !== this.time_suggestion_selected) {
        this.time_suggestion_selected = valueSelected;
      }
      this.checkRequiredValues();
    },
    generateButtonText(totalMinutes: number) {
      const hour = this.getHour(totalMinutes);
      let hourText = "" + hour;
      if (hour < 10) {
        hourText = "0" + hourText;
      }
      const minutes = this.getMinutes(totalMinutes);
      let minutesText = "" + minutes;
      if (minutes === 0) {
        minutesText = "00";
      }
      return hourText + ":" + minutesText;
    },
    async checkRequiredValues() {
      const storedDate = store.getters.getDateValue;
      const storedHour = store.getters.getHour;
      const storedMinutes = store.getters.getMinutes;
      const checkIfSelected = (storedHour || storedMinutes) && storedDate;
      this.$emit("dateAndTimeSelected", !!checkIfSelected.length);
      if (checkIfSelected.length) {
        addToFlowCircleArray(timeEntryConstants.DATE_FLOW_CIRCLE_INDEX);
      } else {
        removerFromFlowCircleArray(timeEntryConstants.DATE_FLOW_CIRCLE_INDEX);
      }
    },
  },
  mounted() {
    if (store.getters.getDateValue) {
      const entryDate = new Date(store.getters.getDateValue);
      this.selectedDate = this.convertedDate(entryDate);
    } else {
      this.today();
    }

    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    let minDateValue = new Date();
    minDateValue.setDate(minDateValue.getDate() - 40);
    this.minmalAllowedValue = minDateValue;
    this.time_suggestion_selected = this.storedHours * 60 + this.storedMinutes;
    this.yesterdayDate = this.convertedDate(yesterday);
    this.time_suggestion_selected = this.storedHours * 60 + this.storedMinutes;
    this.checkRequiredValues();
  },
});
