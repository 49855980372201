
import { defineComponent } from "vue";
import store from "@/store/store";
import * as timeEntryConstants from "@/constants/TimeEntryConstants";
import emitter from "@/utils/emitter";

export default defineComponent({
  name: "flowCircles",
  emits: ["selectedCircle"],
  data() {
    return {
      selected: 0,
      selectedCircleArray: [],
    };
  },
  props: {
    circleTextArray: {
      type: Array,
    },
    circleMaxValue: {
      default: 0,
      type: Number,
    },
    canProceed: {
      default: false,
      type: Boolean,
    },
  },
  created() {
    emitter.on("selectedCircleWithEdit", (index: any) =>
      this.updateSelectedCircle(index)
    );
  },
  methods: {
    toggleCircle(index: number) {
      if (index <= this.circleMaxValue) {
        //check to understand if you can move between the circles:
        //you can only move if you have entered the mandatory parameters (variable canProceed) to go to the next or you want to go back
        const indexValue = index;
        let filledCircleArrayNumbers = [] as number[];
        filledCircleArrayNumbers = this.selectedCircleArray;
        if (this.canProceed || index <= this.selected) {
          if (index === timeEntryConstants.TASK_FLOW_CIRCLE_INDEX) {
            //Always selectable if first flow circle
            this.selected = index;
            this.$emit("selectedCircle", this.selected);
          } else {
            if (filledCircleArrayNumbers.includes(indexValue - 1)) {
              //Checks if previous flow circle is checked
              if (index === timeEntryConstants.COMPLETION_FLOW_CIRCLE_INDEX) {
                //Checks if final flow circle can be clicked
                if (filledCircleArrayNumbers.includes(indexValue)) {
                  this.selected = index;
                  this.$emit("selectedCircle", this.selected);
                }
              } else {
                this.selected = index;
                this.$emit("selectedCircle", this.selected);
              }
            }
          }
        }
      }
    },
    circleClass(index: number) {
      const flowCircleArray = store.getters.getFlowCircleChecks;
      this.selectedCircleArray = flowCircleArray;
      if (index === this.selected) {
        return timeEntryConstants.SELECTED_FLOW_CIRCLE;
      } else if (flowCircleArray.includes(index)) {
        return timeEntryConstants.FILLED_FLOW_CIRCLE;
      } else {
        return "";
      }
    },
    nextCircle() {
      this.selected += 1;
      this.$emit("selectedCircle", this.selected);
    },
    updateSelectedCircle(index: number) {
      this.selected = index;
    },
  },
});
