import { Module } from "vuex";
import { RootState } from "@/store/store";
import { ComperableTimeEntry } from "@/models/time_assistant/time_entry";

export interface editEntryState {
  enableEditing: boolean;
  enableAllEntryViewEditing: boolean;
  showCompletionInfo: boolean;
  showTagSelectionInfo: boolean;
  editableTechnologies: string[];
  profileName: string;
  editableID: string;
  originalRoleID: string;
  defaultCheckHasBeenTriggered: boolean;
  acquiredOriginalEntry: boolean;
  orginalTimeEntry: ComperableTimeEntry;
}

export const editEntryStore: Module<editEntryState, RootState> = {
  state: {
    enableEditing: false,
    enableAllEntryViewEditing: false,
    showCompletionInfo: false,
    showTagSelectionInfo: false,
    editableTechnologies: [],
    profileName: "",
    editableID: "",
    originalRoleID: "",
    defaultCheckHasBeenTriggered: false,
    acquiredOriginalEntry: false,
    orginalTimeEntry: {} as ComperableTimeEntry,
  },
  mutations: {
    setEnableEditing(state, payload) {
      state.enableEditing = payload;
    },
    setEnableAllEntryViewEditing(state, payload) {
      state.enableAllEntryViewEditing = payload;
    },
    setShowCompletionInfo(state, payload) {
      state.showCompletionInfo = payload;
    },
    setShowTagSelectionInfo(state, payload) {
      state.showTagSelectionInfo = payload;
    },
    setEditableTechnologies(state, payload) {
      state.editableTechnologies = payload;
    },
    setProfileName(state, payload) {
      state.profileName = payload;
    },
    setEditableID(state, payload) {
      state.editableID = payload;
    },
    setOriginalRoleID(state, payload) {
      state.originalRoleID = payload;
    },
    setDefaultCheckHasBeenTriggered(state, payload) {
      state.defaultCheckHasBeenTriggered = payload;
    },
    setAcquiredOriginalEntry(state, payload) {
      state.acquiredOriginalEntry = payload;
    },
    setOrginalTimeEntry(state, payload) {
      state.orginalTimeEntry = payload;
    },
  },
  actions: {
    updateEnableEditing(context, enableEditing) {
      context.commit("setEnableEditing", enableEditing);
    },
    updateEnableAllEntryViewEditing(context, enableAllEntryViewEditing) {
      context.commit("setEnableAllEntryViewEditing", enableAllEntryViewEditing);
    },
    updateShowCompletionInfo(context, showCompletionInfo) {
      context.commit("setShowCompletionInfo", showCompletionInfo);
    },
    updateShowTagSelectionInfo(context, showTagSelectionInfo) {
      context.commit("setShowTagSelectionInfo", showTagSelectionInfo);
    },
    updateEditableTechnologies(context, editableTechnologies) {
      context.commit("setEditableTechnologies", editableTechnologies);
    },
    updateProfileName(context, profileName) {
      context.commit("setProfileName", profileName);
    },
    updateEditableID(context, editableID) {
      context.commit("setEditableID", editableID);
    },
    updateOriginalRoleID(context, originalRoleID) {
      context.commit("setOriginalRoleID", originalRoleID);
    },
    updateDefaultCheckHasBeenTriggered(context, defaultCheckHasBeenTriggered) {
      context.commit(
        "setDefaultCheckHasBeenTriggered",
        defaultCheckHasBeenTriggered
      );
    },
    updateAcquiredOriginalEntryCheck(context, acquiredOriginalEntry) {
      context.commit("setAcquiredOriginalEntry", acquiredOriginalEntry);
    },
    updateOrginalTimeEntry(context, orginalTimeEntry) {
      context.commit("setOrginalTimeEntry", orginalTimeEntry);
    },
  },
  getters: {
    getEnableEditing(state) {
      return state.enableEditing;
    },
    getEnableAllEntryViewEditing(state) {
      return state.enableAllEntryViewEditing;
    },
    getShowCompletionInfo(state) {
      return state.showCompletionInfo;
    },
    getShowTagSelectionInfo(state) {
      return state.showTagSelectionInfo;
    },
    getEditableTechnologies(state) {
      return state.editableTechnologies;
    },
    getProfileName(state) {
      return state.profileName;
    },
    getEditableID(state) {
      return state.editableID;
    },
    getOriginalRoleID(state) {
      return state.originalRoleID;
    },
    getDefaultCheckHasBeenTriggered(state) {
      return state.defaultCheckHasBeenTriggered;
    },
    getAcquiredOriginalEntryCheck(state) {
      return state.acquiredOriginalEntry;
    },
    getOrginalTimeEntry(state) {
      return state.orginalTimeEntry;
    },
  },
};
