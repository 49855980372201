import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "app" }
const _hoisted_2 = { class: "snackbar-div" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_side_bar = _resolveComponent("side-bar")!
  const _component_snackbar = _resolveComponent("snackbar")!
  const _component_loading_wrapper = _resolveComponent("loading-wrapper")!
  const _component_not_supported_version_component = _resolveComponent("not-supported-version-component")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_side_bar, { class: "hide-for-not-supported-div" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_snackbar, {
        buttonActionText: _ctx.buttonActionText,
        text: _ctx.snackbarMessage,
        show: _ctx.showSnackbar,
        onCloseSnackBar: _ctx.closeSnackBar
      }, null, 8, ["buttonActionText", "text", "show", "onCloseSnackBar"])
    ]),
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_loading_wrapper, { key: 0 }))
      : _createCommentVNode("", true),
    _createVNode(_component_not_supported_version_component, { class: "not-supported-div" }),
    _createVNode(_component_router_view, { class: "router-div" })
  ]))
}