import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import RegistrationView from "@/views/RegistrationView.vue";
import LoginView from "@/views/LoginView.vue";
import ForgottenPasswordView from "@/views/ForgottenPasswordView.vue";
import * as pageNames from "@/router/pageNames";
import Dashboard from "@/views/Dashboard.vue";
import Callback from "@/views/AsanaCallback.vue";
import userStore from "@/store/store";
import { storeUserAndSession } from "@/utils/user-utils";
import { getMe } from "@/service/GetMeService";
import { UserInterface } from "@/models/sso/user";

const publicPages = [
  pageNames.LOGIN_PAGE,
  pageNames.REGISTRATION_PAGE,
  pageNames.REGISTRATION_VERIFIY_PAGE,
  pageNames.REGISTRATION_END_PAGE,
  pageNames.REGISTRATION_INVALID_LINK,
  pageNames.REGISTRATION_ALREADY_REGISTERED_USER,
  pageNames.RESET_PASSWORD_PAGE,
  pageNames.FORGOTTEN_PASSWORD_PAGE,
  pageNames.FORGOTTEN_PASSWORD_INVALID_LINK,
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/registration",
    name: pageNames.REGISTRATION_PAGE,
    component: RegistrationView,
  },
  {
    path: "/registration/verify",
    name: pageNames.REGISTRATION_VERIFIY_PAGE,
    component: RegistrationView,
  },
  {
    path: "/registration/end",
    name: pageNames.REGISTRATION_END_PAGE,
    component: RegistrationView,
  },
  {
    path: "/registration/invalidlink",
    name: pageNames.REGISTRATION_INVALID_LINK,
    component: RegistrationView,
  },
  {
    path: "/registration/useralreadyregistered",
    name: pageNames.REGISTRATION_ALREADY_REGISTERED_USER,
    component: RegistrationView,
  },
  {
    path: "/",
    name: pageNames.HOME_PAGE,
    component: Dashboard,
  },
  {
    path: "/login",
    name: pageNames.LOGIN_PAGE,
    component: LoginView,
  },
  {
    path: "/forgottenpassword",
    name: pageNames.FORGOTTEN_PASSWORD_PAGE,
    component: ForgottenPasswordView,
  },
  {
    path: "/forgottenpassword/resetpassword",
    name: pageNames.RESET_PASSWORD_PAGE,
    component: ForgottenPasswordView,
  },
  {
    path: "/forgottenpassword/invalidlink",
    name: pageNames.FORGOTTEN_PASSWORD_INVALID_LINK,
    component: ForgottenPasswordView,
  },
  {
    path: "/dashboard/tasks",
    name: pageNames.DASHBOARD_PAGE,
    component: Dashboard,
  },
  {
    path: "/callback",
    name: pageNames.ASANA_CALLBACK,
    component: Callback,
  },
  {
    path: "/dashboard/newTimeEntry",
    name: pageNames.TIME_ENTRY_PAGE,
    component: Dashboard,
  },
  {
    path: "/dashboard/timeEntries",
    name: pageNames.TIME_ENTRY_INFO_PAGE,
    component: Dashboard,
  },
  {
    path: "/dashboard/timeEntries/upload",
    name: pageNames.UPLOAD_EXCEL_PAGE,
    component: Dashboard,
  },
  {
    path: "/dashboard/allEntries",
    name: pageNames.ALL_ENTRIES_PAGE,
    component: Dashboard,
  },
  {
    path: "/dashboard/createProject",
    name: pageNames.PROJECT_CREATION_PAGE,
    component: Dashboard,
  },
  {
    path: "/dashboard/projects",
    name: pageNames.PROJECT_MANAGEMENT_PAGE,
    component: Dashboard,
  },
  {
    path: "/dashboard/createObjective",
    name: pageNames.OBJECTIVE_CREATION_PAGE,
    component: Dashboard,
  },
  {
    path: "/dashboard/profile",
    name: pageNames.PROFILE_PAGE,
    component: Dashboard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const inRestrictedPage = !publicPages.includes(String(to.name));
  const userStored = userStore.getters.getUser;
  const sessionCookieStored = userStore.getters.getSessionCookie;
  if (inRestrictedPage) {
    if (!userStored || !sessionCookieStored) {
      const getMeResult: { user: UserInterface; session: string } | null =
        await getMe();
      if (getMeResult) {
        if (getMeResult.user) {
          await storeUserAndSession(getMeResult);
          if (to.path === "/") {
            next("/dashboard/tasks");
          } else {
            next();
          }
        }
      } else {
        next("login");
      }
    } else {
      //user is already logged and we have his infos in the store
      next();
    }
  } else {
    //user is in a public page
    if (userStored && sessionCookieStored) {
      next("/dashboard/tasks");
    } else {
      const getMeResult: { user: UserInterface; session: string } | null =
        await getMe();
      if (getMeResult) {
        if (getMeResult.user) {
          await storeUserAndSession(getMeResult);
          next("/dashboard/tasks");
        }
      } else {
        //proceed to the next public page
        next();
      }
    }
  }
});

export default router;
