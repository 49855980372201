
import { defineComponent } from "vue";
import TaskList from "@/components/TaskList.vue";
import {
  callAsanaAPI,
  filterTaskUpdate,
  filterProjectUpdate,
} from "@/helpers/AsanaHelper";
import { localProjectFilter, localTaskFilter } from "@/helpers/LocalTaskHelper";
import { AsanaTask } from "@/models/time_assistant/asana_task";
import {
  TaProject,
  TransferableInterface,
} from "@/models/time_assistant/ta_project";
import { TaObjective } from "@/models/time_assistant/ta_objectives";
import { AsanaProject } from "@/models/time_assistant/asana_project";
import {
  allIntegrationByUser,
  integrationAccessToken,
  removeIntegration,
} from "@/service/IntegrationService";
import userStore from "@/store/store";
import loadingStore from "@/store/store";
import { UserInterface } from "@/models/sso/user";
import taskStore from "@/store/store";
import store from "@/store/store";
import * as pageNames from "@/router/pageNames";
import { projectsByUser } from "@/service/ProjectService";
import { objectivesByUser } from "@/service/ObjectiveService";
import { Integration } from "@/models/time_assistant/integration";
import { ASANA } from "@/constants/IntegrationConstants";

export default defineComponent({
  name: "Task list view",
  components: { TaskList },
  data() {
    return {
      user: userStore.getters.getUser as UserInterface,
      workspace_gid: 1132657203238371,
      asanaTaskList: [] as AsanaTask[],
      asanaProjectList: [] as AsanaProject[],
      storedTaskList: store.getters.getTaskState,
      storedProjectList: store.getters.getProjectState,
      storedTaProjectList: store.getters.getTaProjectArrayState,
      storedTaTaskList: store.getters.getTaObjectiveArrayState,
      SelectedString: pageNames.TIME_ENTRY_INFO_PAGE,
      taUserProjects: [] as TaProject[],
      taUserTasks: [] as TaObjective[],
      updatedTaUserProjects: [] as TransferableInterface[],
      updatedTaUserTasks: [] as AsanaTask[],
      firstLoadDone: false,
      secondLoadDone: false,
    };
  },
  async created() {
    taskStore.dispatch("updateSelectedTaskState", {});
    if (loadingStore.getters.getloadTaskList) {
      loadingStore.dispatch("changeLoadingState", true);
      const user: UserInterface = userStore.getters.getUser;
      const integrationName = ASANA;
      const integrationToken = await integrationAccessToken(
        integrationName,
        user.firebase_uid
      );

      if (integrationToken) {
        if (!integrationToken.confirmed_integration) {
          const allIntegrations = await allIntegrationByUser(user.firebase_uid);
          const asanaIntegration = allIntegrations.all_user_integrations.find(
            (element: Integration) => element.integration_name === ASANA
          );
          await removeIntegration(asanaIntegration.id);
          loadingStore.dispatch("changeAsanaIntegrationState", false);
        } else {
          [this.asanaTaskList, this.asanaProjectList] = await callAsanaAPI(
            this.workspace_gid,
            this.user.firebase_uid
          );
          loadingStore.dispatch("changeAsanaIntegrationState", true);
        }
      } else {
        loadingStore.dispatch("changeAsanaIntegrationState", false);
      }
      loadingStore.dispatch("updateloadTaskList", false);
      store.dispatch("updateProjectState", this.asanaProjectList);
      store.dispatch("updateTaskState", this.asanaTaskList);
    } else {
      this.asanaTaskList = this.storedTaskList as AsanaTask[];
      this.asanaProjectList = this.storedProjectList as AsanaProject[];
      this.updatedTaUserProjects = this
        .storedTaProjectList as TransferableInterface[];
      this.updatedTaUserTasks = this.storedTaTaskList as AsanaTask[];
    }
    this.firstLoadDone = true;
    if (this.firstLoadDone && this.secondLoadDone) {
      loadingStore.dispatch("changeLoadingState", false);
    }
  },
  computed: {
    updateTasks() {
      let asanaTaskList = this.asanaTaskList as AsanaTask[];
      let asanaProjectList = this.asanaProjectList as AsanaProject[];
      return filterTaskUpdate(asanaTaskList, asanaProjectList);
    },
    updateAsanaProjectList() {
      let asanaTaskList = this.asanaTaskList as AsanaTask[];
      let asanaProjectList = this.asanaProjectList as AsanaProject[];
      return filterProjectUpdate(asanaTaskList, asanaProjectList);
    },
    localTasks() {
      let recievedLocalTasks = this.taUserTasks as TaObjective[];
      return localTaskFilter(recievedLocalTasks);
    },
    localProjects() {
      let recievedLocalProjects = this.taUserProjects as TaProject[];
      return localProjectFilter(recievedLocalProjects);
    },
  },
  async mounted() {
    loadingStore.dispatch("changeLoadingState", true);
    const user: UserInterface = userStore.getters.getUser;
    this.taUserProjects = await projectsByUser(user.firebase_uid);
    this.taUserTasks = await objectivesByUser(user.firebase_uid);
    store.dispatch("updateShowSideBar", true);
    store.dispatch("updateSelectedSidebarElement", this.SelectedString);
    this.secondLoadDone = true;
    if (this.firstLoadDone && this.secondLoadDone) {
      loadingStore.dispatch("changeLoadingState", false);
    }
  },
});
