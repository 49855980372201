import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "confirm-exit-popup-wrapper" }
const _hoisted_2 = { class: "confirm-exit-popup-block" }
const _hoisted_3 = { class: "popup-content" }
const _hoisted_4 = { class: "default-text warning-text" }
const _hoisted_5 = { class: "button-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ta_button = _resolveComponent("ta-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.warningText), 1),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_ta_button, {
            class: "leave-button",
            text: _ctx.leaveText,
            backgroundColor: 'gray-dark-text',
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.continueToDashboard()))
          }, null, 8, ["text"]),
          _createVNode(_component_ta_button, {
            class: "stay-button",
            text: _ctx.stayText,
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.closePopup()))
          }, null, 8, ["text"])
        ])
      ])
    ])
  ]))
}