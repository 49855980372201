import { Module } from "vuex";
import { RootState } from "@/store/store";
import { ASANA_SKIPPED } from "@/constants/IntegrationConstants";

export interface loadingState {
  isLoading: boolean;
  isAsanaIntegrated: string | boolean;
  loadTaskList: boolean;
}

export const loadingStore: Module<loadingState, RootState> = {
  state: {
    isLoading: false,
    isAsanaIntegrated: ASANA_SKIPPED,
    loadTaskList: true,
  },
  mutations: {
    setLoadingState(state, payload: boolean) {
      state.isLoading = payload;
    },
    setAsanaIntegrationState(state, payload: string | boolean) {
      state.isAsanaIntegrated = payload;
    },
    setloadTaskList(state, payload) {
      state.loadTaskList = payload;
    },
  },
  actions: {
    changeLoadingState(context, isLoading) {
      context.commit("setLoadingState", isLoading);
    },
    changeAsanaIntegrationState(context, isAsanaIntegrated) {
      context.commit("setAsanaIntegrationState", isAsanaIntegrated);
    },
    updateloadTaskList(context, loadTaskList) {
      context.commit("setloadTaskList", loadTaskList);
    },
  },
  getters: {
    getLoadingState(state) {
      return state.isLoading;
    },
    getAsanaIntegrationState(state) {
      return state.isAsanaIntegrated;
    },
    getloadTaskList(state) {
      return state.loadTaskList;
    },
  },
};
