
import { defineComponent } from "vue";
import router from "@/router";
import loadingStore from "@/store/store";
import taButton from "@/components/Button.vue";
import { sendEmailResetUserPassword } from "@/service/ForgottenPasswordService";
import { userInfoByDocumentId } from "@/service/RegistrationService";

export default defineComponent({
  components: { taButton },
  data() {
    return {
      emailSentText: this.$t("snackbar.email-sent"),
      emailNotSentText: this.$t("snackbar.send-email-error"),
      receiveLinkButtonText: this.$t(
        "reset-password-link-exception.receive-link-button-text"
      ),
      returnLoginButtonText: this.$t(
        "reset-password-link-exception.return-login-button-text"
      ),
      mainText: this.$t("reset-password-link-exception.main-text"),
      buttonActionText: this.$t("snackbar.buttonActionText"),
      userEmail: "",
      showSnackbar: false,
      snackbarMessage: "",
    };
  },
  methods: {
    async repeatProcess() {
      const result = await sendEmailResetUserPassword(this.userEmail);
      if (result) {
        this.$emit("successEmail", this.emailSentText);
      } else {
        this.$emit("failedEmail", this.emailNotSentText);
      }
    },
    returnToLogin() {
      loadingStore.dispatch("changeLoadingState", true);
      router.push("/login");
    },
  },
  async mounted() {
    loadingStore.dispatch("changeLoadingState", true);
    const document_id = this.$route.query.id as string;
    const user_info = await userInfoByDocumentId(document_id);
    this.userEmail = user_info.email;
    loadingStore.dispatch("changeLoadingState", false);
  },
});
