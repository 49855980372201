import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-div-reset-password-exception" }
const _hoisted_2 = { class: "link-exception-text-container" }
const _hoisted_3 = { class: "h5-white link-exception-main-text" }
const _hoisted_4 = { class: "receive-new-link-button-container" }
const _hoisted_5 = { class: "return-to-login-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ta_button = _resolveComponent("ta-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.mainText), 1),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_ta_button, {
          class: "receive-new-link-button",
          text: _ctx.receiveLinkButtonText,
          backgroundColor: 'orange',
          onClick: _ctx.repeatProcess
        }, null, 8, ["text", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_ta_button, {
          class: "return-to-login-button",
          text: _ctx.returnLoginButtonText,
          onClick: _ctx.returnToLogin
        }, null, 8, ["text", "onClick"])
      ])
    ])
  ]))
}