
import { defineComponent } from "vue";
import {
  estimatedTimeInMinutes,
  remainingTimeText,
} from "@/helpers/TaskHelper";

export default defineComponent({
  data() {
    return {
      timePercentage: 0,
      timeOver: false,
      hour_symbol: this.$t("time-selector.hour-symbol"),
      minute_symbol: this.$t("time-selector.minute-symbol"),
      generic_minutes: this.$t("generic-text.short-min"),
      estimiated: this.$t("component-entry-info-view.estimated"),
      notEndedText: this.$t("remaining-time.not-ended-text"),
      endedText: this.$t("remaining-time.ended-text"),
      remainingProgressBarTime: "",
      estimatedProgressBarTime: "",
    };
  },
  emits: ["progressBarBlock"],
  props: {
    taskItemView: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateProgressBar(time_spent: number, estimated_time: number) {
      const percentage = ((time_spent / 60) * 100) / estimated_time;
      if (percentage >= 100) {
        this.timePercentage = 100;
        this.timeOver = true;
      } else {
        this.timePercentage = percentage;
      }
    },

    resetPercentage() {
      this.timePercentage = 0;
    },
    remainingTime(estimated_time: number, time_spent: number | undefined) {
      if (estimated_time) {
        const estimatedMinutes = estimatedTimeInMinutes(
          estimated_time
        ) as number;

        const hours = estimatedMinutes / 60;
        const displayedHours = Math.floor(hours);
        const calculatedMinutes = (hours - displayedHours) * 60;
        const displayedMinutes = Math.round(calculatedMinutes);

        this.estimatedProgressBarTime = `${displayedHours}${this.hour_symbol} ${displayedMinutes}${this.generic_minutes} ${this.estimiated}`;
        this.$emit("progressBarBlock");
        let remaining_time = estimatedMinutes;

        if (time_spent) {
          this.updateProgressBar(time_spent, estimated_time);
          remaining_time = remaining_time - time_spent;
        } else {
          this.updateProgressBar(0, estimated_time);
        }

        const hour = remaining_time / 60;
        const minutes = (hour - Math.floor(hour)) * 60;
        const remainingTimeString = remainingTimeText(
          remaining_time,
          hour,
          minutes,
          this.notEndedText,
          this.endedText,
          this.hour_symbol,
          this.minute_symbol
        ) as string;
        this.remainingProgressBarTime = remainingTimeString;
      } else {
        this.resetPercentage();
      }
    },
  },
});
