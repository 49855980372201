
import { defineComponent } from "vue";

import router from "@/router";
import store from "@/store/store";
import taButton from "@/components/Button.vue";
import Datepicker from "vue3-date-time-picker";
import TimeSelector from "@/components/TimeSelector.vue";
import { addObjective } from "@/service/ObjectiveService";
import { TransferableInterface } from "@/models/time_assistant/ta_project";
import tooltip from "@/components/Tooltip.vue";
import * as localTaskConstants from "@/constants/LocalTaskConstants";
import { AsanaTask } from "@/models/time_assistant/asana_task";

export default defineComponent({
  name: "Object creation view",
  components: { taButton, Datepicker, TimeSelector, tooltip },
  data() {
    return {
      Length120: localTaskConstants.LENGTH_120,
      objectiveNameValue: "",
      who: localTaskConstants.WHO_CONSTANT,
      what: localTaskConstants.WHAT_CONSTANT,
      why: localTaskConstants.WHY_CONSTANT,
      whoValue: "",
      whatValue: "",
      whyValue: "",
      whoTranslationKey: this.$t("component-new-task-view.who"),
      whatTranslationKey: this.$t("component-new-task-view.what"),
      whyTranslationKey: this.$t("component-new-task-view.why"),
      selectedDate: "",
      cancelText: this.$t("component-new-task-view.cancel-text"),
      saveText: this.$t("component-new-task-view.save-text"),
      objectiveDescription: "",
      taProject: store.getters.getTaProjectArrayState,
      date: new Date(),
      minmalAllowedValue: new Date(),
      selectedProjectName: "",
      selectedProjectGid: "",
      selectedProjectColor: "",
      localSourceName: "Time Assistant",
      hideFirstError: true,
      hideSecondError: true,
      firstErrorMessageText: this.$t(
        "objective-creation-view-component.first-error-message"
      ),
      secondErrorMessageText: this.$t(
        "objective-creation-view-component.second-error-message"
      ),
      whoTooltip: this.$t("objective-creation-view-component.who-tooltip-text"),

      whatTooltip: this.$t(
        "objective-creation-view-component.what-tooltip-text"
      ),
      whyTooltip: this.$t("objective-creation-view-component.why-tooltip-text"),
      requiredSymbol: this.$t("required.symbol"),
      nameOfScrollableText: "",
      currentAcquiredTasks: store.getters.getTaObjectiveArrayState,
    };
  },
  setup() {
    const format = (date: Date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    };
    return {
      format,
    };
  },
  methods: {
    checkForWidth(event: any) {
      if (event.target.offsetWidth < event.target.scrollWidth) {
        this.nameOfScrollableText = event.explicitOriginalTarget.outerText;
      }
    },
    async saveObjective() {
      if (!!this.objectiveNameValue && !!this.selectedProjectName) {
        await store.dispatch("changeLoadingState", true);
        const storedHours = store.getters.getHour;
        const storedMinutes = store.getters.getMinutes / 60;
        const combinedTime: number = storedHours + storedMinutes;
        const objectiveId = await addObjective(
          this.objectiveNameValue,
          this.selectedProjectName,
          this.selectedProjectGid,
          this.selectedProjectGid,
          this.selectedProjectColor,
          this.localSourceName,
          store.getters.getUser.firebase_uid,
          this.selectedDate,
          combinedTime,
          this.whoValue,
          this.whatValue,
          this.whyValue,
          this.objectiveDescription
        );
        const newObjective: AsanaTask = {
          task_gid: objectiveId,
          task_name: this.objectiveNameValue,
          due_on: this.selectedDate,
          completed: false,
          custom_fields: {
            why: this.whyValue,
            estimated_time_hours: combinedTime,
          },
          project_gid: this.selectedProjectGid,
          color: this.selectedProjectColor,
          firebase_id: objectiveId,
        };
        if (objectiveId) {
          this.currentAcquiredTasks.push(newObjective);
          store.dispatch(
            "updateTaObjectiveArrayState",
            this.currentAcquiredTasks
          );
          await store.dispatch("changeLoadingState", false);
          store.commit("resetTime");
          router.push("/dashboard/tasks");
        }
      } else {
        if (!this.objectiveNameValue) {
          this.hideFirstError = false;
        }
        if (!this.selectedProjectName) {
          this.hideSecondError = false;
        }
      }
    },
    selectProject(projectName: TransferableInterface) {
      this.selectedProjectName = projectName.name;
      this.selectedProjectGid = projectName.gid;
      this.selectedProjectColor = projectName.color;
      this.hideSecondError = true;
    },
    updatedQuestion(question: string) {
      switch (question) {
        case localTaskConstants.WHO_CONSTANT: {
          let customValueRef = this.$refs.whoRef as HTMLInputElement;
          this.whoValue = customValueRef.value;
          break;
        }
        case localTaskConstants.WHAT_CONSTANT: {
          let customValueRef = this.$refs.whatRef as HTMLInputElement;
          this.whatValue = customValueRef.value;
          break;
        }
        case localTaskConstants.WHY_CONSTANT: {
          let customValueRef = this.$refs.whyRef as HTMLInputElement;
          this.whyValue = customValueRef.value;
          break;
        }
      }
    },
    async clearDate() {
      this.selectedDate = "";
      await store.dispatch("updateDateValue", this.selectedDate);
    },
    async getDateValue(dateValue: Date) {
      this.selectedDate = this.convertedDate(dateValue);

      await store.dispatch("updateDateValue", this.selectedDate);
    },
    updateOptionalText() {
      let customValueRef = this.$refs.objectiveDescription as HTMLInputElement;
      this.objectiveDescription = customValueRef.value;
    },
    updateobjectiveNameValue() {
      let customValueRef = this.$refs.objectiveNameInput as HTMLInputElement;
      this.objectiveNameValue = customValueRef.value;
      this.hideFirstError = true;
    },
    async leavePage() {
      router.push("/dashboard/tasks");
    },
    convertedDate(date: Date) {
      return [
        date.getFullYear(),
        this.formatDate(date.getMonth() + 1),
        this.formatDate(date.getDate()),
      ].join("-");
    },
    formatDate(num: number) {
      return num.toString().padStart(2, "0");
    },
  },
  async mounted() {
    await store.dispatch("updateShowSideBar", true);
    this.selectedDate = this.convertedDate(new Date());
    await store.dispatch("updateDateValue", this.selectedDate);
  },
});
