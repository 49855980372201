import Vuex from "vuex";

import { tokenStore, tokenState } from "@/store/tokenStore";
import { userStore, userState } from "@/store/userStore";
import { loadingStore, loadingState } from "@/store/loadingStore";
import { projectState, projectStore } from "@/store/projectStore";
import { taskState, taskStore } from "@/store/taskStore";
import { activityState, activityStore } from "@/store/activityStore";
import { displayState, displayStore } from "@/store/displayStore";
import { editEntryState, editEntryStore } from "@/store/editEntryStore";
import { editProjectState, editProjectStore } from "@/store/editProjectStore";

export interface RootState {
  tokenStore: tokenState;
  userStore: userState;
  loadingStore: loadingState;
  projectStore: projectState;
  taskStore: taskState;
  activityStore: activityState;
  displayStore: displayState;
  editEntryStore: editEntryState;
  editProjectStore: editProjectState;
}

export default new Vuex.Store({
  modules: {
    tokenStore,
    userStore,
    loadingStore,
    projectStore,
    taskStore,
    activityStore,
    displayStore,
    editEntryStore,
    editProjectStore,
  },
});
