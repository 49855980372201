import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "link-exception-container" }
const _hoisted_2 = { class: "link-exception-text-container" }
const _hoisted_3 = { class: "h4-white link-exception-main-text" }
const _hoisted_4 = { class: "resend-email-container" }
const _hoisted_5 = { class: "resend-email-messages" }
const _hoisted_6 = { class: "medium-text-white" }
const _hoisted_7 = { class: "medium-text-orange" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "expiration-date-text"
}
const _hoisted_10 = { key: 2 }
const _hoisted_11 = { class: "resend-email-button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ta_button = _resolveComponent("ta-button")!
  const _component_main_block = _resolveComponent("main-block")!

  return (_openBlock(), _createBlock(_component_main_block, { customClass: 'main-block-registration' }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.mainText), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, [
              _createTextVNode(_toDisplayString(_ctx.firstSubText) + " ", 1),
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.userEmail), 1),
              (_ctx.actualPage === _ctx.invalidLinkPage)
                ? (_openBlock(), _createElementBlock("br", _hoisted_8))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(_ctx.secondSubText) + " ", 1),
              (_ctx.actualPage === _ctx.invalidLinkPage)
                ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.expirationDays), 1))
                : _createCommentVNode("", true),
              (_ctx.actualPage === _ctx.invalidLinkPage)
                ? (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(_ctx.thirdSubText), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_ta_button, {
              text: _ctx.buttonText,
              backgroundColor: 'orange',
              onClick: _ctx.methodName
            }, null, 8, ["text", "onClick"])
          ])
        ])
      ]),
      _createVNode(_component_ta_button, {
        text: _ctx.cancelButtonText,
        customClass: 'cancel-button',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.returnToLogin()))
      }, null, 8, ["text"])
    ]),
    _: 1
  }))
}