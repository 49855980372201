import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"
import _imports_0 from '@/assets/images/img-placeholder.jpeg'


const _hoisted_1 = { class: "left-block" }
const _hoisted_2 = {
  key: 0,
  class: "welcome-img",
  src: _imports_0,
  alt: "lac-icon"
}
const _hoisted_3 = { class: "small-text-grey welcome-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.imageSrc)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.text), 1)
  ]))
}