import { Module } from "vuex";
import { RootState } from "@/store/store";

export interface displayState {
  showSideBar: boolean;
  selectedSidebarElement: string;
  asanaPopupdisabled: boolean;
}

export const displayStore: Module<displayState, RootState> = {
  state: {
    showSideBar: false,
    selectedSidebarElement: "",
    asanaPopupdisabled: false,
  },
  mutations: {
    setShowSideBar(state, payload) {
      state.showSideBar = payload;
    },
    setSelectedSidebarElement(state, payload) {
      state.selectedSidebarElement = payload;
    },
    setAsanaPopupdisabled(state, payload) {
      state.asanaPopupdisabled = payload;
    },
  },
  actions: {
    updateShowSideBar(context, showSideBar) {
      context.commit("setShowSideBar", showSideBar);
    },
    updateSelectedSidebarElement(context, selectedSidebarElement) {
      context.commit("setSelectedSidebarElement", selectedSidebarElement);
    },
    updateAsanaPopupdisabled(context, asanaPopupdisabled) {
      context.commit("setAsanaPopupdisabled", asanaPopupdisabled);
    },
  },
  getters: {
    getShowSideBar(state) {
      return state.showSideBar;
    },
    getSelectedSidebarElement(state) {
      return state.selectedSidebarElement;
    },
    getAsanaPopupdisabled(state) {
      return state.asanaPopupdisabled;
    },
  },
};
