import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/images/google-logo.png'
import _imports_1 from '@/assets/images/close_icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "google-image",
  src: _imports_0
}
const _hoisted_2 = {
  key: 1,
  src: _imports_1
}
const _hoisted_3 = {
  key: 2,
  class: "white-close",
  src: _imports_1
}
const _hoisted_4 = {
  key: 3,
  class: "button-text"
}
const _hoisted_5 = {
  key: 4,
  class: "button-text"
}
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading_ring = _resolveComponent("loading-ring")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['button', _ctx.buttonClass, _ctx.customClass]),
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hover = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false))
  }, [
    (_ctx.showGoogleImage)
      ? (_openBlock(), _createElementBlock("img", _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.showCloseIcon)
      ? (_openBlock(), _createElementBlock("img", _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.hover && _ctx.closeHover)
      ? (_openBlock(), _createElementBlock("img", _hoisted_3))
      : _createCommentVNode("", true),
    (!_ctx.hover && _ctx.closeHover)
      ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true),
    (!_ctx.closeHover && !_ctx.enableButtonLoader)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, [
          (!_ctx.enableButtonLoader)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.text), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.enableButtonLoader)
      ? (_openBlock(), _createBlock(_component_loading_ring, {
          key: 5,
          enableSmallLoader: ""
        }))
      : _createCommentVNode("", true)
  ], 34))
}