
import { defineComponent, onBeforeMount, onBeforeUpdate, onMounted } from "vue";
import TaskItem from "@/components/TaskItem.vue";
import TaskItemHeader from "@/components/TaskItemHeader.vue";
import AsanaPopup from "@/components/AsanaPopup.vue";
import CheckBox from "@/components/CheckBox.vue";
import { AsanaTask } from "@/models/time_assistant/asana_task";
import { AsanaProject } from "@/models/time_assistant/asana_project";
import searchBar from "@/components/SearchBar.vue";
import loadingStore from "@/store/store";
import { filterByGivenOption } from "@/helpers/FilterHelper";
import router from "@/router";
import chipFilter from "@/components/ChipFilters.vue";
import taskStore from "@/store/store";
import activityStore from "@/store/store";
import { DONE, READY_FOR_PROD } from "@/constants/ChipConstants";
import { CustomFieldsInformations } from "@/models/time_assistant/asana_task";
import store from "@/store/store";
import { UserInterface } from "@/models/sso/user";
import * as localConstants from "@/constants/LocalProjectConstants";

export default defineComponent({
  name: "Task list",
  components: {
    TaskItem,
    TaskItemHeader,
    AsanaPopup,
    CheckBox,
    chipFilter,
    searchBar,
  },
  data() {
    return {
      skipPopup: false,
      integrationCheck: false,
      projectFilterText: this.$t("component-task-list.select-projects"),
      taskFilterText: this.$t("component-task-list.select-filters"),
      statusText: this.$t("component-task-list.show-status"),
      priorityText: this.$t("component-task-list.show-priority"),
      cancelText: this.$t("generic-button-text.capital-cancel"),
      selectedFilter: [] as CustomFieldsInformations[],
      selectedProjectFilter: [] as AsanaProject[],
      showStatusCheck: true,
      showPriorityCheck: true,
      searchedItem: "",
      hideDropdown: true,
      passableProjects: [] as any[],
      noProjectName: this.$t("component-task-list.no-project"),
      showPopup: true,
      noProjectTasks: [] as AsanaTask[],
      otherProject: {
        color: localConstants.COLOR,
        gid: localConstants.GID,
        name: localConstants.NAME,
        firebase_id: localConstants.FIREBASE_ID,
        resource_type: localConstants.RESOURCE_TYPE,
      },
    };
  },
  props: {
    tasks: {
      type: Array,
    },
    projects: {
      type: Array,
    },
    taProjects: {
      type: Array,
    },
    taTasks: {
      type: Array,
    },
  },
  methods: {
    createNewProject() {
      store.dispatch("updateSelectedSidebarElement", "");
      router.push("/dashboard/createProject");
    },
    createNewTaObjective() {
      if (this.taProjects?.length != 0) {
        store.dispatch("updateSelectedSidebarElement", "");
        router.push("/dashboard/createObjective");
      }
    },
    showTaskListDropdown() {
      this.hideDropdown = !this.hideDropdown;
    },
    createNewTask() {
      if (this.tasks?.length != 0 || this.taTasks?.length != 0) {
        router.push("/dashboard/newTimeEntry");
      }
    },
    taskFilterUpdate(selectedOption: CustomFieldsInformations) {
      filterByGivenOption(selectedOption, this.selectedFilter);
    },
    projectFilterUpdate(selectedOption: any) {
      filterByGivenOption(selectedOption, this.selectedProjectFilter);
    },
    toggleStatus() {
      this.showStatusCheck = !this.showStatusCheck;
    },
    togglePriority() {
      this.showPriorityCheck = !this.showPriorityCheck;
    },
    clearprojects() {
      this.selectedProjectFilter = [];
    },
    clearFilters() {
      this.selectedFilter = [];
    },
    searchItem(searchItem: string) {
      this.searchedItem = searchItem;
    },
    async createNewTimeEntry(timeEntry: any) {
      await taskStore.dispatch("updateSelectedTaskState", timeEntry);
      await taskStore.dispatch("updateProceedWithTask", true);
      const taskProject = this.projects?.find(
        (item: any) => item.gid === timeEntry.project_gid
      );
      await activityStore.dispatch("updateTask", timeEntry);
      if (taskProject) {
        await activityStore.dispatch("updateProject", taskProject);
      } else {
        await activityStore.dispatch("updateProject", {} as AsanaProject);
      }
      store.dispatch("updateProceedToTagView", true);
      await activityStore.dispatch("updateRoutedFromTasklist", true);

      router.push("/dashboard/newTimeEntry");
    },
    async viewTimeEntry(timeEntry: any) {
      await taskStore.dispatch("updateSelectedTaskState", timeEntry);
      await activityStore.dispatch("updateTask", timeEntry);
      const allProjects = this.projects?.concat(this.taProjects);
      let taskProject = allProjects?.find(
        (item: any) => item.gid === timeEntry.project_gid
      );
      if (!taskProject) {
        taskProject = {
          name: this.noProjectName,
        };
      }
      await activityStore.dispatch("updateProject", taskProject);
      router.push("/dashboard/timeEntries");
    },
    async checkForNoProjectTasks(taskArray: unknown[] | undefined) {
      const allProjects = this.projects?.concat(this.taProjects);
      taskArray?.forEach((task: any) => {
        if (
          !allProjects?.some(
            (project: any) => project.gid === task?.project_gid
          )
        ) {
          task.project_gid = localConstants.GID;
          this.noProjectTasks.push(task);
        }
      });
    },
    async readCheckFunction() {
      await this.checkForNoProjectTasks(this.tasks);
      await this.checkForNoProjectTasks(this.taTasks);
    },
  },
  computed: {
    enableObjectiveSelection() {
      return this.taProjects?.length === 0;
    },
    hideDivWhileLoading() {
      return loadingStore.getters.getLoadingState;
    },
    isIntegrated() {
      return loadingStore.getters.getAsanaIntegrationState;
    },
    checkIfTaskInfoPresent() {
      let checkIfTasksPresent = this.tasks?.concat(this.taTasks) as AsanaTask[];
      return checkIfTasksPresent.length > 0;
    },
    filter() {
      if (!!this.tasks || !!this.taTasks) {
        let taskData: AsanaTask[] = [];
        let preFilteredTask: AsanaTask[] = [];
        let preFilteredTaskData = this.tasks?.concat(
          this.taTasks
        ) as AsanaTask[];
        if (this.selectedProjectFilter.length > 0) {
          this.selectedProjectFilter.forEach((projectElement) => {
            preFilteredTaskData.forEach((task) => {
              if (projectElement.gid === task.project_gid) {
                preFilteredTask.push(task);
              }
            });
          });
          if (this.selectedFilter.length <= 0) {
            taskData = preFilteredTask;
          }
        } else {
          preFilteredTask = preFilteredTaskData;
        }

        if (this.selectedFilter.length > 0) {
          this.selectedFilter.forEach((element) => {
            preFilteredTask.forEach((task) => {
              if (
                element.value === task.custom_fields?.status?.value ||
                element.value === task.custom_fields?.priority?.value
              ) {
                taskData.push(task);
              }
            });
          });
        }

        if (
          this.selectedFilter.length <= 0 &&
          this.selectedProjectFilter.length <= 0
        ) {
          taskData = preFilteredTaskData;
        }
        let uniqueArray = Array.from(new Set(taskData));

        taskStore.dispatch("updateUniqueTaskListArray", uniqueArray);
        taskData = uniqueArray.sort((a, b) => {
          return new Date(b.due_on).valueOf() - new Date(a.due_on).valueOf();
        });

        if (this.searchedItem !== "") {
          taskData = taskData.filter(
            (item: AsanaTask) =>
              item.task_name
                .toLowerCase()
                .includes(this.searchedItem.toLowerCase()) ||
              item?.parent
                ?.toLowerCase()
                .includes(this.searchedItem.toLowerCase())
          );
        }

        const result = taskData.reduce(function (
          r: { [x: string]: any[] },
          a: { due_on: string | number }
        ) {
          r[a.due_on] = r[a.due_on] || [];
          r[a.due_on].push(a);
          return r;
        },
        Object.create(null));

        return result;
      }

      return [];
    },
    uniqueTagFilter() {
      const newTaskArray: CustomFieldsInformations[] = [];
      const filterableTags = this.tasks as AsanaTask[];
      filterableTags.forEach((tag) => {
        if (tag.custom_fields?.priority?.value) {
          newTaskArray.push(tag.custom_fields.priority);
        }
        if (
          tag.custom_fields?.status?.value &&
          tag.custom_fields?.status?.value !== DONE &&
          tag.custom_fields?.status?.value !== READY_FOR_PROD
        ) {
          newTaskArray.push(tag.custom_fields.status);
        }
      });
      const arr = newTaskArray.filter(
        (value: CustomFieldsInformations, index, self) =>
          index ===
          self.findIndex(
            (t: CustomFieldsInformations) =>
              t.value === value.value && t.color === value.color
          )
      );
      return arr;
    },
    uniqueProjectFilter() {
      let allTasks = this.tasks?.concat(this.taTasks) as AsanaTask[];
      const filteredProjects = this.projects?.filter(
        (project: any) => project.name
      );
      const filteredTaProjects = this.taProjects?.filter(
        (taProjects: any) => taProjects.name
      );
      const allProjects = filteredProjects?.concat(filteredTaProjects);
      allProjects?.push(this.otherProject);

      allProjects?.forEach((project: any) => {
        if (allTasks.some((task) => task.project_gid === project.gid)) {
          this.passableProjects.push(project);
        }
        if (
          !this.passableProjects.some(
            (projects) => projects.name === localConstants.NAME
          )
        ) {
          this.passableProjects.push(this.otherProject);
        }
      });

      taskStore.dispatch(
        "updateUniqueTaskListProjectArrayState",
        this.passableProjects
      );
      return Array.from(new Set(this.passableProjects));
    },
  },
  mounted() {
    const user: UserInterface = store.getters.getUser;
    if (user.disable_asana_popup || store.getters.getAsanaPopupdisabled) {
      this.showPopup = !this.showPopup;
    }
  },
  watch: {
    tasks: [
      {
        handler: "readCheckFunction",
      },
    ],
    taTasks: [
      {
        handler: "readCheckFunction",
        deep: true,
      },
    ],
  },
});
