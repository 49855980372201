
import { defineComponent } from "vue";
import taskStore from "@/store/store";
import projectStore from "@/store/store";
import TaskItem from "@/components/TaskItem.vue";
import { AsanaTask } from "@/models/time_assistant/asana_task";
import { AsanaProject } from "@/models/time_assistant/asana_project";
import userStore from "@/store/store";
import loadingStore from "@/store/store";
import activityStore from "@/store/store";
import { UserInterface } from "@/models/sso/user";
import {
  callAsanaAPI,
  filterTaskUpdate,
  filterProjectUpdate,
} from "@/helpers/AsanaHelper";
import { sortAlphabeticalStringArray } from "@/utils/sorting-utils";
import searchBar from "@/components/SearchBar.vue";
import store from "@/store/store";
import * as timeEntryConstants from "@/constants/TimeEntryConstants";
import * as localTaskConstants from "@/constants/LocalTaskConstants";
import { addToFlowCircleArray } from "@/helpers/TimeEntryHelper";
import { projectsByUser } from "@/service/ProjectService";
import { objectivesByUser } from "@/service/ObjectiveService";
import { TaObjective } from "@/models/time_assistant/ta_objectives";
import { TaProject } from "@/models/time_assistant/ta_project";
import { localProjectFilter, localTaskFilter } from "@/helpers/LocalTaskHelper";

export default defineComponent({
  components: { TaskItem, searchBar },
  emits: ["taskSelected"],
  data() {
    return {
      otherProject: timeEntryConstants.OTHER_PROJECT,
      selected: timeEntryConstants.NOT_SELECTED,
      selectedProjectObject: {} as AsanaProject,
      user: userStore.getters.getUser as UserInterface,
      workspace_gid: 1132657203238371,
      taskGid: "",
      searchedItem: "",
      projectOnMounted: {} as AsanaProject,
      TaskGidOnMounted: "",
      disableSelection: store.getters.getEnableEditing,
      editSwitchingText: this.$t("task-selection.edit-text"),
      otherText: this.$t("task-selection.other"),
      nameOfScrollableText: "",
      displayOther: false,
    };
  },
  async created() {
    if (
      projectStore.getters.getProjectState.length === 0 &&
      projectStore.getters.getTaProjectArrayState.length === 0
    ) {
      loadingStore.dispatch("changeLoadingState", true);
      let asanaTaskList = [] as AsanaTask[];
      let asanaProjectList = [] as AsanaProject[];
      [asanaTaskList, asanaProjectList] = await callAsanaAPI(
        this.workspace_gid,
        this.user.firebase_uid
      );
      filterTaskUpdate(asanaTaskList, asanaProjectList);
      filterProjectUpdate(asanaTaskList, asanaProjectList);

      const taUserProjects = await projectsByUser(this.user.firebase_uid);
      const taUserTasks = await objectivesByUser(this.user.firebase_uid);
      const recievedLocalTasks = taUserTasks as TaObjective[];
      const recievedLocalProjects = taUserProjects as TaProject[];
      localTaskFilter(recievedLocalTasks);
      localProjectFilter(recievedLocalProjects);
      loadingStore.dispatch("changeLoadingState", false);
    }
    this.displayOther = !this.displayOther;
  },
  methods: {
    checkForWidth(event: any) {
      if (event.target.offsetWidth < event.target.scrollWidth) {
        this.nameOfScrollableText = event.explicitOriginalTarget.outerText;
      }
    },
    async toggleProject(index: number, project: AsanaProject) {
      if (!this.disableSelection) {
        this.selected = index;
        this.selectedProjectObject = project;
        await activityStore.dispatch(
          "updateProject",
          this.selectedProjectObject
        );
      }
    },
    async clearProject() {
      if (!this.disableSelection) {
        this.selected = timeEntryConstants.OTHER_PROJECT;
        this.selectedProjectObject = {
          color: localTaskConstants.NO_COLOR,
          gid: localTaskConstants.NO_GID,
          members: [],
          name: localTaskConstants.NO_NAME,
          source: localTaskConstants.TIME_ASSISTANT,
          resource_type: localTaskConstants.PROJECT_TYPE,
          firebase_id: localTaskConstants.NO_FIREBASE_ID,
        };
        await activityStore.dispatch(
          "updateProject",
          this.selectedProjectObject
        );
      }
    },
    selectedProject(index: number, project: any) {
      // if (!this.disableSelection) {
      const passedTask = taskStore.getters.getSelectedTaskState;
      if (this.selected === timeEntryConstants.NOT_SELECTED && passedTask) {
        // -1 stands for not selected
        if (passedTask.project_gid === project.gid) {
          this.selectedProjectObject = project;
          return "selected";
        } else {
          return "";
        }
      } else if (index === this.selected) {
        return "selected";
      } else {
        return "";
      }
      // }
    },
    async selectTask(task: AsanaTask) {
      if (!this.disableSelection) {
        if (this.projectOnMounted !== this.selectedProjectObject) {
          store.commit("resetState");
          await addToFlowCircleArray(timeEntryConstants.TASK_FLOW_CIRCLE_INDEX);
          await activityStore.dispatch(
            "updateProject",
            this.selectedProjectObject
          );
        }

        if (this.taskGid !== task.task_gid) {
          this.taskGid = task.task_gid;
          taskStore.dispatch("updateSelectedTaskState", task);
          activityStore.dispatch("updateTask", task);
          activityStore.dispatch("updateTimeEntryInformations", {});
          store.dispatch("updateProceedToTagView", true);
        }

        this.$emit("taskSelected", true);
      }
    },
    searchItem(searchItem: string) {
      this.searchedItem = searchItem;
    },
  },
  computed: {
    getStoreProjects() {
      let newAsanaProjectArray = projectStore.getters.getProjectState;
      let newprojectArray = projectStore.getters.getTaProjectArrayState;
      let AsanaTaskArray = taskStore.getters.getTaskState as AsanaTask[];
      let TaskArray = taskStore.getters.getTaObjectiveArrayState as AsanaTask[];
      let passableProjects: any[] = [];

      const allProjects = newAsanaProjectArray?.concat(newprojectArray);
      const allTasks = AsanaTaskArray.concat(TaskArray);

      allProjects?.forEach((project: any) => {
        if (allTasks.some((task) => task.project_gid === project.gid)) {
          passableProjects.push(project);
        }
      });
      const sortedArray = sortAlphabeticalStringArray(passableProjects);
      return sortedArray;
    },
    getStoreTasks() {
      let AsanaTaskArray = taskStore.getters.getTaskState as AsanaTask[];
      let TaskArray = taskStore.getters.getTaObjectiveArrayState as AsanaTask[];
      const allTasks = AsanaTaskArray.concat(TaskArray);
      let filteredArray = [] as AsanaTask[];
      if (this.selected === timeEntryConstants.OTHER_PROJECT) {
        let newAsanaProjectArray = projectStore.getters.getProjectState;
        let newprojectArray = projectStore.getters.getTaProjectArrayState;

        const allProjects = newAsanaProjectArray?.concat(newprojectArray);

        allTasks.forEach((task) => {
          if (
            allProjects.findIndex(
              (project: { gid: string }) => project.gid == task.project_gid
            ) == -1
          ) {
            filteredArray.push(task);
          }
        });
      } else {
        allTasks.forEach((task) => {
          if (
            this.selectedProjectObject.gid === task.project_gid &&
            task.project_gid
          ) {
            filteredArray.push(task);
          }
        });
        if (this.searchedItem) {
          filteredArray = filteredArray.filter(
            (item: AsanaTask) =>
              item.task_name
                .toLowerCase()
                .includes(this.searchedItem.toLowerCase()) ||
              item?.parent
                ?.toLowerCase()
                .includes(this.searchedItem.toLowerCase())
          );
        }
        // return filteredArray;
      }
      return filteredArray;
    },
  },
  async mounted() {
    this.clearProject();
    await addToFlowCircleArray(timeEntryConstants.TASK_FLOW_CIRCLE_INDEX);

    const newAsanaProjectArray = projectStore.getters.getProjectState;
    const newprojectArray = projectStore.getters.getTaProjectArrayState;
    const allProjects = newAsanaProjectArray?.concat(newprojectArray);

    if (taskStore.getters.getSelectedTaskState.task_gid) {
      let passedTask = taskStore.getters.getSelectedTaskState;
      let selectedElementIndex = this.getStoreTasks.indexOf(passedTask);

      const projectExists = allProjects.some(
        (passedProjects: any) => passedProjects.gid === passedTask.project_gid
      );

      if (!projectExists) {
        this.selected = timeEntryConstants.OTHER_PROJECT;
      }

      let selectedElementPlacement = this.$refs[
        `taskItem${selectedElementIndex}`
      ] as any;
      if (selectedElementPlacement) {
        let moveTop = selectedElementPlacement.$el.offsetTop;
        (this.$refs.taskSelection as HTMLElement).scrollTop = moveTop;
      }
      this.taskGid = passedTask.task_gid;
      this.$emit("taskSelected", true);
    }
    this.projectOnMounted = this.selectedProjectObject;
    this.TaskGidOnMounted = this.taskGid;
  },
});
