
import { defineComponent } from "vue";
import router from "@/router";
import store from "@/store/store";
import * as pageNames from "@/router/pageNames";

export default defineComponent({
  data() {
    return {
      expandSidebar: false,
      myTasksSelected: pageNames.TIME_ENTRY_INFO_PAGE,
      allEntryListSelected: pageNames.ALL_ENTRIES_PAGE,
      projectManagement: pageNames.PROJECT_MANAGEMENT_PAGE,
      mytasksText: this.$t("component-side-bar-menu.my-tasks"),
      allEntriesText: this.$t("component-side-bar-menu.all-time-entries"),
      projectCreationText: this.$t(
        "component-side-bar-menu.project-management-view"
      ),
    };
  },
  methods: {
    goToTaskList() {
      this.expandSidebar = true;
      store.dispatch("updateSelectedSidebarElement", this.myTasksSelected);
      router.push("/dashboard/tasks");
    },
    goToAllEntryList() {
      this.expandSidebar = true;
      store.dispatch("updateSelectedSidebarElement", this.allEntryListSelected);
      router.push("/dashboard/allEntries");
    },
    goToProjectCreationView() {
      this.expandSidebar = true;
      store.dispatch("updateSelectedSidebarElement", this.projectManagement);
      router.push("/dashboard/projects");
    },
  },
  computed: {
    showSideBar() {
      return store.getters.getShowSideBar;
    },
    selectedString() {
      return store.getters.getSelectedSidebarElement;
    },
  },
});
