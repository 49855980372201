import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withKeys as _withKeys, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-div-input-email" }
const _hoisted_2 = { class: "medium-text-white message" }
const _hoisted_3 = { class: "input-email-div" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_field = _resolveComponent("input-field")!
  const _component_ta_button = _resolveComponent("ta-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.mainTextResetEmail), 1),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_input_field, {
        onKeyup: _withKeys(_ctx.sendEmail, ["enter"]),
        class: "small-text-grey input-field-email",
        placeholder: _ctx.inputFieldEmailPlaceholder,
        type: 'email',
        required: true,
        errorMessage: _ctx.errorMessage,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showErrorMessage(''))),
        onEmptyFieldEmail: _ctx.isInputFieldEmpty,
        onEmitEmail: _ctx.isEmailValid,
        onInput: _ctx.receive
      }, null, 8, ["onKeyup", "placeholder", "errorMessage", "onEmptyFieldEmail", "onEmitEmail", "onInput"]),
      _createVNode(_component_ta_button, {
        class: "confirm-email-button",
        text: _ctx.confirmEmailButtonText,
        backgroundColor: _ctx.backgroundColor,
        enableLoading: _ctx.enableButtonLoader,
        onClick: _ctx.sendEmail
      }, null, 8, ["text", "backgroundColor", "enableLoading", "onClick"])
    ])
  ]))
}