import { UserApi } from "@/infrastructure/http/UserApiResource";

export async function sendEmailResetUserPassword(
  email: string
): Promise<boolean> {
  const correctResponse = "reset password email sent";
  const result = await UserApi.sendEmailResetUserPassword(email);
  if (result === correctResponse) {
    return true;
  } else {
    return false;
  }
}

export async function updateUserPassword(
  password: string,
  id: string
): Promise<boolean> {
  const result = await UserApi.updateUserPassword(password, id);
  return result;
}
