
import { defineComponent } from "vue";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { signInWithGoogle } from "@/service/GoogleService";
import taButton from "@/components/Button.vue";
import inputField from "@/components/InputField.vue";
import { checkEmailAndSignup } from "@/service/RegistrationService";
import MainBlock from "@/components/MainBlock.vue";
import store from "@/store/store";
import router from "@/router";
import loadingStore from "@/store/store";
import snackbar from "@/components/Snackbar.vue";
import { storeUserAndSession } from "@/utils/user-utils";

export default defineComponent({
  name: "App",
  components: { taButton, inputField, MainBlock, snackbar },
  data() {
    return {
      cancelButtonText: this.$t("registration.cancel"),
      buttonText: this.$t("registration.signUp-button"),
      isActive: false,
      errorMessage: "",
      email: "",
      emailValid: false,
      backgroundColor: "orange",
      googleButtonBackgroundColor: "white",
      inputFieldEmailPlaceholder: this.$t(
        "input-field.input-field-email-placeholder"
      ),
      googleButtonText: this.$t("registration.google-button-text"),
      applicationName: "time-assistant-v3",
      applicationBasicRole: "user",
      emptyField: true,
      received: false,
      enableButtonLoader: false,
      showSnackbar: false,
      snackbarMessage: "",
      buttonActionText: this.$t("snackbar.buttonActionText"),
      checkLaCEmail: false,
    };
  },
  methods: {
    async loginWithGoogle() {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      if (auth) {
        loadingStore.dispatch("changeLoadingState", true);
        const login_info = await signInWithGoogle(auth, provider);
        if (login_info) {
          if (typeof login_info !== "string") {
            await storeUserAndSession(login_info);
            router.push("/dashboard/tasks");
          } else {
            loadingStore.dispatch("changeLoadingState", false);
            this.showSnackbar = true;
            this.snackbarMessage = this.$t("login.use-lac");
            setTimeout(() => {
              this.showSnackbar = false;
            }, 4000);
          }
        } else {
          loadingStore.dispatch("changeLoadingState", false);
        }
      }
    },
    receive(data: string) {
      this.email = data;
      this.errorMessage = "";
    },
    checkEmail(data: boolean) {
      this.emailValid = data;
    },
    async checkEmailValidation() {
      if (!this.enableButtonLoader) {
        if (!this.emptyField && this.emailValid) {
          this.enableButtonLoader = true;
          let isEmailValid = await checkEmailAndSignup(
            this.email,
            this.applicationName,
            this.applicationBasicRole
          );
          this.enableButtonLoader = false;
          if (!isEmailValid) {
            this.enableButtonLoader = false;
            this.showErrorMessage(this.$t("registration.email-taken"), true);
          } else if (this.emailValid) {
            this.enableButtonLoader = true;
            await store.dispatch("saveEmail", this.email);
            router.push("/registration/verify");
          }
        } else {
          this.enableButtonLoader = false;
          if (this.checkLaCEmail) {
            this.createSnackBar();
          }
          this.showErrorMessage(
            this.$t("component-input-field.field-required"),
            false
          );
        }
      }
    },
    cancel() {
      loadingStore.dispatch("changeLoadingState", true);
      router.push("/login");
    },
    isInputFieldEmpty(value: boolean) {
      this.received = true;
      this.emptyField = value;
    },
    showErrorMessage(message: string, email_taken: boolean) {
      this.errorMessage = message;
      if (this.received && !email_taken) {
        this.errorMessage = "";
      }
    },
    setLaCCheck(setCheck: boolean) {
      this.checkLaCEmail = setCheck;
    },
    createSnackBar() {
      this.showSnackbar = true;
      this.snackbarMessage = this.$t("component-input-field.not-valid-lac");
      setTimeout(() => {
        this.showSnackbar = false;
      }, 4000);
    },
    closeSnackBar() {
      this.showSnackbar = false;
    },
  },
});
