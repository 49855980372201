import { UserApi } from "@/infrastructure/http/UserApiResource";
import { USER_LOGGED_OUT } from "@/constants/ApiResponseConstants";

export async function logout(): Promise<boolean> {
  const result = await UserApi.logout();
  if (result === USER_LOGGED_OUT) {
    return true;
  } else {
    return false;
  }
}
