import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h4-white verify-main-text" }
const _hoisted_2 = { class: "bottom-verify-text" }
const _hoisted_3 = { class: "default-text-white verify-text" }
const _hoisted_4 = { class: "default-text-white verify-text" }
const _hoisted_5 = { class: "verify-last" }
const _hoisted_6 = { class: "default-text-white verify-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ta_button = _resolveComponent("ta-button")!
  const _component_main_block = _resolveComponent("main-block")!

  return (_openBlock(), _createBlock(_component_main_block, { customClass: 'main-block-registration' }, {
    default: _withCtx(() => [
      _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t("registration-verify.verify-email")), 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("registration-verify.complete-with-email")), 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("registration-verify.check-spam")), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, [
            _createTextVNode(_toDisplayString(_ctx.$t("registration-verify.not-receiving")) + " ", 1),
            _createElementVNode("span", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.sendVerifyEmail())),
              class: "default-text-orange google-highlight"
            }, _toDisplayString(_ctx.$t("registration-verify.resend")), 1)
          ])
        ])
      ]),
      _createVNode(_component_ta_button, {
        text: _ctx.cancelButtonText,
        customClass: 'cancel-button',
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.cancelVerify()))
      }, null, 8, ["text"])
    ]),
    _: 1
  }))
}