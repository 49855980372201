import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "progress-bar-remaining-time-div default-text" }
const _hoisted_2 = { class: "progress-bar-estimated-time-div default-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.estimatedProgressBarTime)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["progress-bar-block-wrapper", { noMarginTop: _ctx.taskItemView, taskItemWidth: _ctx.taskItemView }])
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["progress-bar-text-block", { hideElement: _ctx.taskItemView }])
        }, [
          _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.remainingProgressBarTime), 1),
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.estimatedProgressBarTime), 1)
        ], 2),
        _createElementVNode("div", {
          class: _normalizeClass(["progress-bar-block", { noMarginTop: _ctx.taskItemView }])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["progress-bar", { taskItemWidth: _ctx.taskItemView }])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["progress-bar-percentage", {
            'progress-bar-percentage-completed': _ctx.timeOver,
          }]),
              style: _normalizeStyle('width:' + _ctx.timePercentage + '%;')
            }, null, 6)
          ], 2)
        ], 2)
      ], 2))
    : _createCommentVNode("", true)
}