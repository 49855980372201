export default {
  "registration": {
    "welcome-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inizia a seguire i tuoi progressi oggi!"])},
    "policy-message-beginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscrivendomi, accetto "])},
    "policy-message-first-highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["l'Informativa sulla privacy"])},
    "policy-message-middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" e i"])},
    "policy-message-second-highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Termini di servizio"])},
    "policy-message-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" di Time Assistant."])},
    "signUp-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iscriviti"])},
    "email-taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'e-mail è già stata presa"])},
    "google-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua con Google"])}
  },
  "registration-verify": {
    "verify-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, verifica il tuo indirizzo email"])},
    "continue-google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua con Google per verificare"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oppure"])},
    "complete-with-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completa il processo di iscrizione tramite l'email che abbiamo inviato al tuo indirizzo email"])},
    "not-receiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ricevuto l'email?"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinvia l'email."])},
    "google-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua con Google"])}
  },
  "registration-end": {
    "end-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei quasi arrivato!"])},
    "email-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ti stai iscrivendo come "])},
    "name-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qual è il tuo nome completo?"])},
    "name-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "create-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea una password"])},
    "password-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La password deve avere almeno 8 caratteri"])},
    "re-enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reinserire la password"])},
    "continue-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continua"])},
    "verification-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di utilizzare il link di verifica"])},
    "passwords-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrambi i campi della password devono corrispondere"])}
  },
  "component-main-footer": {
    "contacts-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTATTI"])},
    "privacy-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMATIVA SULLA PRIVACY"])},
    "terms-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TERMINI DI SERVIZIO"])}
  },
  "component-input-field": {
    "field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo è richiesto"])},
    "valid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo deve essere un'email valida"])},
    "valid-password-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password troppo corta"])},
    "valid-password-letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa almeno una lettera A_Z, a-z"])},
    "valid-password-digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa almeno una cifra 0-9"])},
    "valid-password-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min 8 simboli (A-Z, a-z, 0-9)"])}
  },
  "component-main-header": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnettiti"])}
  },
  "component-input-email-reset-password": {
    "main-text-reset-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir votre email pour réinitialiser votre mot de passe"])},
    "confirm-email-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFERMA EMAIL"])},
    "cancel-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INDIETRO"])}
  },
  "component-reset-password": {
    "new-password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
    "re-enter-password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-inserisci la password"])},
    "confirm-new-password-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFERMA LA NUOVA PASSWORD"])},
    "compare-passowrd-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrambe le password devono corrispondere"])}
  },
  "login": {
    "welcome-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messaggio di Benvenuto"])},
    "google-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login con Google"])},
    "login-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN"])},
    "forgot-password-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Dimenticata?"])},
    "no-account-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai un account?"])},
    "registration-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLICCA QUI PER REGISTRARTI"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informativa sulla Privacy"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["e"])},
    "terms-of-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termini di Servizio"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sono applicati"])}
  },
  "input-field": {
    "input-field-email-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "input-field-password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
  },
  "forgotten-password-view": {
    "forgotten-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password Dimenticata"])}
  },
  "snackbar": {
    "wrong-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credenziali errate inserite."])},
    "email-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il link per il reset della password è stato inviato alla tua email."])},
    "send-email-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa email non è collegata a nessuno dei nostri account."])},
    "update-password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errore nell'aggiornamento della password."])},
    "update-password-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password aggiornata correttamente."])},
    "send-email-error-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa è andato storto durante l'invio dell'email."])},
    "verification-email-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email di verifica inviata."])},
    "buttonActionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])},
    "user-registration-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrazione dell'utente non riuscita."])},
    "user-registration-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utente registrato correttamente."])}
  },
  "tooltip": {
    "show-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra password"])},
    "hide-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nascondi password"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifiche"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciao, "])},
    "plus-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea time entry"])},
    "status-chips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stato"])},
    "priority-chips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorità"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggiori informazioni"])},
    "editIconTitleTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica la task selezionata"])},
    "editIconTagsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica i tag della time entry"])}
  },
  "reset-password-link-exception": {
    "main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo link per il reset della password è invalido o scaduto"])},
    "receive-link-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RICEVI UN NUOVO LINK"])},
    "return-login-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RITORNA AL LOGIN"])}
  },
  "registration-link-exception": {
    "invalid-link-main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo link di verifica è invalido o scaduto"])},
    "invalid-link-first-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi ricevere un nuovo link di verifica alla tua email"])},
    "invalid-link-second-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo link di verifica è valido solo per"])},
    "invalid-link-third-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" giorni."])},
    "invalid-link-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RICEVI UN NUOVO LINK"])},
    "already-registered-user-main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa e-mail è già registrata"])},
    "already-registered-user-first-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua e-mail"])},
    "already-registered-user-second-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["è stata già registrata in questo sistema"])},
    "already-registered-user-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RITORNA AL LOGIN"])}
  },
  "tag-selection": {
    "first-section-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHI"])},
    "second-section-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COSA"])},
    "first-checkbox-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo ruolo è stato selezionato in base al tuo ruolo di default per questo progetto"])},
    "second-checkbox-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imposta questo come ruolo di default per questo progetto"])}
  },
  "multiple-checkbox-select": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona azioni"])},
    "label-select-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona un ruolo"])},
    "labelWithActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Azioni selezionate: "])}
  },
  "profiles": {
    "TECHNICAL_EXPERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Expert"])},
    "DESIGN_THINKER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Thinker"])},
    "PROJECT_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Owner"])},
    "BUSINESS_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Developer"])},
    "LEADER_COACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader - Coach"])}
  },
  "time-selector": {
    "hour-symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
    "minute-symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])}
  },
  "DateAndTime": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATA"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEMPO"])},
    "time-suggestion-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I suggerimenti dei tempi sono selezionati in base alle tue entry precedenti"])}
  },
  "remaining-time": {
    "not-ended-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rimanenti"])},
    "ended-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oltre la stima"])}
  },
  "required": {
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" *"])}
  },
  "component-new-task-view": {
    "new-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova time entry"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INDIETRO"])},
    "select-task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona una task"])},
    "entry-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time entry tags"])},
    "date-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data e ora"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completamento"])},
    "cancel-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNULLA"])},
    "next-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVANTI"])},
    "save-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SALVA"])},
    "save-and-continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SALVA E CREA NUOVA"])}
  },
  "not-supported-version": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie per aver scelto Time Assistant"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Al momento non supportiamo questa versione web. Perfavore apri Time Assistant nle tuo desktop browser."])}
  }
}