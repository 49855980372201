
import { defineComponent } from "vue";
import { projectConstants } from "@/constants/ChipConstants";

export default defineComponent({
  props: {
    chipContent: {
      type: String,
      default: "",
    },
    filterColor: {
      type: String,
      default: "",
    },
    projectColor: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    projectCircleColor() {
      if (this.project) {
        return projectConstants[this.projectColor];
      }
      return "";
    },
    chipStyle() {
      if (!this.disabled) {
        if (this.project) {
          return "project-selected";
        }
        return this.filterColor;
      }
      return "";
    },
  },
});
