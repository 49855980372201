export default {
  "registration": {
    "welcome-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencez à suivre vos progrès dès aujourd'hui!"])},
    "policy-message-beginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En m'inscrivant, j'accepte la "])},
    "policy-message-first-highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "policy-message-middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et les"])},
    "policy-message-second-highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Conditions de service"])},
    "policy-message-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" de Time Assistant"])},
    "signUp-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s'inscrire"])},
    "email-taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email est déjà pris"])},
    "google-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer avec Google"])}
  },
  "registration-verify": {
    "verify-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre adresse e-mail"])},
    "continue-google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer avec Google pour vérifier"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou"])},
    "complete-with-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminez le processus d'inscription via l'e-mail que nous avons envoyé à votre adresse e-mail."])},
    "not-receiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas reçu d'e-mail ?"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer l'e-mail."])},
    "google-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer avec Google"])}
  },
  "registration-end": {
    "end-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous y êtes presque !"])},
    "email-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous vous inscrivez en tant que "])},
    "name-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel est votre nom complet ?"])},
    "name-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "create-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe"])},
    "password-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter 8 caractères ou plus"])},
    "re-enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-entrer le mot de passe"])},
    "continue-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "verification-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez utiliser le lien de vérification"])},
    "passwords-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les deux champs du mot de passe doivent correspondre"])}
  },
  "component-main-footer": {
    "contacts-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACTS"])},
    "privacy-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POLITIQUE DE CONFIDENTIALITÉ"])},
    "terms-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONDITIONS DE SERVICE"])}
  },
  "component-input-field": {
    "field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire"])},
    "valid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ doit être une adresse électronique valide"])},
    "valid-password-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe trop court"])},
    "valid-password-letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez au moins une lettre A_Z, a-z"])},
    "valid-password-digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez au moins un chiffre 0-9"])},
    "valid-password-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min 8 symboles (A-Z, a-z, 0-9)"])}
  },
  "component-main-header": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])}
  },
  "component-input-email-reset-password": {
    "main-text-reset-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address to reset your password"])},
    "confirm-email-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFIRMEZ EMAIL"])},
    "cancel-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNULER"])}
  },
  "component-reset-password": {
    "new-password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "re-enter-password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez à nouveau mot de passe"])},
    "confirm-new-password-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFIRMER LE NOUVEAU MOT DE PASSE"])},
    "compare-passowrd-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les deux champs de mot de passe doivent correspondre"])}
  },
  "login": {
    "welcome-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message de bienvenue"])},
    "google-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login avec Google"])},
    "login-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN"])},
    "forgot-password-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
    "no-account-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de compte?"])},
    "registration-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLIQUEZ ICI POUR VOUS INSCRIRE"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La Politique de Confidentialité"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et"])},
    "terms-of-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les Conditions d'Utilisation"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["s'appliquent"])}
  },
  "input-field": {
    "input-field-email-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "input-field-password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
  },
  "forgotten-password-view": {
    "forgotten-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])}
  },
  "snackbar": {
    "wrong-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvaises informations d'identification insérées."])},
    "email-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien de réinitialisation du mot de passe a été envoyé à votre adresse e-mail"])},
    "send-email-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet e-mail n'est lié à aucun de nos comptes."])},
    "update-password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la mise à jour du mot de passe."])},
    "update-password-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe mis à jour correctement."])},
    "send-email-error-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors de l'envoi de l'e-mail de vérification."])},
    "verification-email-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'email de vérification a été envoyé."])},
    "buttonActionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "user-registration-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'enregistrement de l'utilisateur."])},
    "user-registration-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur s'est enregistré correctement."])}
  },
  "tooltip": {
    "show-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer le mot de passe"])},
    "hide-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masquer le mot de passe"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salut, "])},
    "plus-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["créer une entrée de temps"])},
    "status-chips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "priority-chips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'informations"])},
    "editIconTitleTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la tâche sélectionnée"])},
    "editIconTagsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les balises d'entrée de temps"])}
  },
  "reset-password-link-exception": {
    "main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien de réinitialisation de votre mot de passe n'est pas valide ou a expiré."])},
    "receive-link-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECEVOIR UN NOUVEAU LIEN"])},
    "return-login-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RETOUR À LA CONNEXION"])}
  },
  "registration-link-exception": {
    "invalid-link-main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre lien de vérification n'est pas valide ou a expiré."])},
    "invalid-link-first-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez recevoir un nouveau lien de vérification dans votre e-mail."])},
    "invalid-link-second-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre lien de vérification n'est valable que pour"])},
    "invalid-link-third-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" jours."])},
    "invalid-link-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECEVOIR UN NOUVEAU LIEN"])},
    "already-registered-user-main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet e-mail est déjà enregistré"])},
    "already-registered-user-first-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre e-mail"])},
    "already-registered-user-second-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a déjà été enregistré dans ce système"])},
    "already-registered-user-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RETOUR À LA CONNEXION"])}
  },
  "tag-selection": {
    "first-section-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OMS"])},
    "second-section-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CE QUE"])},
    "first-checkbox-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre rôle a été sélectionné en fonction de votre rôle de projet par défaut"])},
    "second-checkbox-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définissez ceci comme rôle par défaut pour ce projet"])}
  },
  "multiple-checkbox-select": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner les actions"])},
    "label-select-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un rôle"])},
    "labelWithActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions sélectionnées: "])}
  },
  "profiles": {
    "TECHNICAL_EXPERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Expert"])},
    "DESIGN_THINKER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Thinker"])},
    "PROJECT_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Owner"])},
    "BUSINESS_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Developer"])},
    "LEADER_COACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader - Coach"])}
  },
  "time-selector": {
    "hour-symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
    "minute-symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])}
  },
  "DateAndTime": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEMPS"])},
    "time-suggestion-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les suggestions de temps sont sélectionnées en fonction de vos entrées précédentes"])}
  },
  "remaining-time": {
    "not-ended-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["restant"])},
    "ended-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["surestimation"])}
  },
  "required": {
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" *"])}
  },
  "component-new-task-view": {
    "new-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau time entry"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARRIÈRE"])},
    "select-task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une tâche"])},
    "entry-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time entry tags"])},
    "date-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date et l'heure"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achèvement"])},
    "cancel-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANNULER"])},
    "next-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUIVANT"])},
    "save-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENREGISTRER"])},
    "save-and-continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENREGISTRER ET CRÉER UN NOUVEAU"])}
  },
  "not-supported-version": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci d'avoir choisi Time Assistant"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous ne prenons actuellement pas en charge cette version Web. Veuillez ouvrir l'assistant de temps dans votre navigateur de bureau."])}
  }
}