import { UserApi } from "@/infrastructure/http/UserApiResource";

export async function getMyAsanaAuth(): Promise<string> {
  const asanaUrl = await UserApi.getMyAsanaAuth();
  return asanaUrl;
}

export async function getAsanaAccessToken(
  code: string,
  uid: string
): Promise<string> {
  const accessTokens = await UserApi.getAsanaAccessToken(code, uid);
  return accessTokens;
}

export async function disableAsanaPopup(
  firebase_uid: string,
  document_id: string,
  disable_asana_popup: boolean
) {
  const responseMessage = await UserApi.disableAsanaPopup(
    firebase_uid,
    document_id,
    disable_asana_popup
  );
  return responseMessage;
}
