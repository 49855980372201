import { Module } from "vuex";
import { RootState } from "@/store/store";
import { AsanaTask } from "@/models/time_assistant/asana_task";
import { CustomFieldsInformations } from "@/models/time_assistant/asana_task";

export interface taskState {
  taskArray: [];
  selectedTask: AsanaTask;
  proceedWithTask: boolean;
  allCustomFields?: CustomFieldsInformations[];
  showTaskInfo: boolean;
  uniqueTaskListArray: [];
  taObjectiveArray: [];
  fromEntryView: boolean;
}

export const taskStore: Module<taskState, RootState> = {
  state: {
    taskArray: [],
    selectedTask: ({} as AsanaTask) || undefined,
    proceedWithTask: false,
    allCustomFields: [],
    showTaskInfo: false,
    uniqueTaskListArray: [],
    taObjectiveArray: [],
    fromEntryView: false,
  },
  mutations: {
    setTaskState(state, payload: []) {
      state.taskArray = payload;
    },
    setSelectedTaskState(state, payload: AsanaTask) {
      state.selectedTask = payload;
    },
    setproceedState(state, payload: boolean) {
      state.proceedWithTask = payload;
    },
    setAllCustomFields(state, payload: CustomFieldsInformations[]) {
      state.allCustomFields = payload;
    },
    setShowTaskInfo(state, payload: boolean) {
      state.showTaskInfo = payload;
    },
    setUniqueTaskListArray(state, payload: []) {
      state.uniqueTaskListArray = payload;
    },
    setTaObjectiveArrayState(state, payload: []) {
      state.taObjectiveArray = payload;
    },
    setFromEntryViewState(state, payload: boolean) {
      state.fromEntryView = payload;
    },
  },
  actions: {
    updateTaskState(context, taskArray: []) {
      context.commit("setTaskState", taskArray);
    },
    updateSelectedTaskState(context, selectedTask: AsanaTask[]) {
      context.commit("setSelectedTaskState", selectedTask);
    },
    updateProceedWithTask(context, proceedWithTask: boolean) {
      context.commit("setproceedState", proceedWithTask);
    },
    updateAllCustomFields(
      context,
      allCustomFields: CustomFieldsInformations[]
    ) {
      context.commit("setAllCustomFields", allCustomFields);
    },
    updateShowTaskInfo(context, showTaskInfo: boolean) {
      context.commit("setShowTaskInfo", showTaskInfo);
    },
    updateUniqueTaskListArray(context, uniqueTaskListArray: []) {
      context.commit("setUniqueTaskListArray", uniqueTaskListArray);
    },
    updateTaObjectiveArrayState(context, taObjectiveArray: []) {
      context.commit("setTaObjectiveArrayState", taObjectiveArray);
    },
    updateFromEntryViewState(context, fromEntryView: boolean) {
      context.commit("setFromEntryViewState", fromEntryView);
    },
  },
  getters: {
    getTaskState(state) {
      return state.taskArray;
    },
    getSelectedTaskState(state) {
      return state.selectedTask;
    },
    getProceedWithTask(state) {
      return state.proceedWithTask;
    },
    getAllCustomFields(state) {
      return state.allCustomFields;
    },
    getShowTaskInfo(state) {
      return state.showTaskInfo;
    },
    getUniqueTaskListArray(state) {
      return state.uniqueTaskListArray;
    },
    getTaObjectiveArrayState(state) {
      return state.taObjectiveArray;
    },
    getFromEntryViewState(state) {
      return state.fromEntryView;
    },
  },
};
