
import { defineComponent } from "vue";
import { format } from "date-fns";

export default defineComponent({
  name: "Task item header",
  components: {},
  props: {
    taskDate: {
      type: String,
    },
    infoDate: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  computed: {
    checkDate() {
      let dateToString = new Date(this.taskDate as string);
      if (this.infoDate) {
        return format(dateToString, "MMMM d");
      }
      return this.taskDate === "null"
        ? this.$t("component-task-item-header.not-set")
        : format(dateToString, "MMM dd, yyyy");
    },
  },
});
