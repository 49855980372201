import { Module } from "vuex";
import { RootState } from "@/store/store";

export interface projectState {
  projectArray: [];
  uniqueTaskListProjectArray: [];
  taProjectArray: [];
}

export const projectStore: Module<projectState, RootState> = {
  state: {
    projectArray: [],
    uniqueTaskListProjectArray: [],
    taProjectArray: [],
  },
  mutations: {
    setProjectState(state, payload: []) {
      state.projectArray = payload;
    },
    setUniqueTaskListProjectArrayState(state, payload: []) {
      state.uniqueTaskListProjectArray = payload;
    },
    setTaProjectArrayState(state, payload: []) {
      state.taProjectArray = payload;
    },
  },
  actions: {
    updateProjectState(context, projectArray: []) {
      context.commit("setProjectState", projectArray);
    },
    updateUniqueTaskListProjectArrayState(
      context,
      uniqueTaskListProjectArray: []
    ) {
      context.commit(
        "setUniqueTaskListProjectArrayState",
        uniqueTaskListProjectArray
      );
    },
    updateTaProjectArrayState(context, taProjectArray: []) {
      context.commit("setTaProjectArrayState", taProjectArray);
    },
  },
  getters: {
    getProjectState(state) {
      return state.projectArray;
    },
    getUniqueTaskListProjectArrayState(state) {
      return state.uniqueTaskListProjectArray;
    },
    getTaProjectArrayState(state) {
      return state.taProjectArray;
    },
  },
};
