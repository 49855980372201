import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["loading-ring", { 'small-loading-ring': _ctx.enabler }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{ 'smaller-loader': _ctx.enabler }, _ctx.customColor])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass([{ 'smaller-loader': _ctx.enabler }, _ctx.customColor])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass([{ 'smaller-loader': _ctx.enabler }, _ctx.customColor])
    }, null, 2),
    _createElementVNode("div", {
      class: _normalizeClass([{ 'smaller-loader': _ctx.enabler }, _ctx.customColor])
    }, null, 2)
  ], 2))
}