
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    enableSmallLoader: {
      type: Boolean,
      required: false,
      default: false,
    },
    customColor: {
      type: String,
    },
  },
  computed: {
    enabler() {
      return this.enableSmallLoader;
    },
  },
});
