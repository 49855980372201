
import { defineComponent } from "vue";
import store from "@/store/store";
import router from "@/router";
import {
  deleteTimeEntryActivities,
  getTimeEntryActivities,
} from "@/service/TimeEntryService";
import timeEntryInfoItem from "@/components/TimeEntryInfoComponents/TimeEntryInfoItem.vue";
import progressBar from "@/components/TimeEntryInfoComponents/TimeEntryProgressBar.vue";
import {
  ActivityInformationsArray,
  ActivityInformations,
} from "@/models/time_assistant/activity-item";
import TaskItemHeader from "@/components/TaskItemHeader.vue";
import { format } from "date-fns";
import loadingStore from "@/store/store";
import { AsanaTask } from "@/models/time_assistant/asana_task";
import { AsanaProject } from "@/models/time_assistant/asana_project";
import * as timeEntryConstants from "@/constants/TimeEntryConstants";
import { TimeEntry } from "@/models/time_assistant/time_entry";
import { saveTimeEntryInformations } from "@/service/TimeEntryService";
import snackbar from "@/components/Snackbar.vue";
import TimeEntryDeletePopup from "@/components/GenericComponents/DeletePopup.vue";
import uploadPopup from "@/components/TimeEntryInfoComponents/TimEntryUploadView.vue";
import { totalTimeString } from "@/utils/timeUtils";
import * as localTaskConstants from "@/constants/LocalTaskConstants";
export default defineComponent({
  components: {
    timeEntryInfoItem,
    TaskItemHeader,
    snackbar,
    TimeEntryDeletePopup,
    uploadPopup,
    progressBar,
  },
  data() {
    return {
      showAsanaLogo: true,
      showUploadButton: false,
      showCreateEntryButton: true,
      timeEntryInformationArray: {} as ActivityInformationsArray,
      activityList: [] as ActivityInformations[],
      visibleActivityList: [] as ActivityInformations[],
      hideNoEntries: false,
      task: {} as AsanaTask,
      project: {} as AsanaProject,
      lastDateResult: {},
      displayedCombinedHours: "",
      scrollValue: 0,
      buttonActionText: this.$t("snackbar.buttonActionText"),
      emailNotSentText: this.$t("snackbar.send-email-error"),
      emailSentText: this.$t("snackbar.email-sent"),
      snackbarCustomClass: "snackbar-div",
      showSnackbar: false,
      snackbarMessage: "",
      duplicationMessage: this.$t("snackbar.duplication-created"),
      deletionMessage: this.$t("snackbar.entry-deleted"),
      combinedTime: 0,
      noDueDate: this.$t("component-entry-info-view.no-due"),
      showDeleteWarning: false,
      removableEntry: {} as ActivityInformations,
      add_magin: false,
      timeEntryWarningText: this.$t(
        "component-entry-info-view-popup.warning-message"
      ),
      timeEntryConfirmDeleteText: this.$t(
        "component-entry-info-view-popup.delete-entry"
      ),
      timeEntryCancelDeleteText: this.$t(
        "component-entry-info-view-popup.keep-entry"
      ),
      showUpload: false,
    };
  },
  methods: {
    async createNewEntry() {
      await store.dispatch("updateSelectedTaskState", this.task);
      await store.dispatch("updateProceedWithTask", true);
      await store.dispatch("updateTask", this.task);
      await store.dispatch("updateProject", this.project);

      await store.dispatch("updateRoutedFromTasklist", true);
      await store.dispatch("updateFromEntryViewState", true);

      store.dispatch("updateProceedToTagView", true);
      router.push("/dashboard/newTimeEntry");
    },
    async showUploadPopup() {
      await store.dispatch("updateTask", this.task);
      await store.dispatch("updateProject", this.project);

      await store.dispatch("updateRoutedFromTasklist", true);
      await store.dispatch("updateFromEntryViewState", true);
      this.showUpload = !this.showUpload;
    },
    closeUplodad() {
      this.showUpload = !this.showUpload;
    },
    addMargin() {
      this.add_magin = true;
    },
    async editEntry(entry: ActivityInformations) {
      if (entry.value[0].length > 0) {
        await store.dispatch("updateConfirmValueCheck", true);
      }
      await store.dispatch("updateTaskValue", entry.value[0]);

      await store.dispatch("updateEnableEditing", true);
      await store.dispatch("updateProceedWithTask", true);
      await store.dispatch("updateRoleID", entry.roleID);
      await store.dispatch("updateActions", entry.actions);
      await store.dispatch("updateEditableTechnologies", entry.technology);
      await store.dispatch("updateTechnologyArray", entry.technology);

      await store.dispatch("updateDateValue", entry.createdAt);
      await store.dispatch("updateProfileName", entry.role);
      await store.dispatch("updateEditableID", entry.ID);
      await store.dispatch("updateCustonTaskValue", entry.value[1]);

      const hours = Math.floor(entry.spent_time_min / 60);
      const minutes = entry.spent_time_min % 60;

      await store.dispatch("updateHour", hours);
      await store.dispatch("updateMinutes", minutes);

      await store.dispatch("updateShowCompletionInfo", true);
      await store.dispatch("updateShowTagSelectionInfo", true);

      router.push("/dashboard/newTimeEntry");
    },
    createSnackBar(message: string) {
      this.showSnackbar = true;
      this.snackbarMessage = message;
      setTimeout(() => {
        this.showSnackbar = false;
      }, 4000);
    },
    closeSnackBar() {
      this.showSnackbar = false;
    },
    async leavePage() {
      store.commit("resetState");
      await store.dispatch("updateShowTaskInfo", false);
      router.push("/dashboard/tasks");
    },
    goToAsana() {
      if (!this.project.gid) {
        window.open(
          `${process.env.VUE_APP_ASANA_TASK_LINK}${0}/${this.task.task_gid}/f`,
          "_blank"
        );
      } else {
        window.open(
          `${process.env.VUE_APP_ASANA_TASK_LINK}${this.project.gid}/${this.task.task_gid}/f`,
          "_blank"
        );
      }
    },
    addActivities() {
      if (
        this.activityList.length <
        timeEntryConstants.TIME_ENTRY_ACTIVITY_MINIMUM_VALUE_CHECK_LENGTH
      ) {
        this.scrollValue = this.activityList.length;
      }
      for (var i = 0; i < this.scrollValue; i++) {
        this.visibleActivityList.push(this.activityList[0]);
        this.activityList.shift();
      }
    },
    onScroll(event: any) {
      //Adds more visible activities as you scroll
      if (
        event.target.offsetHeight + event.target.scrollTop >=
        event.target.scrollHeight
      ) {
        this.addActivities();
      }
    },

    saveLastResult(result: any) {
      this.lastDateResult = result;
    },
    setActiveEntries() {
      if (
        this.activityList.length >=
        timeEntryConstants.TIME_ENTRY_ACTIVITY_MINIMUM_HIGHEST_LENGTH
      ) {
        this.scrollValue =
          timeEntryConstants.TIME_ENTRY_ACTIVITY_MAXIMUM_VALUE_LENGTH;
      } else if (
        this.activityList.length ===
        timeEntryConstants.TIME_ENTRY_ACTIVITY_MINIMUM_VALUE_LENGTH
      ) {
        this.scrollValue =
          timeEntryConstants.TIME_ENTRY_ACTIVITY_MINIMUM_VALUE_LENGTH;
      } else {
        this.scrollValue = this.activityList.length;
      }
      this.addActivities();
      this.scrollValue = timeEntryConstants.TIME_ENTRY_ACTIVITY_SCROLL_VALUE;
    },
    async updateTaskList(entry: ActivityInformations, subtract: boolean) {
      const compareableTask = store.getters.getTask;
      const AsanaTaskArray = store.getters.getTaskState;
      let taskArray = [] as AsanaTask[];

      const isFound = AsanaTaskArray.some(
        (element: any) => compareableTask.task_gid === element.task_gid
      );

      isFound
        ? (taskArray = store.getters.getTaskState)
        : (taskArray = store.getters.getTaObjectiveArrayState);

      const hours = Math.floor(entry.spent_time_min / 60);
      const minutes = entry.spent_time_min % 60;
      const index = taskArray.findIndex((object: AsanaTask) => {
        return object.task_gid === compareableTask.task_gid;
      });
      if (subtract) {
        if (index !== -1) {
          taskArray[index].totalTimeSpentMin =
            compareableTask.totalTimeSpentMin -= hours * 60 + minutes;
        }
      } else {
        if (index !== -1) {
          taskArray[index].totalTimeSpentMin =
            compareableTask.totalTimeSpentMin += hours * 60 + minutes;
        }
      }

      isFound
        ? await store.dispatch("updateTaskState", taskArray)
        : await store.dispatch("updateTaObjectiveArrayState", taskArray);
    },
    async duplicateEntry(entry: ActivityInformations) {
      loadingStore.dispatch("changeLoadingState", true);

      const timeEntry: TimeEntry = {
        project: store.getters.getProject,
        task: store.getters.getTask,
        role: entry.roleID as string,
        actions: entry.actions,
        value: entry.value,
        newTechnologies: store.getters.getNewTechnologies,
        technologies: entry.technology,
        user_uid: store.getters.getUser.firebase_uid,
        hour: Math.floor(entry.spent_time_min / 60),
        minutes: entry.spent_time_min % 60,
        createdWith: "WEB_APP",
        date: new Date(),
      };

      let newEntry = JSON.parse(JSON.stringify(entry));
      newEntry.createdAt = format(new Date(), "yyyy-MM-dd");

      let newIdValue = await saveTimeEntryInformations(timeEntry);
      //Assigning an id value of the newly created entry from database to user view for deleting function
      newEntry.ID = newIdValue;

      this.updateTaskList(entry, false);

      this.combinedTime = this.combinedTime + newEntry.spent_time_min;
      this.displayedCombinedHours = totalTimeString(this.combinedTime);
      this.setActiveEntries();
      this.visibleActivityList.unshift(newEntry);

      const estimated_time = this.task?.custom_fields
        ?.estimated_time_hours as number;
      const time_spent = this.task?.totalTimeSpentMin;
      (this.$refs.progressBar as any).remainingTime(estimated_time, time_spent);

      loadingStore.dispatch("changeLoadingState", false);
      this.createSnackBar(this.duplicationMessage);
    },
    async deleteEntry(entry: ActivityInformations) {
      if (!this.showDeleteWarning) {
        this.showDeleteWarning = !this.showDeleteWarning;
        this.removableEntry = entry;
      } else {
        this.showDeleteWarning = !this.showDeleteWarning;
        loadingStore.dispatch("changeLoadingState", true);

        await deleteTimeEntryActivities(
          entry.ID,
          this.task.firebase_id,
          entry.spent_time_min.toString()
        );

        this.combinedTime = this.combinedTime - entry.spent_time_min;
        this.displayedCombinedHours = totalTimeString(this.combinedTime);
        this.setActiveEntries();

        this.updateTaskList(entry, true);

        this.visibleActivityList = this.visibleActivityList.filter(function (
          el
        ) {
          return el.ID != entry.ID;
        });
        const estimated_time = this.task?.custom_fields
          ?.estimated_time_hours as number;

        const time_spent = this.task?.totalTimeSpentMin;
        (this.$refs.progressBar as any).remainingTime(
          estimated_time,
          time_spent
        );

        this.hideNoEntries = !this.visibleActivityList.length;

        this.hideNoEntries = !this.visibleActivityList.length;

        loadingStore.dispatch("changeLoadingState", false);
        this.createSnackBar(this.deletionMessage);
      }
    },
    async deleteSelectedEntry() {
      await this.deleteEntry(this.removableEntry);
    },
    cancelDeletionOfEntry() {
      this.showDeleteWarning = !this.showDeleteWarning;
    },
  },
  computed: {
    sortedDate() {
      let sortActivityList: ActivityInformations[] = [];

      sortActivityList = this.visibleActivityList;
      const result = sortActivityList.reduce(
        function (
          r: { [x: string]: any[] },
          a: { createdAt: string | number }
        ) {
          r[format(new Date(a.createdAt), "yyyy-MM-dd")] =
            r[format(new Date(a.createdAt), "yyyy-MM-dd")] || [];
          r[format(new Date(a.createdAt), "yyyy-MM-dd")].push(a);
          return r;
        },

        Object.create(null)
      );
      this.saveLastResult(result);
      return result;
    },
    dueDate(): string {
      return this.task.due_on
        ? format(new Date(this.task.due_on), "dd.MM.yyyy")
        : this.noDueDate;
    },
  },
  async mounted() {
    store.dispatch("updateSelectedSidebarElement", "");
    loadingStore.dispatch("changeLoadingState", true);
    if (!store.getters.getProject) {
      this.leavePage();
    }
    this.task = store.getters.getTask;
    this.project = store.getters.getProject;
    if (this.project.source === localTaskConstants.TIME_ASSISTANT) {
      this.showAsanaLogo = !this.showAsanaLogo;
      this.showUploadButton = true;
    }
    if (!this.project.gid) {
      this.showCreateEntryButton = !this.showCreateEntryButton;
      this.showAsanaLogo = !this.showAsanaLogo;
    }
    if (store.getters.getTask.firebase_id) {
      this.timeEntryInformationArray = await getTimeEntryActivities(
        store.getters.getTask.firebase_id
      );
      this.activityList = this.timeEntryInformationArray.activities_list;
      if (this.activityList.length <= 0) {
        this.hideNoEntries = true;
      }
    } else {
      this.hideNoEntries = true;
    }
    this.activityList.forEach(
      (element: ActivityInformations) =>
        (this.combinedTime = this.combinedTime + element.spent_time_min)
    );
    this.displayedCombinedHours = totalTimeString(this.combinedTime);
    this.setActiveEntries();

    const estimated_time = this.task?.custom_fields
      ?.estimated_time_hours as number;

    let time_spent = this.task?.totalTimeSpentMin || 0;
    (this.$refs.progressBar as any).remainingTime(estimated_time, time_spent);

    loadingStore.dispatch("changeLoadingState", false);
  },
});
