export default {
  "registration": {
    "welcome-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start tracking your progress today!"])},
    "policy-message-beginning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By signing up, I agree to the "])},
    "policy-message-first-highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "policy-message-middle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" and"])},
    "policy-message-second-highlight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Terms of Service"])},
    "policy-message-end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" of Time Assistant."])},
    "signUp-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign up"])},
    "email-taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email is already taken"])},
    "google-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Google"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL"])}
  },
  "registration-verify": {
    "verify-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please verify your email address"])},
    "continue-google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Google to verify"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Or"])},
    "complete-with-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete sign up process via the email we sent to your email address."])},
    "not-receiving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Didn’t receive an email?"])},
    "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resend email."])},
    "google-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with Google"])},
    "check-spam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure to check your spam mail"])}
  },
  "registration-end": {
    "end-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are almost there!"])},
    "email-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You’re signing up as "])},
    "name-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What’s your full name?"])},
    "name-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "create-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create a password"])},
    "password-input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password must be 8 characters or longer."])},
    "re-enter-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter password"])},
    "continue-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "verification-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use verification link"])},
    "passwords-match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both password fields must match"])}
  },
  "AsanaCallback": {
    "link-asana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linking your Asana with Time Assistant"])},
    "returning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Returning to the Time Assistant"])}
  },
  "component-main-footer": {
    "contacts-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACTS"])},
    "privacy-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIVACY POLICY"])},
    "terms-footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TERMS OF SERVICE"])}
  },
  "component-input-field": {
    "field-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "valid-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be a valid email"])},
    "not-valid-lac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field must be a valid LaC email"])},
    "valid-password-length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password is too short"])},
    "valid-password-letters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use at least one letter A_Z, a-z"])},
    "valid-password-digits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use at least one digit 0-9"])},
    "valid-password-helper": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min 8 symbols (A-Z, a-z, 0-9)"])}
  },
  "component-main-header": {
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])}
  },
  "component-task-list": {
    "my-tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tasks"])},
    "new-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREATE NEW TIME ENTRY"])},
    "empty-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Empty State"])},
    "select-projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select projects"])},
    "select-filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select filters"])},
    "show-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show status"])},
    "show-priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show priority"])},
    "new-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW PROJECT"])},
    "new-objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEW OBJECTIVE"])},
    "no-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project does not exist"])}
  },
  "component-input-email-reset-password": {
    "main-text-reset-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email address to reset your password"])},
    "confirm-email-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFIRM EMAIL"])},
    "cancel-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL"])}
  },
  "component-reset-password": {
    "new-password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "re-enter-password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-enter password"])},
    "confirm-new-password-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONFIRM NEW PASSWORD"])},
    "compare-passowrd-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both password fields must match"])}
  },
  "login": {
    "welcome-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome Message"])},
    "google-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login with Google"])},
    "login-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOGIN"])},
    "forgot-password-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])},
    "no-account-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have an account?"])},
    "registration-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLICK HERE TO REGISTER"])},
    "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "and": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["and"])},
    "terms-of-service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apply"])},
    "use-lac": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please use valid LaC email"])}
  },
  "input-field": {
    "input-field-email-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "input-field-password-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])}
  },
  "forgotten-password-view": {
    "forgotten-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgotten Password"])},
    "forgotten-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL"])}
  },
  "component-asana-popup": {
    "get-best": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the best out of Time Assistant"])},
    "link-your": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link your"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
    "skip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skip"])},
    "disable-popup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disable asana popup"])}
  },
  "component-new-task-view": {
    "new-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New time entry"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACK"])},
    "select-task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a task"])},
    "entry-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time entry tags"])},
    "date-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date and time"])},
    "completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion"])},
    "cancel-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL"])},
    "next-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NEXT"])},
    "save-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAVE"])},
    "save-and-continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAVE AND CREATE NEW"])},
    "who": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who"])},
    "what": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What"])},
    "why": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why"])}
  },
  "component-entry-info-view": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BACK"])},
    "due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Due: "])},
    "my-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My activities"])},
    "time-spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total time spent"])},
    "no-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have not added any activities yet"])},
    "no-due": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO DUE DATE"])},
    "estimated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estimated"])}
  },
  "component-entry-info-item-view": {
    "duplicate-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate entry"])},
    "delete-tooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete entry"])}
  },
  "component-entry-info-view-popup": {
    "warning-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you wish to delete the entry?"])},
    "keep-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEEP ENTRY"])},
    "delete-entry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DELETE ENTRY"])}
  },
  "snackbar": {
    "wrong-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong credentials inserted."])},
    "email-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password reset link has been sent to your email."])},
    "send-email-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This email is not linked to any of our accounts."])},
    "update-password-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error updating password."])},
    "update-password-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password updated correctly"])},
    "send-email-error-registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong while sending verification email."])},
    "verification-email-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verification email sent."])},
    "buttonActionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "user-registration-failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Failed to register the user."])},
    "user-registration-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User registered correctly."])},
    "duplication-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry has been successfully duplicated"])},
    "entry-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entry has been successfully deleted"])}
  },
  "chip-filter": {
    "filter-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLEAR FILTERS"])}
  },
  "generic-button-text": {
    "capital-cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CANCEL"])}
  },
  "generic-text": {
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OR"])},
    "short-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
    "short-min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
    "single-symbol-min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])}
  },
  "data-protection-popup": {
    "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DENY"])},
    "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AGREE"])},
    "allow-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Time Assistant to access "])},
    "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account email, task and project information?"])}
  },
  "tooltip": {
    "show-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show password"])},
    "hide-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide password"])},
    "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello, "])},
    "plus-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create time entry"])},
    "status-chips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "priority-chips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priority"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More informations"])},
    "editIconTitleTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit selected task"])},
    "editIconTagsTooltip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit time entry tags"])}
  },
  "component-task-selection": {
    "select-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a project"])}
  },
  "reset-password-link-exception": {
    "main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password reset link is invalid or has expired"])},
    "receive-link-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECEIVE A NEW LINK"])},
    "return-login-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RETURN TO LOGIN"])}
  },
  "registration-link-exception": {
    "invalid-link-main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your verification link is invalid or has expired"])},
    "invalid-link-first-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can receive a new verification link to your e-mail"])},
    "invalid-link-second-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your verification link is only valid for"])},
    "invalid-link-third-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" days."])},
    "invalid-link-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RECEIVE A NEW LINK"])},
    "already-registered-user-main-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This e-mail is already registered"])},
    "already-registered-user-first-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your e-mail"])},
    "already-registered-user-second-subtext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["has already been registered in this system"])},
    "already-registered-user-button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RETURN TO LOGIN"])}
  },
  "task-selection": {
    "edit-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task switching is not enabled during entry editing"])},
    "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])}
  },
  "tag-selection": {
    "first-section-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHO"])},
    "second-section-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT"])},
    "third-section-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HOW"])},
    "fourth-section-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHY"])},
    "first-checkbox-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your role has been selected based on your default project role"])},
    "second-checkbox-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Set this as you default role for this project"])},
    "why-checkbox-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This value matches my activity"])},
    "error-suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can only add 3 technologies"])},
    "our-suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our suggestions"])},
    "no-suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no suggestions"])},
    "user-suggestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your technologies"])},
    "value-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum amount of symbols reached"])}
  },
  "DateAndTime": {
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DATE"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TIME"])},
    "time-suggestion-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time suggestions are selected based on your previous entries"])}
  },
  "multiple-checkbox-select": {
    "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select actions"])},
    "label-select-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a role"])},
    "labelWithActions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions selected: "])}
  },
  "profiles": {
    "TECHNICAL_EXPERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Expert"])},
    "DESIGN_THINKER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Thinker"])},
    "PROJECT_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Owner"])},
    "BUSINESS_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Developer"])},
    "LEADER_COACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader - Coach"])}
  },
  "profile-roles-label": {
    "TECHNICAL_EXPERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TE"])},
    "DESIGN_THINKER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DT"])},
    "PROJECT_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PO"])},
    "BUSINESS_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BD"])},
    "LEADER_COACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LC"])},
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])}
  },
  "profile-roles-name": {
    "TECHNICAL_EXPERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Expert"])},
    "DESIGN_THINKER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design Thinker"])},
    "PROJECT_OWNER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Owner"])},
    "BUSINESS_DEVELOPER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Developer"])},
    "LEADER_COACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leader Coach"])},
    "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])}
  },
  "time-selector": {
    "hour-symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["h"])},
    "minute-symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["m"])}
  },
  "remaining-time": {
    "not-ended-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining"])},
    "ended-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["over estimation"])}
  },
  "component-completion-view": {
    "role-activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role and activity"])},
    "used-technology": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Used technologies"])},
    "created-value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created value"])},
    "no-tech-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are currently no used technologies added"])},
    "no-value-selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is currently no value added"])}
  },
  "required": {
    "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" *"])}
  },
  "component-side-bar-menu": {
    "my-tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My tasks"])},
    "all-time-entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Time Entries"])},
    "project-management-view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage projects"])}
  },
  "component-task-item-header": {
    "not-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No set deadline"])}
  },
  "not-supported-version": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for choosing Time Assistant"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We currently don't support this web version. Please open the Time Assistant in your desktop browser."])}
  },
  "component-time-entry-exit-popup": {
    "irreversible-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The changes in the current time entry will be lost"])},
    "return-to-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RETURN TO TASK LIST"])},
    "return-to-entry-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RETURN TO ENTRY LIST"])},
    "continue-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTINUE EDITING"])}
  },
  "component-all-entries-view": {
    "all-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Activities"])},
    "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "objective": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objective"])},
    "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
    "me": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Me"])},
    "download-entries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download this months entries"])},
    "my-activities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My activities"])}
  },
  "local-task-component-locales": {
    "new-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New project"])},
    "project-color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project color"])},
    "manage-project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage your projects"])},
    "new-objectice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New task"])},
    "warning-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you wish to delete the project?"])},
    "delete-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DELETE PROJECT"])},
    "cancel-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEEP PROJECT"])}
  },
  "project-creation-view-component": {
    "first-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a project name"])},
    "second-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a color"])}
  },
  "objective-creation-view-component": {
    "first-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide a task name"])},
    "second-error-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select a project"])},
    "who-tooltip-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHO describes an entity who benefits from the intended functionality"])},
    "what-tooltip-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHAT describes the intended result of the objective"])},
    "why-tooltip-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WHY describes the value created by completing this objective"])}
  },
  "component-profile-view": {
    "user-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Profile"])},
    "disconnect-asana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disconnect from Asana"])},
    "connect-asana": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect to Asana"])}
  },
  "time-entry-upload-view": {
    "loading-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting your info"])},
    "upload-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading your excel file"])},
    "error-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure that date fields are filled correctly"])},
    "error-desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure that description fields are filled correctly"])},
    "error-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please make sure that hour fields are filled correctly"])},
    "getting-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Getting your info"])}
  },
  "component-time-entry-upload": {
    "understand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I UNDERSTAND"])},
    "desc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill the required blocks and upload your excel file following the requirements"])}
  }
}