import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "chip-wrapper" }
const _hoisted_2 = { class: "medium-text-grey" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["chip", [_ctx.chipStyle, _ctx.size, _ctx.projectCircleColor, { notSelected: _ctx.disabled }]])
    }, [
      (_ctx.project)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["project-color", [_ctx.projectCircleColor]])
          }, null, 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.chipContent), 1)
    ], 2)
  ]))
}