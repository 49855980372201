
import { computed, defineComponent } from "vue";
import checkbox from "@/components/CheckBox.vue";
import store from "@/store/store";

interface OptionStructure {
  text: string;
  selected: boolean;
}

export default defineComponent({
  emits: ["resetSelectActions"],
  components: {
    checkbox,
  },
  props: {
    options: {
      required: true,
      type: Array as () => Array<OptionStructure>,
    },
    clickedNewLabel: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      show: false,
      label: this.$t("multiple-checkbox-select.label"),
      labelSelectRole: this.$t("multiple-checkbox-select.label-select-role"),
      labelWithActions: this.$t("multiple-checkbox-select.labelWithActions"),
      clickOnSelect: false,
    };
  },
  setup(props, context) {
    const num_actions_selected = computed(() => {
      let selectedActions: string[] = store.getters.getActions;
      if (props.clickedNewLabel) {
        selectedActions = [];
        context.emit("resetSelectActions");
        store.dispatch("updateActions", selectedActions);
      }
      return selectedActions.length;
    });

    const options_filled = computed(() => {
      return props.options.length !== 0;
    });

    return { num_actions_selected, options_filled };
  },
  methods: {
    showDropdown() {
      if (this.options.length !== 0) {
        this.clickOnSelect = true;
        this.show = !this.show;
      }
    },
    closeDropdown(event: any) {
      const image_id = event.target.getAttribute("id");
      if (!this.clickOnSelect && image_id !== "check_img") {
        this.show = false;
      }
      this.clickOnSelect = false;
    },
    async checkSelectedOption(value: OptionStructure) {
      value.selected = !value.selected;
      let selectedActions: string[] = store.getters.getActions;

      if (value.selected) {
        selectedActions.push(value.text);
        selectedActions.sort();
        await store.dispatch("updateActions", selectedActions);
      } else {
        selectedActions = selectedActions.filter((action: string) => {
          return action !== value.text;
        });
        selectedActions.sort();
        await store.dispatch("updateActions", selectedActions);
      }
    },
  },
});
