
import { defineComponent } from "vue";
import { computed } from "@vue/reactivity";
import loadingRing from "@/components/loadingRing.vue";

export default defineComponent({
  data() {
    return {
      hover: false,
    };
  },
  components: {
    loadingRing,
  },
  props: {
    text: {
      required: true,
      type: String,
    },
    showGoogleImage: {
      required: false,
      type: Boolean,
      default: false,
    },
    closeHover: {
      required: false,
      type: Boolean,
      default: false,
    },
    showCloseIcon: {
      required: false,
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
      required: false,
      default: "orange",
    },
    customClass: {
      type: String,
      required: false,
    },
    enableLoading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  computed: {
    enableButtonLoader() {
      return this.enableLoading;
      // return this.enableLoading ? true : false;
    },
  },
  setup(props) {
    const buttonClass = computed(() => {
      return `button-${props.backgroundColor}`;
    });

    return { buttonClass };
  },
});
