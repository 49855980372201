export const REGISTRATION_PAGE = "REGISTRATION";
export const REGISTRATION_VERIFIY_PAGE = "REGISTRATION_VERIFY";
export const REGISTRATION_END_PAGE = "REGISTRATION_END";
export const REGISTRATION_INVALID_LINK = "REGISTRATION_INVALID_LINK";
export const REGISTRATION_ALREADY_REGISTERED_USER =
  "REGISTRATION_ALREADY_REGISTERED_USER";
export const LOGIN_PAGE = "LOGIN";
//TODO update it with the home page
export const HOME_PAGE = "HOME";
export const FORGOTTEN_PASSWORD_PAGE = "FORGOTTEN_PASSWORD";
export const RESET_PASSWORD_PAGE = "RESET_PASSWORD";
export const FORGOTTEN_PASSWORD_INVALID_LINK =
  "FORGOTTEN_PASSWORD_INVALID_LINK";
export const DASHBOARD_PAGE = "DASHBOARD";
export const ASANA_CALLBACK = "ASANA_CALLBACK";
export const TIME_ENTRY_PAGE = "TASKVIEW";
export const TIME_ENTRY_INFO_PAGE = "TIME_ENTRY_INFO";
export const UPLOAD_EXCEL_PAGE = "UPLOAD_EXCEL";
export const ALL_ENTRIES_PAGE = "ALL_ENTRIES";
export const PROJECT_CREATION_PAGE = "PROJECT_CREATION_VIEW";
export const PROJECT_MANAGEMENT_PAGE = "PROJECT_MANAGEMENT_VIEW";
export const OBJECTIVE_CREATION_PAGE = "OBJECTIVE_CREATION_VIEW";
export const PROFILE_PAGE = "PROFILE_PAGE";
