import { FirebaseApp, initializeApp } from "firebase/app";

export function initializeApplication(): FirebaseApp {
  const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
  };

  const app = initializeApp(firebaseConfig);
  return app;
}
