
import { defineComponent, computed } from "vue";
import MainBlock from "@/components/MainBlock.vue";
import { useRoute } from "vue-router";
import * as pageNames from "@/router/pageNames";
import taButton from "@/components/Button.vue";
import router from "@/router";
import loadingStore from "@/store/store";
import { sendVerificationEmail } from "@/service/RegistrationService";
import { userInfoByDocumentId } from "@/service/RegistrationService";
import store from "@/store/store";

export default defineComponent({
  emits: ["registrationEmailNotSent", "registrationEmailSent"],
  name: "Registration Verify",
  components: { MainBlock, taButton },
  data() {
    return {
      cancelButtonText: this.$t("registration.cancel"),
      emailNotSentText: this.$t("snackbar.send-email-error-registration"),
      emailSentText: this.$t("snackbar.verification-email-sent"),
      invalidLinkPage: pageNames.REGISTRATION_INVALID_LINK,
      alreadyRegisteredUserPage: pageNames.REGISTRATION_ALREADY_REGISTERED_USER,
      methodName: this.returnToLogin,
      actualPage: "",
      documentId: "",
      userEmail: "",
      mainText: "",
      expirationDays: "XX",
      firstSubText: "",
      secondSubText: "",
      thirdSubText: "",
      buttonText: "",
    };
  },
  async mounted() {
    if (useRoute().name === this.alreadyRegisteredUserPage) {
      this.actualPage = this.alreadyRegisteredUserPage;
      this.mainText = this.$t(
        "registration-link-exception.already-registered-user-main-text"
      );
      this.firstSubText = this.$t(
        "registration-link-exception.already-registered-user-first-subtext"
      );
      this.secondSubText = this.$t(
        "registration-link-exception.already-registered-user-second-subtext"
      );
      this.buttonText = this.$t(
        "registration-link-exception.already-registered-user-button-text"
      );
      this.methodName = this.returnToLogin;
      this.userEmail = store.getters.getEmail;
    } else if (useRoute().name === this.invalidLinkPage) {
      this.actualPage = this.invalidLinkPage;
      this.mainText = this.$t(
        "registration-link-exception.invalid-link-main-text"
      );
      this.firstSubText = this.$t(
        "registration-link-exception.invalid-link-first-subtext"
      );
      this.secondSubText = this.$t(
        "registration-link-exception.invalid-link-second-subtext"
      );
      this.thirdSubText = this.$t(
        "registration-link-exception.invalid-link-third-subtext"
      );
      this.buttonText = this.$t(
        "registration-link-exception.invalid-link-button-text"
      );
      this.methodName = this.resendEmail;
      this.documentId = this.$route.query.id as string;
      const userInfo = await userInfoByDocumentId(this.documentId);
      this.userEmail = userInfo.email;
    }

    loadingStore.dispatch("changeLoadingState", false);
  },
  setup() {
    const computedContainer = computed(() => {
      if (useRoute().name === pageNames.REGISTRATION_ALREADY_REGISTERED_USER) {
        return useRoute().name;
      } else if (useRoute().name === pageNames.REGISTRATION_INVALID_LINK) {
        return useRoute().name;
      }
      return "";
    });
    return { computedContainer };
  },
  methods: {
    async resendEmail() {
      let isEmailValid = await sendVerificationEmail(this.userEmail);
      if (!isEmailValid) {
        this.$emit("registrationEmailNotSent", this.emailNotSentText);
      } else {
        this.$emit("registrationEmailSent", this.emailSentText);
      }
    },
    returnToLogin() {
      loadingStore.dispatch("changeLoadingState", true);
      router.push("/login");
    },
  },
});
