
import { defineComponent } from "vue";
import taButton from "@/components/Button.vue";
import StatusChip from "@/components/StatusChip.vue";
import { filterByGivenOption } from "@/helpers/FilterHelper";
import { CustomFieldsInformations } from "@/models/time_assistant/asana_task";

export default defineComponent({
  components: {
    StatusChip,
    taButton,
  },
  emits: ["clearFilter", "selected"],
  data() {
    return {
      filterText: this.$t("chip-filter.filter-text"),
      selectedChipFilter: [],
    };
  },
  props: {
    filterableArray: {
      type: Array,
    },
    filterName: {
      type: String,
    },
    filterArray: {
      type: Boolean,
    },
  },
  methods: {
    filterUpdate(selectedOption: CustomFieldsInformations) {
      filterByGivenOption(selectedOption, this.selectedChipFilter);
      this.$emit("selected", selectedOption);
    },
    clearFilters() {
      this.selectedChipFilter = [];
      this.$emit("clearFilter");
    },
  },
});
