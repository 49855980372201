
import { defineComponent } from "vue";
import userStore from "@/store/store";
import { logout } from "@/service/LogoutSerivce";
import router from "@/router";
import { resetUserAndSession } from "@/utils/user-utils";
import loadingStore from "@/store/store";
import tooltip from "@/components/Tooltip.vue";
import store from "@/store/store";

export default defineComponent({
  components: { tooltip },
  data() {
    return {
      isDropdownOpen: false,
      userTag: userStore.getters.getUser.display_name[0],
      userFullName: userStore.getters.getUser.display_name,
      notificationTooltip: this.$t("tooltip.notifications"),
      profileTooltip: this.$t("tooltip.profile"),
      menuTooltip: this.$t("tooltip.menu"),
      headerTooltipPosition: "large",
    };
  },
  methods: {
    goToProfile() {
      router.push("/dashboard/profile");
      this.isDropdownOpen = false;
    },
    showDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    async userLogout() {
      loadingStore.dispatch("changeAsanaIntegrationState", true);
      loadingStore.dispatch("changeLoadingState", true);
      const result = await logout();
      await resetUserAndSession();
      if (result) {
        store.dispatch("updateShowSideBar", false);
        router.push("/login");
      } else {
        loadingStore.dispatch("changeLoadingState", false);
      }
    },
  },
});
