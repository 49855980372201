import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "chip-filter"
}
const _hoisted_2 = { class: "chip-text-block" }
const _hoisted_3 = { class: "chip-text small-text" }
const _hoisted_4 = { class: "chip-filter-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ta_button = _resolveComponent("ta-button")!
  const _component_status_chip = _resolveComponent("status-chip")!

  return (_ctx.filterableArray.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.filterName), 1),
          (_ctx.selectedChipFilter.length > 0)
            ? (_openBlock(), _createBlock(_component_ta_button, {
                key: 0,
                showCloseIcon: "",
                customClass: 'clear-filter-button',
                text: _ctx.filterText,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clearFilters()))
              }, null, 8, ["text"]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterableArray, (filterOptions, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "chip-filter-chip"
            }, [
              _createVNode(_component_status_chip, {
                onClick: ($event: any) => (_ctx.filterUpdate(filterOptions)),
                chipContent: _ctx.filterArray ? filterOptions.name : filterOptions.value,
                filterColor: filterOptions.color,
                projectColor: filterOptions.color,
                disabled: !_ctx.selectedChipFilter.includes(filterOptions),
                project: _ctx.filterArray
              }, null, 8, ["onClick", "chipContent", "filterColor", "projectColor", "disabled", "project"])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}