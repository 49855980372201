import { UserInterface } from "@/models/sso/user";
import { signInWithPopup, GoogleAuthProvider, Auth } from "firebase/auth";
import { createSessionWithGoogle } from "@/service/LoginService";
import * as loginConstants from "@/constants/LoginConstants";

export async function signInWithGoogle(
  auth: Auth,
  provider: GoogleAuthProvider
): Promise<{ user: UserInterface; session: string } | void | string> {
  return signInWithPopup(auth, provider)
    .then(async (result) => {
      let user;
      let token;
      let userEmail;
      const lacEmail = loginConstants.LAC_EMAIL;
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (credential) {
        user = result.user;
        userEmail = user.email as string;
        if (!userEmail.includes(lacEmail)) {
          return "Not LaC email";
        }
        token = (await user.getIdToken()) || "";
        if (token) {
          const login_info = await createSessionWithGoogle(token, user);
          return login_info;
        }
      }
      return;
    })
    .catch(() => {
      return;
    });
}
