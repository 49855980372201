import { UserApi } from "@/infrastructure/http/UserApiResource";
import { UserInterface } from "@/models/sso/user";

export async function getMe(): Promise<{
  user: UserInterface;
  session: string;
} | null> {
  const result = await UserApi.getMe();
  if (result.user && result.session) {
    return result;
  } else {
    return null;
  }
}
