import { UserApi } from "@/infrastructure/http/UserApiResource";
import { Integration } from "@/models/time_assistant/integration";

export async function integrationAccessToken(
  integration_name: string,
  uid: string
) {
  const integrationToken = await UserApi.integrationAccessToken(
    integration_name,
    uid
  );
  return integrationToken;
}

export async function getAllUsers() {
  const allUsers = await UserApi.getAllUsers();
  return allUsers;
}

export async function allIntegrationByUser(user_uid: string) {
  const all_integrations = await UserApi.allIntegrationByUser(user_uid);
  return all_integrations;
}

export async function removeIntegration(intId: string) {
  await UserApi.removeIntegration(intId);
}
export async function updateIntegration(
  id: string,
  confirmed_integration: boolean
) {
  await UserApi.updateIntegration(id, confirmed_integration);
}
