import type { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import axios from "axios";
import store from "@/store/store";

export abstract class AbstractApiResource {
  protected readonly http: AxiosInstance;

  protected constructor(protected readonly application: string) {
    let baseURL;
    if (application === process.env.VUE_APP_SSO_APPLICATION) {
      baseURL = process.env.VUE_APP_API_GATEWAY_BASE_SSO_URL || "";
    } else {
      baseURL = process.env.VUE_APP_API_GATEWAY_BASE_TA_URL || "";
    }
    this.http = axios.create({
      baseURL,
      withCredentials: true,
    });

    this.http.interceptors.request.use(
      async function (config) {
        if (application === process.env.VUE_APP_SSO_APPLICATION) {
          if (store.getters.getBearerTokenSSO === null) {
            await store.dispatch("generateSSOToken");
          }
          if (config.headers) {
            config.headers.Authorization = store.getters.getBearerTokenSSO;
          }
        } else {
          if (store.getters.getBearerTokenTA === null) {
            await store.dispatch("generateTAToken");
          }
          if (config.headers) {
            config.headers.Authorization = store.getters.getBearerTokenTA;
          }
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );

    this.http.defaults.headers.common["Accept"] =
      "application/json;charset=UTF-8";
    this.http.defaults.headers.common["Content-Type"] =
      "application/json;charset=UTF-8";
  }

  protected handleResponse<T>(response: AxiosResponse<T>): T | undefined {
    if (response) {
      return response.data;
    } else {
      return undefined;
    }
  }

  protected handleErrors<T>(error: AxiosError<T>): T | undefined {
    return error.response?.data;
  }
}
