
import { defineComponent } from "vue";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { signInWithGoogle } from "@/service/GoogleService";
import { loginWithUsernameAndPassword } from "@/service/LoginService";
import { Form } from "vee-validate";

import mainBlock from "@/components/MainBlock.vue";
import leftBlock from "@/components/LeftBlock.vue";
import rightBlock from "@/components/RightBlock.vue";
import taButton from "@/components/Button.vue";
import mainFooter from "@/components/MainFooter.vue";
import inputField from "@/components/InputField.vue";
import router from "@/router";
import loadingStore from "@/store/store";
import { storeUserAndSession } from "@/utils/user-utils";
import snackbar from "@/components/Snackbar.vue";

export default defineComponent({
  name: "LoginView",
  components: {
    Form,
    mainFooter,
    taButton,
    inputField,
    mainBlock,
    leftBlock,
    rightBlock,
    snackbar,
  },
  data() {
    return {
      passwordType: "password",
      googleButtonBackgroundColor: "white",
      buttonBackgroundColor: "orange",
      welcomeMessage: this.$t("login.welcome-message"),
      googleButtonText: this.$t("login.google-button-text"),
      inputFieldEmailPlaceholder: this.$t(
        "input-field.input-field-email-placeholder"
      ),
      inputFieldPasswordPlaceholder: this.$t(
        "input-field.input-field-password-placeholder"
      ),
      loginButtonText: this.$t("login.login-button-text"),
      forgotPasswordText: this.$t("login.forgot-password-text"),
      noAccountText: this.$t("login.no-account-text"),
      registrationButtonText: this.$t("login.registration-button-text"),
      privacyPolicy: this.$t("login.privacy-policy"),
      and: this.$t("login.and"),
      termsOfService: this.$t("login.terms-of-service"),
      apply: this.$t("login.apply"),
      email: "",
      password: "",
      errorMessageEmail: "",
      errorMessagePassword: "",
      buttonActionText: this.$t("snackbar.buttonActionText"),
      showSnackbar: false,
      snackbarMessage: "",
      emptyFieldEmail: true,
      emptyFieldPassword: true,
      emitEmailReceived: false,
      emitPasswordReceived: false,
      emailValid: false,
    };
  },
  async mounted() {
    loadingStore.dispatch("changeLoadingState", false);
  },
  methods: {
    receiveEmail(data: string) {
      this.email = data;
      this.errorMessageEmail = "";
    },
    receivePassword(data: string) {
      this.password = data;
      data.length > 0
        ? (this.emptyFieldPassword = false)
        : (this.emptyFieldPassword = true);
      this.errorMessagePassword = "";
    },
    async loginWithGoogle() {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      if (auth) {
        loadingStore.dispatch("changeLoadingState", true);
        const login_info = await signInWithGoogle(auth, provider);
        if (login_info) {
          if (typeof login_info !== "string") {
            await storeUserAndSession(login_info);
            router.push("/dashboard/tasks");
          } else {
            loadingStore.dispatch("changeLoadingState", false);
            this.showSnackbar = true;
            this.snackbarMessage = this.$t("login.use-lac");
            setTimeout(() => {
              this.showSnackbar = false;
            }, 4000);
          }
        } else {
          loadingStore.dispatch("changeLoadingState", false);
        }
      }
    },
    async login() {
      if (
        !this.emptyFieldEmail &&
        !this.emptyFieldPassword &&
        this.emailValid
      ) {
        loadingStore.dispatch("changeLoadingState", true);
        const login_info = await loginWithUsernameAndPassword(
          this.email,
          this.password
        );
        if (!login_info?.user) {
          loadingStore.dispatch("changeLoadingState", false);
          this.showSnackbar = true;
          this.snackbarMessage = this.$t("snackbar.wrong-credentials");
          setTimeout(() => {
            this.showSnackbar = false;
          }, 4000);
        } else {
          if (login_info) {
            loadingStore.dispatch("changeLoadingState", true);
            await storeUserAndSession(login_info);
          }
          router.push("/dashboard/tasks");
        }
      } else if (this.emptyFieldEmail && this.emptyFieldPassword) {
        this.showEmailErrorMessage(
          this.$t("component-input-field.field-required")
        );
        this.showPasswordErrorMessage(
          this.$t("component-input-field.field-required")
        );
      } else if (this.emptyFieldEmail && !this.emptyFieldPassword) {
        this.showEmailErrorMessage(
          this.$t("component-input-field.field-required")
        );
      } else if (!this.emptyFieldEmail && this.emptyFieldPassword) {
        this.showPasswordErrorMessage(
          this.$t("component-input-field.field-required")
        );
      }
    },
    showPassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },
    closeSnackBar() {
      this.showSnackbar = false;
    },
    isInputFieldEmailEmpty(value: boolean) {
      this.emitEmailReceived = true;
      this.emptyFieldEmail = value;
    },
    isInputFieldPasswordEmpty(value: boolean) {
      this.emitPasswordReceived = true;
      this.emptyFieldPassword = value;
    },
    showEmailErrorMessage(message: string) {
      if (!this.emitEmailReceived) {
        this.errorMessageEmail = message;
      }
    },
    showPasswordErrorMessage(message: string) {
      if (!this.emitPasswordReceived) {
        this.errorMessagePassword = message;
      }
    },
    isEmailValid(data: boolean) {
      this.emailValid = data;
    },
  },
  beforeCreate: function () {
    document.body.className = "LoginView";
  },
});
