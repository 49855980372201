import { AsanaProject } from "@/models/time_assistant/asana_project";
import { AsanaTask } from "@/models/time_assistant/asana_task";
import { DONE, READY_FOR_PROD } from "@/constants/ChipConstants";

export function filterByGivenOption(
  selectedOption: any,
  selectedFilter: any[]
) {
  if (selectedFilter.includes(selectedOption) != true) {
    selectedFilter.push(selectedOption);
  } else {
    selectedFilter.find((element, index) => {
      if (element === selectedOption) {
        selectedFilter.splice(index, 1);
      }
    });
  }
  return selectedOption;
}
